import styles from "../css/SingleProductCom.module.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMagnifyingGlass,
  faUser,
  faHeart,
  faBagShopping,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Text, Skeleton, Box, Icon, Image } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { updateWishlist } from "../Redux/AppReducer/Action";
// import { getWishList } from "../Redux/WishListReducer/Action";
import { AiOutlineHeart } from "react-icons/ai";
import { BsFillHeartFill } from "react-icons/bs";
import showCustomToast from "./showCustomToast";
import { getWishlistCountAsync } from "../Redux/NavBarhelperdataslice";

library.add(faMagnifyingGlass, faUser, faHeart, faBagShopping);

export default function RelatedProduct(el, height = "50vh") {
  const {
    product_price = "",
    id = "",
    productimage = "",
    discount = "",
    discounted_price = "",
    rattings = "",
    is_wishlist = "",
    product_name = "",
    variation = [],
  } = el;
  const dispatch = useDispatch();
  const { isLoading } = useSelector((store) => store.AppReducer);
  const [showWish, setShowWish] = useState(false);
  const [wishlistStatus, setWishlistStatus] = useState(
    Boolean(Number(is_wishlist))
  ); // State variable for wishlist status
  const navigate = useNavigate();
  const { isAuth, userData } = useSelector((store) => store.AuthReducer);
  const params = {
    product_id: id,
    user_id: userData.id,
  };

  // useEffect(() => {
  //   Products.forEach((product) => {
  //     if (product.id === el.id) {
  //       setAlreadyloaded(true);
  //       return;
  //     }
  //   });
  // }, [Products, el.id]);

  useEffect(() => {
    setWishlistStatus(Boolean(Number(el.is_wishlist)));
  }, [el]);

  // ...
  const handleWishlistClick = () => {
    if (isAuth) {
      // setIswishLoading(true);
      const apiURL = wishlistStatus
        ? `${process.env.REACT_APP_API}/removefromwishlist`
        : `${process.env.REACT_APP_API}/addtowishlist`;

      axios({
        method: "post",
        url: apiURL,
        data: params,
      })
        .then((res) => {
          showCustomToast(res.data.message, "success");
          const params = {
            product_id: id,
            user_id: userData.id,
            // is_wishlist: !wishlistStatus,
            is_wishlist: wishlistStatus ? "0" : "1",
          };
          // dispatch(getWishList(params));
          dispatch(updateWishlist(params));
          dispatch(getWishlistCountAsync(params));
          setWishlistStatus((prevStatus) => !prevStatus); // Toggle the wishlist status
        })
        .catch((err) => {
          showCustomToast("item already present in wishlist", "error");
        });
    } else {
      navigate("/login");
    }
  };

  var rating;
  var count;
  if (rattings.length > 0) {
    rating = rattings[0].avg_rating;
    count = rattings[0].count;
  } else {
    rating = 0;
    count = 0;
  }

  return (
    <>
      <Skeleton isLoaded={!isLoading}>
        <div
          className={styles.main}
          onMouseEnter={() => setShowWish(true)}
          onMouseLeave={() => setShowWish(false)}
        >
          <div style={{ width: "100%", position: "relative" }}>
            <div className={styles.wishlist_btn}>
              <Box
                bg={"rgba(255, 255, 255, 0.623)"}
                borderRadius={"50%"}
                p={1}
                boxSize={7}
                textAlign="center"
                onClick={handleWishlistClick}
              >
                <Icon
                  as={wishlistStatus ? BsFillHeartFill : AiOutlineHeart}
                  boxSize={5}
                  color={wishlistStatus ? "red" : "black"}
                />
              </Box>
            </div>
            {!showWish && count !== 0 && (
              <div className={styles.rating}>
                <div>
                  <span>{rating}</span> &nbsp;
                  <span className={styles.star}>&#9733; </span>
                </div>
                <div> |</div>
                <div>{count}</div>
              </div>
            )}
            <Image
              onClick={() => navigate(`../fetchProductDetails/${id}`)}
              src={productimage?.image_url}
              borderRadius={10}
              alt=""
            />
          </div>

          <div>
            <div className={styles.size}>
              {showWish && (
                <div>
                  {variation.length > 0 ? <span>Sizes: </span> : null}
                  {variation.map((el, index) => (
                    <span key={index}>
                      {el.variation}
                      {index !== variation.length - 1 && ","}{" "}
                    </span>
                  ))}
                </div>
              )}
            </div>

            {!showWish && (
              <Text
                // m={"2px 0px"}
                className={
                  window.innerWidth > 500
                    ? styles.prc_name
                    : styles.prc_name_mob
                }
                onClick={() => navigate(`../fetchProductDetails/${id}`)}
              >
                {product_name}
              </Text>
            )}
            <div
              onClick={() => navigate(`../fetchProductDetails/${id}`)}
              className={window.innerWidth > 500 ? styles.prc : styles.prc_mob}
            >
              <p>Rs.{discounted_price}</p>
              <p>
                <span>Rs.</span>
                {product_price}
              </p>
              <p>{`(${discount}% OFF)`}</p>
            </div>
          </div>
        </div>
      </Skeleton>
    </>
  );
}
