import OtherNavbar from "../Components/OtherNavbar";
import OtherFooter from "../Components/OtherFooter";
import {
  Box,
  Button,
  VStack,
  Show,
  Text,
  GridItem,
} from "@chakra-ui/react";
import { CheckIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateOrderStatus } from "../Redux/OrderReducer/Action";

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);

  // Access the individual query parameters
  const order_id = searchParams.get("order_id");
  const amount = searchParams.get("amount");
  const order_number = searchParams.get("order_number");
  useEffect(() => {
    const params = {
      status: "success",
    };
    dispatch(updateOrderStatus(params));
    //eslint-disable-next-line
  }, []);

  return (
    <VStack h="100vh" justify="space-between">
      <OtherNavbar page="paymentSuccess" />
      <Box w="100%">
        <VStack w={"60%"} m="auto" spacing={4}>
          {/* <Image
            w="full"
            src="https://www.knowband.com/blog/wp-content/uploads/2020/03/THANKYOU-PAGE-2.png"
          /> */}
          {/* <Show breakpoint="(max-width: 500px)">
            <Button
              my={4}
              w={"100%"}
              onClick={() => {
                navigate("/store");
              }}
              color={"#fff"}
              variant={"solid"}
              bg={"black"}
              borderRadius={3}
              fontSize={"15px"}
            >
              CONTINUE SHOPPING
            </Button>
          </Show> */}

            <Show breakpoint="(min-width: 500px)" >
            <Text style={{fontSize:20,fontWeight:'bold'}}>Your Order has been received</Text>
            </Show>
            <Show breakpoint="(max-width: 500px)">
            <Text  style={{fontSize:15,fontWeight:'bold'}}>Your Order has been received</Text>
              </Show>


            <GridItem colSpan={1}>
              <Box
                w="70px"
                h="70px"
                borderRadius="50%" // This sets the box to be a circle
                bg="green.500"
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
                fontWeight="bold"
                mb={2}
                fontSize="24px"
              >
                <CheckIcon boxSize={8}/>
              </Box>
            </GridItem>
            <Text style={{fontSize:14,fontWeight:'bold'}}>Thank You for your Purchase !</Text>
            <Text style={{fontSize:12}}>Your Order ID is: {order_id}</Text>
            <Text style={{fontSize:12}}>Your Order Number is: {order_number}</Text>
            <Text style={{fontSize:12}}>Your Order Amount: Rs. {amount}</Text>
            <Button
              variant={"outline"}
              colorScheme="red"
              fontWeight={"bold"}
              //  _hover={{bgColor:"pink"}}
              onClick={() => {
                navigate("/store");
              }}
            >
              CONTINUE SHOPPING
            </Button>

        </VStack>
      </Box>
      <OtherFooter />
    </VStack>
  );
};

export default PaymentSuccess;
