import {
  Box,
  Center,
  Spinner,
  Heading,
  HStack,
  Icon,
  VStack,
  Text,
  Input,
  InputLeftAddon,
  InputGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Link as ChakraLink,
  Button,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginwithnumber } from "../Redux/AuthReducer/Action";
import { PhoneIcon } from "@chakra-ui/icons";
import Navbar2 from "../Components/Navbar2";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuth, isLoading } = useSelector((store) => store.AuthReducer);

  const [input, setInput] = useState({
    email: "",
    password: "",
    phonenumber: "",
  });

  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const comingFrom = location?.state || "/";

  const handleInputChange = (e, type) => {
    if (type === "email") {
      setInput({ ...input, email: e });
    } else if (type === "password") {
      setInput({ ...input, password: e });
    } else if (type === "phonenumber") {
      setInput({ ...input, phonenumber: e });
    }
    if (isError) {
      setIsError(false);
    }
  };

  const callbackAfterLoginSuccess = () => {
    dispatch({ type: "USER_PHONE_NUMBER", payload: input.phonenumber });
    navigate("/otp");
    dispatch({ type: "RESET_AUTH_STATUS" });
  };
  const NumberhandleSubmit = (e) => {
    e.preventDefault();
    const { phonenumber } = input;
    const params = {
      identifier: phonenumber,
      login_type: "1",
    };
    try {
      dispatch(loginwithnumber(params, callbackAfterLoginSuccess));
    } catch (error) {}
  };

  useEffect(() => {
    if (isAuth) {
      navigate(comingFrom, { replace: true });
    }
  });

  return (
    <>
      {isLoading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={9999}
          backgroundColor="rgba(0, 0, 0, 0.5)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="xl" color="#ffffff" />
        </Box>
      )}

      <Box>
        <Navbar2 />

        <Center
          w={"full"}
          bgColor={window.innerWidth > 500 ? "#fceeea" : "#d3d3d361"}
          h={"100vh"}
        >
          <VStack w={"420px"} spacing="0">
            {/* <Box>

                            <Image src="https://assets.myntassets.com/f_webp,dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/2022/9/21/8fca3ae9-d245-443b-a142-8d568247794c1663700243878-offer-banner-300-600x240-code-_-MYNTRA400.jpg" />

                        </Box> */}

            <Box w={"100%"} p={"40px 30px 10px 30px"} bgColor="white">
              <FormControl isInvalid={isError}>
                <FormLabel display={"flex"} as="div">
                  <Center>
                    <HStack
                      w="full"
                      alignItems={"baseline"}
                      gap="0"
                      spacing={"5px"}
                    >
                      <Heading
                        fontWeight={"600"}
                        as={"h2"}
                        color="#424553"
                        fontSize="24px"
                        size="lg"
                      >
                        Login
                      </Heading>
                    </HStack>
                  </Center>
                </FormLabel>
                {/* <Show breakpoint='(min-width: 500px)'>
                  <InputGroup mt={10} size={"sm"} variant={"outline"}>
                    <InputLeftAddon p="15px 10px">
                      <Icon as={EmailIcon} color="gray.500" boxSize={4} />
                    </InputLeftAddon>

                    <Input
                      p={"15px 10px"}
                      focusBorderColor="#f4f4f4"
                      type="email"
                      placeholder="Email or Mobile"
                      value={input.email}
                      onChange={(e) => handleInputChange(e.target.value, "email")}
                    />
                  </InputGroup>

                  <InputGroup mt={10} size={"sm"} variant={"outline"}>
                    <InputLeftAddon p="15px 10px">
                      <Icon as={LockIcon} color="gray.500" boxSize={4} />
                    </InputLeftAddon>

                    <Input
                      p={"15px 10px"}
                      focusBorderColor="#f4f4f4"
                      type="password"
                      placeholder="Password"
                      value={input.password}
                      onChange={(e) =>
                        handleInputChange(e.target.value, "password")
                      }
                    />
                  </InputGroup>
                </Show> */}
                {/* <Show breakpoint='(max-width: 500px)'> */}
                <InputGroup mt={10} size={"sm"} variant={"outline"}>
                  <InputLeftAddon p="15px 10px">
                    <Icon as={PhoneIcon} color="gray.500" boxSize={4} />
                  </InputLeftAddon>

                  <Input
                    p={"15px 10px"}
                    focusBorderColor="#f4f4f4"
                    type="number"
                    placeholder="Mobile Number"
                    value={input.phonenumber}
                    onChange={(e) =>
                      handleInputChange(e.target.value, "phonenumber")
                    }
                  />
                </InputGroup>
                {/* </Show> */}

                {!isError ? (
                  true
                ) : (
                  <FormErrorMessage fontSize={12}>
                    Please enter a valid data
                  </FormErrorMessage>
                )}

                <FormHelperText
                  mt={8}
                  color={"#a7a9af"}
                  textAlign="left"
                  fontSize={"12px"}
                >
                  By continuing, I agree to the&nbsp;
                  <ChakraLink
                    fontWeight={"bold"}
                    _hover={{ textDecoration: "none" }}
                    color={window.innerWidth > 500 ? "#ff3f6c" : "black"}
                    onClick={() => {
                      navigate("/terms");
                    }}
                  >
                    Terms of Use&nbsp;
                  </ChakraLink>
                  &&nbsp;
                  <ChakraLink
                    fontWeight={"bold"}
                    _hover={{ textDecoration: "none" }}
                    color={window.innerWidth > 500 ? "#ff3f6c" : "black"}
                    onClick={() => {
                      navigate("/privacy");
                    }}
                  >
                    Privacy Policy&nbsp;
                  </ChakraLink>
                </FormHelperText>
                {/* <Show breakpoint='(min-width: 500px)'>
                  <Button
                    w={"100%"}
                    mt={8}
                    mb={4}
                    variant="solid"
                    backgroundColor={window.innerWidth > 500 ? '#ff3f6c' : 'black'}
                    color={"#fff"}
                    borderRadius="0"
                    colorScheme={"none"}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    CONTINUE
                  </Button>
                </Show> */}
                {/* <Show breakpoint='(max-width: 500px)'> */}
                <Button
                  w={"100%"}
                  mt={8}
                  mb={4}
                  variant="solid"
                  backgroundColor={
                    window.innerWidth > 500 ? "#ff3f6c" : "black"
                  }
                  color={"#fff"}
                  borderRadius="0"
                  colorScheme={"none"}
                  type="submit"
                  onClick={NumberhandleSubmit}
                >
                  CONTINUE
                </Button>
                {/* </Show> */}
              </FormControl>

              <Text mb={5} color={"#a7a9af"} textAlign="left" fontSize="13px">
                If you don't have Account?
                <ChakraLink
                  fontWeight={"bold"}
                  _hover={{ textDecoration: "none" }}
                  color="#ff3f6c"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  &nbsp;SIGNUP
                </ChakraLink>
              </Text>

              {/* <Show breakpoint='(min-width: 500px)'>
                <Text mb={10} color={"#a7a9af"} textAlign="left">

                  <ChakraLink
                    fontWeight={"bold"}
                    _hover={{ textDecoration: "none" }}
                    color={"#ff3f6c"}

                    onClick={() => {
                      navigate("/forgetPassword");
                    }}
                  >
                    &nbsp;Forget Password
                  </ChakraLink>
                </Text>
              </Show> */}
            </Box>
          </VStack>
        </Center>
      </Box>
    </>
  );
};

export default Login;
