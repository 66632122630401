import * as types from "./ActionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const loginwithnumber = (params,callback) => async (dispatch) => {
  // console.log("before login api", params);

  if (params === "logout") {
    localStorage.setItem("selectedAddress", "");
    localStorage.clear();
    dispatch({ type: types.GET_LOGOUT_SUCCESS });
    return;
  }

  dispatch({ type: types.GET_LOGIN_REQUEST });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/login`,
      params
    );
    //eslint-disable-next-line
    if (response.data.status == '1') {
      //dispatch({ type: 'VERIFY_OTP_MOBILE_VERSION'});
      callback();
    }
    // else{
    //   console.log("status value", response.data.data);
    //   dispatch({ type: types.GET_LOGIN_SUCCESS, payload: response.data.data });
    // }

  } catch (error) {
    dispatch({ type: types.GET_LOGIN_FAILURE });
    showErrorToast("Login Failed!");
  }
};

export const login = (params) => async (dispatch) => {
  // console.log("before login api", params);

  if (params === "logout") {
    localStorage.clear();
    dispatch({ type: types.GET_LOGOUT_SUCCESS });
    return;
  }

  dispatch({ type: types.GET_LOGIN_REQUEST });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/login`,
      params
    );
    //eslint-disable-next-line
    if (response.data.status == 2) {
      // dispatch({
      //   type: 'STOP_LOADER',
      // });
     // showSuccessToast("Go to  Otp!!");
      dispatch({ type: 'VERIFY_OTP', payload: response.data.data });
    }
    else{
      // console.log("status value", response.data.data);
      dispatch({ type: types.GET_LOGIN_SUCCESS, payload: response.data.data });
    }

  } catch (error) {
    dispatch({ type: types.GET_LOGIN_FAILURE });
    showErrorToast("Login Failed!");
  }
};

export const register = (params) => async (dispatch) => {
  dispatch({ type: types.GET_REGISTER_REQUEST });
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/register`,
      params
    );
    //eslint-disable-next-line
    if (response.data.status == 1) {
      dispatch({
        type: types.GET_REGISTER_SUCCESS,
        payload: response.data.data,
      });
      //eslint-disable-next-line
    } else if (response.data.status == 2) {
      dispatch({
        type: 'STOP_LOADER',
      });
      showSuccessToast("Go to Login Page & Verify Otp!!");
    }
  } catch (error) {
    // console.log("invalid data:", error.message);
    dispatch({ type: types.GET_REGISTER_FAILURE });
  }
};

export const resetRegisterPhaseStatus = () => async (dispatch) => {
  // console.log("before login api", params);
  dispatch({ type: types.RESET_REGISTER_PHASE_STATUS });
};


export const verifyOtp = (params) => async (dispatch) => {
  dispatch({ type: types.GET_OTP_REQUEST });
  try {
    await axios.post(`${process.env.REACT_APP_API}/identifierverify`, params);
    //   console.log("status value", response.data.data);

    dispatch({ type: types.GET_OTP_SUCCESS });
  } catch (error) {
    // console.log("invalid data:", error.message);
    dispatch({ type: types.GET_OTP_FAILURE });
  }
};

export const verifyPhoneOtp = (params) => async (dispatch) => {
  dispatch({ type: types.GET_OTP_REQUEST });
  try {
    const response =  await axios.post(`${process.env.REACT_APP_API}/mobileverificationlogin`, params);
    //   console.log("status value", response.data.data);
    dispatch({ type: types.GET_OTP_SUCCESS_MOBILE,payload: response.data.data });
  } catch (error) {
    // console.log("invalid data:", error.message);
    dispatch({ type: types.GET_OTP_FAILURE });
  }
};

export const resendOtp = (params) => async (dispatch) => {
  dispatch({ type:'SHOW_LOADER' });
  try {
    const response=await axios.post(`${process.env.REACT_APP_API}/resendverification`, params);
    //   console.log("status value", response.data.data);
    showSuccessToast(response.data.message)
    dispatch({ type:'STOP_LOADER' });

  } catch (error) {
    // console.log("invalid data:", error.message);
    showErrorToast("Re-try!!")
    dispatch({ type:'STOP_LOADER' });
  }
};

////////////////////password reset///////////////////////////
export const indentifierVerify = (params) => async (dispatch) => {


  if(params.identifier === "")
  {
    showErrorToast("Validation Error!");
    return;
  }
  dispatch({ type:'SHOW_LOADER' });
  try {
    await axios.post(`${process.env.REACT_APP_API}/forgetpasswordsendverification`, params);
    //   console.log("status value", response.data.data);
    dispatch({ type: 'PASSWORD_RESET_EMAIL_VERIFY' });
    dispatch({ type:'STOP_LOADER' });

  } catch (error) {
    // console.log("invalid data:", error.response.data.message);
    showErrorToast(error.response.data.message)
    dispatch({ type:'STOP_LOADER' });
  }
};

export const indentifierOtpVerify = (params) => async (dispatch) => {
 // alert(params.otp)
  if(params.otp.length < 6)
  {
    showErrorToast("Validation Error!");
    return;
  }
  dispatch({ type:'SHOW_LOADER' });
  try {
  await axios.post(`${process.env.REACT_APP_API}/forgetpasswordverification`, params);
    //   console.log("status value", response.data.data);
    dispatch({ type: 'PASSWORD_RESET_OTP_VERIFY' });
    dispatch({ type:'STOP_LOADER' });

  } catch (error) {
    // console.log("invalid data:", error.response.data.message);
    showErrorToast(error.response.data.message)
    dispatch({ type:'STOP_LOADER' });
  }
};

export const indentifierPasswordReset = (params) => async (dispatch) => {



  if(params.password.length < 6)
  {
    showErrorToast("Password Length Should be greater than 5!");
    return;
  }
  dispatch({ type:'SHOW_LOADER' });

  try {
 await axios.post(`${process.env.REACT_APP_API}/setnewpassword`, params);
    //   console.log("status value", response.data.data);
    dispatch({ type: 'PASSWORD_RESET' ,payload:true});

    dispatch({ type:'STOP_LOADER' });

  } catch (error) {
    // console.log("invalid data:", error.response.data.message);
    showErrorToast(error.response.data.message)
    dispatch({ type:'STOP_LOADER' });
  }
};


const showSuccessToast = (message) => {
  toast.success(message, {
    autoClose: 2000, // Customize the duration (in milliseconds)
  });
};


const showErrorToast = (message, onClose) => {
  toast.error(message, {
    autoClose: 1000, // Customize the duration (in milliseconds)
    onClose: onClose, // Pass the onClose callback
  });
};

// localStorage.setItem("username",JSON.stringify(payload.name))
// localStorage.setItem("isAuth",JSON.stringify(true))
