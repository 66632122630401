import { Box, VStack, Text, HStack, Icon, Button } from "@chakra-ui/react";
import { MdOutlineLocalOffer } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const CouponCard = ({
  couponData,
  onApplyCoupon,
  showApplyButton,
  price,
  discountedprice,
}) => {
  const navigate = useNavigate();

  // Function to handle applying the coupon
  const handleApplyCoupon = (c) => {
    onApplyCoupon(c);
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={4}
      // width="300px"
      shadow="md"
      display={"flex"}
      bg="white"
    >
      <VStack align="flex-start" spacing={2} mr={2}>
        <HStack spacing={2}>
          <Icon fontSize="20px" as={MdOutlineLocalOffer} />

          <Text fontSize="16px" color="535766" fontWeight={700}>
            Best Offer ₹
            {couponData.type === 0
              ? `${Math.round(
                  discountedprice -
                    (couponData.percentage / 100) * discountedprice
                )}`
              : ""}
            {/* Best Offer ₹{couponData.type === '0' ? `${couponData.min_amount}` : ''} */}
          </Text>
        </HStack>
        {/* <Text fontSize="15px" color="gray.600" as="b">
          Coupan Code - {couponData.coupon_name}
        </Text> */}
        {/* eslint-disable-next-line */}
        {couponData.type == 0 ? (
          <Text fontSize="14px" color="gray.600">
            {couponData.percentage}% Off on your total amount
          </Text>
        ) : (
          <Text fontSize="14px" color="gray.600">
            ₹{couponData.amount} Off on your total amount
          </Text>
        )}
        {/* <Text fontSize="14px" color="gray.600">
          Discount Amount: ₹300
        </Text> */}
        {couponData.min_amount > 0 ? (
          <Text fontSize="14px" color="gray.600">
            Applicable on orders above ₹{couponData.min_amount}
          </Text>
        ) : (
          <></>
        )}
        {showApplyButton ? (
          <Box>
            {localStorage.getItem("coupenName") === couponData.coupon_name ? (
              <Button
                w="100%"
                mt={2}
                colorScheme="green"
                size={"xs"}
                variant={"link"}
                //   onClick={()=>handleApplyCoupon(couponData)}
              >
                Applied
              </Button>
            ) : (
              <Button
                w="100%"
                mt={2}
                colorScheme="red"
                size={"xs"}
                variant={"link"}
                onClick={() => handleApplyCoupon(couponData)}
              >
                Apply Coupon
              </Button>
            )}
          </Box>
        ) : (
          <></>
        )}
      </VStack>
      <Box bg="#EEF3F6" width={20} borderRadius={5} p={3}>
        <Text justifyContent={"center"} fontSize="xs">
          Use Code
        </Text>
        <Text justifyContent={"center"} display={"grid"} fontSize="xs">
          {couponData.coupon_name}
        </Text>
        <Text
          justifyContent={"center"}
          display={"grid"}
          fontSize="xs"
          as="u"
          onClick={() => {
            navigate("/terms");
          }}
        >
          T&C
        </Text>
      </Box>
    </Box>
  );
};

export default CouponCard;
