import React from 'react';

import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';


const SizeChartModal = ({ isOpen, onClose,sizechar_image_url }) => {


  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'} >
      <ModalOverlay />
      <ModalContent
        bg="transparent"
        boxShadow="none" >
      {/* <ModalHeader>All Coupans</ModalHeader> */}
        <ModalCloseButton zIndex={100} right={10}/>
        <ModalBody>
            <Image src={sizechar_image_url}></Image>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SizeChartModal;
