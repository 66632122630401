import { HStack, Box, Image, Divider, Text,Show,Spacer, VStack } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from "../Assets/logo.png"
// import { useLocation } from 'react-router-dom'

const OtherNavbar = ({page}) => {
  const navigate = useNavigate()

  // const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const pageType = parseInt(params.get('pageType'));


  let cartTextColor = "black";
  let addressTextColor = "black";
  let paymentTextColor = "black";
  let addressBottom = "3px solid";
  let paymentBottom = "none"
  let cartBottom = "black";
  if (page === "address") {

    addressTextColor = "#20bd99";
    addressBottom = "3px solid";
  }
  else if (page === "paymentSuccess") {
    paymentTextColor = "#20bd99";
    paymentBottom = "3px solid";
    addressBottom = "none";
    cartBottom = "none";
  }
  else if (page === "cart") {
    cartTextColor = "#20bd99";
    cartBottom = "3px solid";
    addressBottom = "none";
    paymentBottom = "none";
  }

  return (
    <>
    <Show breakpoint='(max-width: 500px)' >
      <VStack>
          <Box onClick={() => navigate("/")}>
            <Image w="50px" alt="logo" fallbackSrc={logo} cursor="pointer" src={logo} />
          </Box>
          <Spacer/>
          <Box>
            <HStack>
              <Box>
                <Text
                  fontFamily="Helvetica, Arial, sans-serif"
                  fontWeight={600}
                  letterSpacing="3px"
                  fontSize="13px"
                  color={cartTextColor}
                  borderBottom={cartBottom}
                >
                  BAG
                </Text>
              </Box>
              <Divider orientation="horizontal" border="1px dashed !important" />
              <Box>
                <Text
                  fontFamily="Helvetica, Arial, sans-serif"
                  fontWeight={600}
                  letterSpacing="3px"
                  fontSize="13px"
                  color={addressTextColor}
                  borderBottom={addressBottom}
                >
                  ADDRESS
                </Text>
              </Box>
              <Divider orientation="horizontal" border="1px dashed !important" />
              <Box>
                <Text
                  fontFamily="Helvetica, Arial, sans-serif"
                  fontWeight={600}
                  letterSpacing="3px"
                  fontSize="13px"
                  color={paymentTextColor}
                  borderBottom={paymentBottom}
                >
                  PAYMENT
                </Text>
              </Box>
            </HStack>
          </Box>
          <Spacer/>
          <Box>
            <HStack>
              <Image boxSize="30px" src="https://constant.myntassets.com/checkout/assets/img/sprite-secure.png" />
              <Text
                fontFamily="Helvetica, Arial, sans-serif"
                fontWeight={600}
                letterSpacing="3px"
                fontSize="13px"
                color="#696b79"
              >
                100% SECURE
              </Text>
            </HStack>
          </Box>
      </VStack>
    </Show>
    <Show breakpoint='(min-width: 500px)' >
      <Box w="full">
        <HStack width="full" justify="space-between" px="80px" py={3} borderBottom="1px solid #f7f7f8">
          <Box onClick={() => navigate("/")}>
            <Image w="50px" alt="logo" fallbackSrc={logo} cursor="pointer" src={logo} />
          </Box>

          <Box width="340px">
            <HStack>
              <Box>
                <Text
                  fontFamily="Helvetica, Arial, sans-serif"
                  fontWeight={600}
                  letterSpacing="3px"
                  fontSize="13px"
                  color={cartTextColor}
                  borderBottom={cartBottom}
                >
                  BAG
                </Text>
              </Box>
              <Divider orientation="horizontal" border="1px dashed !important" />
              <Box>
                <Text
                  fontFamily="Helvetica, Arial, sans-serif"
                  fontWeight={600}
                  letterSpacing="3px"
                  fontSize="13px"
                  color={addressTextColor}
                  borderBottom={addressBottom}
                >
                  ADDRESS
                </Text>
              </Box>
              <Divider orientation="horizontal" border="1px dashed !important" />
              <Box>
                <Text
                  fontFamily="Helvetica, Arial, sans-serif"
                  fontWeight={600}
                  letterSpacing="3px"
                  fontSize="13px"
                  color={paymentTextColor}
                  borderBottom={paymentBottom}
                >
                  PAYMENT
                </Text>
              </Box>
            </HStack>
          </Box>

          <Box>
            <HStack>
              <Image boxSize="30px" src="https://constant.myntassets.com/checkout/assets/img/sprite-secure.png" />
              <Text
                fontFamily="Helvetica, Arial, sans-serif"
                fontWeight={600}
                letterSpacing="3px"
                fontSize="13px"
                color="#696b79"
              >
                100% SECURE
              </Text>
            </HStack>
          </Box>
        </HStack>
      </Box>
    </Show>
    </>
  )
}

export default OtherNavbar;
