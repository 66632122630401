import React, { useState, useRef, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  IconButton,
  Flex,
  AspectRatio,
  Show,
} from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight, FiPlus, FiMinus } from "react-icons/fi";

const ImageModal = ({ isOpen, onClose, productImages, index }) => {
  const [currentImage, setCurrentImage] = useState(index);
  const [zoomLevel, setZoomLevel] = useState(1);
  const imageRef = useRef(null);

  const previousImage = () => {
    // console.log('left swipe');
    setZoomLevel(1);
    setCurrentImage((prevImage) =>
      prevImage === 0 ? productImages.length - 1 : prevImage - 1
    );
  };

  const nextImage = () => {
    // console.log('rigth swipe');
    setZoomLevel(1);
    setCurrentImage((prevImage) =>
      prevImage === productImages.length - 1 ? 0 : prevImage + 1
    );
  };

  const zoomIn = () => {
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2));
  };

  const zoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 1));
  };

  useEffect(() => {
    setCurrentImage(index);
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        previousImage();
      } else if (event.key === "ArrowRight") {
        nextImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    //eslint-disable-next-line
  }, [index]);

  return (
    <>
      <Show breakpoint="(min-width: 500px)">
        <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
          <ModalOverlay />
          <ModalContent
            // overflow="hidden"
            bg="transparent"
            boxShadow="none"
            marginTop="auto"
            // marginBottom="auto"
            // h="100%"
            w="100%"
            //maxH="100vh" // Set the maximum height of the modal content
          >
            <ModalCloseButton zIndex={100} right={10} />

            <ModalBody w="110%"  >
              <AspectRatio ratio={3 / 4}  >
                <Box
                  position="relative"
                  // h="100%"
                  // w='100%'
                  // overflow="hidden"
                  // objectFit="fill"
                >
                  {/* Render the current image */}
                  <Image
                    objectFit={"fill"}
                    src={productImages[currentImage].image_url}
                    ref={imageRef}
                    style={{
                      transform: `scale(${zoomLevel})`,
                      transition: "transform 0.3s ease",
                      // cursor: 'zoom-in'
                    }}
                  />
                  {/* Render previous and next buttons */}
                  <Button
                    position="absolute"
                    left={0}
                    top="50%"
                    transform="translateY(-50%)"
                    onClick={previousImage}
                    disabled={currentImage === 0}
                    colorScheme="blue"
                    size="lg"
                    variant="ghost"
                  >
                    <FiChevronLeft size={40} />
                  </Button>
                  <Button
                    position="absolute"
                    right={0}
                    top="50%"
                    transform="translateY(-50%)"
                    onClick={nextImage}
                    disabled={currentImage === productImages.length - 1}
                    colorScheme="blue"
                    size="lg"
                    variant="ghost"
                  >
                    <FiChevronRight size={40} />
                  </Button>

                  {/* Render zoom-in and zoom-out buttons */}
                  <Flex
                    position="absolute"
                    top={4}
                    left={4}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                  >
                    <IconButton
                      aria-label="Zoom In"
                      icon={<FiPlus />}
                      onClick={zoomIn}
                      disabled={zoomLevel >= 2}
                      colorScheme="blue"
                      variant="ghost"
                      size="lg"
                    />
                    <IconButton
                      aria-label="Zoom Out"
                      icon={<FiMinus />}
                      onClick={zoomOut}
                      disabled={zoomLevel <= 1}
                      colorScheme="blue"
                      variant="ghost"
                      size="lg"
                    />
                  </Flex>
                </Box>
              </AspectRatio>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Show>
      <Show breakpoint="(max-width: 500px)">
        <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
          <ModalOverlay />
          <ModalContent
            // overflow="hidden"
            // bg="transparent"
            boxShadow="none"
            marginTop="auto"
            // marginBottom="auto"
            // h="100%"
            w="100%"
            // maxH="100vh" // Set the maximum height of the modal content
          >
            <ModalCloseButton zIndex={100} right={5} />

            <ModalBody w="100%" p={0}>
              <Carousel
                showStatus={false}
                showArrows={false}
                infiniteLoop={false}
                swipeable={true}
                showThumbs={true}
                autoPlay={false}
                emulateTouch={true}
              >
                {productImages?.map((img, i) => {
                  return (
                    <AspectRatio ratio={3 / 4} w={"100%"} h="100vh">
                      <Box position="relative">
                        {/* Render the current image */}

                        <Image
                          objectFit={"fill"}
                          // h={'100vh'}
                          src={img.image_url}
                          ref={imageRef}
                          style={{
                            transform: `scale(${zoomLevel})`,
                            transition: "transform 0.3s ease",
                            // cursor: 'zoom-in'
                          }}
                        />

                        {/* Render previous and next buttons */}
                        {/* <Button
                            position="absolute"
                            left={0}
                            top="50%"
                            transform="translateY(-50%)"
                            onClick={previousImage}
                            disabled={currentImage === 0}
                            colorScheme="blue"
                            size="lg"
                            variant="ghost"
                          >
                            <FiChevronLeft size={40} />
                          </Button>
                          <Button
                            position="absolute"
                            right={0}
                            top="50%"
                            transform="translateY(-50%)"
                            onClick={nextImage}
                            disabled={currentImage === productImages.length - 1}
                            colorScheme="blue"
                            size="lg"
                            variant="ghost"
                          >
                            <FiChevronRight size={40} />
                          </Button>
                          <Flex
                            position="absolute"
                            top={4}
                            left={4}
                            direction="column"
                            align="flex-start"
                            justify="flex-start"
                          >
                            <IconButton
                              aria-label="Zoom In"
                              icon={<FiPlus />}
                              onClick={zoomIn}
                              disabled={zoomLevel >= 2}
                              colorScheme="blue"
                              variant="ghost"
                              size="lg"
                            />
                            <IconButton
                              aria-label="Zoom Out"
                              icon={<FiMinus />}
                              onClick={zoomOut}
                              disabled={zoomLevel <= 1}
                              colorScheme="blue"
                              variant="ghost"
                              size="lg"
                            />
                          </Flex> */}
                      </Box>
                    </AspectRatio>
                  );
                })}
              </Carousel>

              {/* <AspectRatio ratio={3 / 4} w={'100%'} h="100vh">

                <Box
                  position="relative"
                  h="100vh">

                  <Image
                    objectFit={'fill'}
                    h={'100vh'}
                    src={productImages[currentImage].image_url}
                    ref={imageRef}
                    style={{
                      transform: `scale(${1})`,
                      transition: 'transform 0.3s ease',
                    }}
                  />
                  <Button
                    position="absolute"
                    left={0}
                    top="50%"
                    transform="translateY(-50%)"
                    onClick={previousImage}
                    disabled={currentImage === 0}
                    colorScheme="blue"
                    size="lg"
                    variant="ghost"
                  >
                    <FiChevronLeft size={40} />
                  </Button>
                  <Button
                    position="absolute"
                    right={0}
                    top="50%"
                    transform="translateY(-50%)"
                    onClick={nextImage}
                    disabled={currentImage === productImages.length - 1}
                    colorScheme="blue"
                    size="lg"
                    variant="ghost"
                  >
                    <FiChevronRight size={40} />
                  </Button>

                  <Flex
                    position="absolute"
                    top={4}
                    left={4}
                    direction="column"
                    align="flex-start"
                    justify="flex-start"
                  >
                    <IconButton
                      aria-label="Zoom In"
                      icon={<FiPlus />}
                      onClick={zoomIn}
                      disabled={zoomLevel >= 2}
                      colorScheme="blue"
                      variant="ghost"
                      size="lg"
                    />
                    <IconButton
                      aria-label="Zoom Out"
                      icon={<FiMinus />}
                      onClick={zoomOut}
                      disabled={zoomLevel <= 1}
                      colorScheme="blue"
                      variant="ghost"
                      size="lg"
                    />
                  </Flex>
                </Box>
              </AspectRatio> */}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Show>
    </>
  );
};

export default ImageModal;
