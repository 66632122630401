import {
  Box,
  HStack,
  Grid,
  VStack,
  Image,
  Text,
  Icon,
  Select,
  GridItem,
} from "@chakra-ui/react";

import { TbTruckReturn } from "react-icons/tb";
import { MdArrowDropDown } from "react-icons/md";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  updateCart,
  deleteCart,
  updateCartItemSize,
  updateCartIfSizeExits,
} from "../Redux/CartReducer/Action";
import { useDispatch, useSelector } from "react-redux";
import showCustomToast from "./showCustomToast";

const SingleCartProduct = ({
  MRP = "",
  qty,
  product_qty = 0,
  product_id = "",
  brand = "",
  category = "",
  variation = "",
  currentSize = "",
  discount = "",
  id,
  images = "",
  image_url = "",
  product_price = "",
  dicounted_price = "",
  rating = "",
  ratungT = "",
  variations = [],
  product_name = "",
  type = "",
  return_days = "",
  setTotalAmount = "",
  setTotalMRP = "",
  totalAmount,
  totalMRP,
  MRPDiscount,
  setTotalMRPDiscount = "",
  handleCartProducts = "",
  handleQtyChange,
  handleCouponDiscount,
}) => {
  const { Cart } = useSelector((store) => store.CartReducer);
  const { userData } = useSelector((store) => store.AuthReducer);
  const dispatch = useDispatch();
  MRP = parseInt(product_price);
  // eslint-disable-next-line
  const [currentSizeShow, setCurrentSize] = useState(
    variation || variations[0]
  );
  const [currentQty, setCurrentQty] = useState(1);
  const [price, setPrice] = useState(Number(dicounted_price));
  const [mrp, setMrp] = useState(Number(product_price));
  const [discountpercentage, setDiscountPercentage] = useState(0);
  const handleUpdateCartIfSizeExits = (params) => {
    const { id, product_id, variation } = params;
    var x = 0;
    Cart.filter((item) => {
      if (
        item.product_id === product_id &&
        item.variation === variation &&
        item.id !== id
      ) {
        x = 2;
        return false; // Remove the item from the cart
      }
      return true; // Keep all other items in the cart
    });

    if (x === 2) {
      const updatedCarts = Cart.filter((item) => {
        if (item.id === id) {
          return false; // Remove the item from the cart
        }
        return true; // Keep all other items in the cart
      });
      // console.log("updated cart", updatedCarts);
      if (updatedCarts.length !== Cart.length) {
        dispatch(updateCartIfSizeExits(params, updatedCarts));
      }
    } else {
      dispatch(updateCartItemSize(params, Cart));
    }

    return x;
  };
  // eslint-disable-next-line
  const handleSize = (selectedVariation) => {
    // Access the selected value and el here
    const params = {
      id: id,
      product_id: product_id,
      variation: selectedVariation.variation,
      current_variation: variation,
      user_id: userData.id,
      cart_id: id,
    };
    const x = handleUpdateCartIfSizeExits(params);
    //  const qtyAllowed = findSelectedSizeMaxAllowedQty(selectedVariation.variation);
    const qtyAllowed = 1;
    if (parseInt(currentQty) > parseInt(qtyAllowed)) {
      showCustomToast(
        "Qantity added is greater than Available Quantity 2",
        "error"
      );

      return;
    } else {
      const selectedSize = selectedVariation;
      // console.log("size data", selectedSize);
      setCurrentSize(selectedSize.variation);
      setPrice(parseInt(selectedSize.discounted_variation_price));
      setMrp(parseInt(selectedSize.price));
      // eslint-disable-next-line
      if (x == 2) {
        setTotalAmount((prev) => prev - price * currentQty);
        setTotalMRP((prev) => prev - mrp * currentQty);
        setTotalMRPDiscount(
          (prev) =>
            totalMRP -
            totalAmount -
            (parseInt(mrp) * currentQty - price * currentQty)
        );
      } else {
        setTotalAmount(
          (prev) =>
            prev -
            price * currentQty +
            parseInt(selectedSize.discounted_variation_price) * currentQty
        );
        setTotalMRP(
          (prev) =>
            prev - mrp * currentQty + parseInt(selectedSize.price) * currentQty
        );
        setTotalMRPDiscount(
          (prev) =>
            totalMRP -
            totalAmount -
            (parseInt(mrp) * currentQty - price * currentQty) +
            (parseInt(selectedSize.price) * currentQty -
              parseInt(selectedSize.discounted_variation_price) * currentQty)
        );
      }
    }
  };

  const checkProductInStock = (qty) => {
    return parseInt(qty) > parseInt(product_qty) ? false : true;
  };

  const handleQty = (e) => {
    e = e.target.value;
    if (!checkProductInStock(e)) {
      showCustomToast(
        "Qantity added is greater than Available Quantity!!",
        "error"
      );

      return;
    }
    setTotalMRP((prev) => {
      // console.log("cart prev 2", prev);
      return prev + mrp * (e - currentQty);
    });
    setTotalMRPDiscount(
      (prev) => prev + (mrp * (e - currentQty) - price * (e - currentQty))
    );
    setTotalAmount((prev) => prev + price * (e - currentQty));
    setCurrentQty(e);
    handleQtyChange();
    const params = {
      cart_id: id,
      qty: e,
    };
    // setDiscountPercentage(Math.round(((mrp * e - price * e) / (mrp * e)) * 100));
    setDiscountPercentage(discount);
    dispatch(updateCart(params));
  };

  useEffect(() => {
    setTotalMRP((prev) => {
      // console.log("cart prev 1", prev);
      return prev + MRP * qty;
    });
    setTotalMRPDiscount((prev) => prev + (MRP * qty - price * qty));
    setTotalAmount((prev) => {
      // console.log("cart prev 3", price, ",", qty, ",", prev);
      return price * qty + prev;
    });
    // eslint-disable-next-line
  }, [MRP]);

  useEffect(() => {
    setCurrentQty(qty);
  }, [qty]);

  useEffect(() => {
    setDiscountPercentage(discount);
    // eslint-disable-next-line
  }, []);

  const handleDelete = () => {
    const params = {
      user_id: userData.id,
      cart_id: id,
    };

    const updatedCarts = Cart.filter((item) => {
      if (item.id === id) {
        return false; // Remove the item from the cart
      }
      return true; // Keep all other items in the cart
    });

    const deleted_cart_amount = price * currentQty;
    setTotalAmount((prev) => prev - deleted_cart_amount);
    dispatch(deleteCart(params, updatedCarts));
    setTotalMRP((prev) => prev - mrp * currentQty);
    // setTotalMRPDiscount(
    //   (prev) =>
    //     totalMRP -
    //     totalAmount -
    //     (parseInt(mrp) * currentQty - price * currentQty)
    // );
    var discountamount = (discount / 100) * (mrp * currentQty);
    setTotalMRPDiscount((prev) => prev - discountamount);
    handleCouponDiscount(price * currentQty);
  };

  return (
    <>
      <Grid
        templateColumns="repeat(2, 1fr)"
        textAlign="left"
        gap={4}
        p={"10px"}
        border={"1px solid #ebebed"}
        borderRadius="5px"
      >
        {/* <Box p={"15px"}>
          <Image
            src={image_url} alt="">
          </Image>
        </Box> */}
        <GridItem colSpan={2} mb={2}>
          <HStack>
            <Image w={100} src={image_url} alt=""></Image>
            <VStack align="flex-start">
              <Text fontWeight={"bold"} fontSize="14px" color="#282c3f">
                {brand}
              </Text>
              <Text
                fontSize={"14px"}
                color="#282c3f"
                fontWeight={400}
                // isTruncated
                // w={"400px"}
              >
                {product_name}
              </Text>
              <HStack>
                {variation ? (
                  <HStack borderRadius={"5px"} bgColor={"#f5f5f6"}>
                    <Text
                      pl={1}
                      color={"#282c3f"}
                      fontSize={"14px"}
                      fontWeight={"bold"}
                    >
                      Size:
                    </Text>
                    <Text p={1} color={"#282c3f"} fontSize={"12px"}>
                      {variation}
                    </Text>
                  </HStack>
                ) : (
                  ""
                )}

                <HStack borderRadius={"5px"} bgColor={"#f5f5f6"}>
                  <Text
                    pl={1}
                    color={"#282c3f"}
                    fontSize={"14px"}
                    fontWeight={"bold"}
                  >
                    Qty:
                  </Text>
                  <Select
                    color={"#282c3f"}
                    icon={<MdArrowDropDown />}
                    size={"sm"}
                    p={0}
                    m={0}
                    variant={"unstyled"}
                    value={currentQty}
                    onChange={handleQty}
                    fontWeight="500"
                  >
                    {[...Array(10)].map((_, index) => (
                      <option fontWeight="500" value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </Select>
                </HStack>
              </HStack>

              <HStack fontSize={"14px"}>
                <Text color={"#282c3f"} fontWeight={"bold"}>
                  ₹ {price * currentQty}
                </Text>
                <Text
                  color={"#94969f"}
                  fontWeight={400}
                  textDecor="line-through"
                >
                  ₹ {mrp * currentQty}
                </Text>
                <Text color={"#f16565"} fontWeight={400}>
                  {discountpercentage}% OFF
                </Text>
              </HStack>
              <HStack fontSize={"12px"} spacing={1}>
                <Icon fontSize={"16px"} color="282c3f" as={TbTruckReturn} />
                <Text fontWeight={"bold"} color="282c3f">
                  {return_days} days
                </Text>
                <Text color="282c3f">return available</Text>
              </HStack>
            </VStack>
          </HStack>
        </GridItem>
        <GridItem colStart={3} colEnd={6}>
          <Box
            position={"relative"}
            top={"3px"}
            right={"5px"}
            fontSize={"20px"}
            color="282c3f"
            cursor={"pointer"}
            onClick={() => handleDelete(id)}
          >
            &#x2715;
          </Box>
        </GridItem>
      </Grid>
    </>
  );
};

export default React.memo(SingleCartProduct);
