import { Box, HStack, VStack, Text, Button, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cod from "../Assets/cash-on-delivery.png";
import CodWhite from "../Assets/cash-on-delivery-white.png";
import Card from "../Assets/credit-card.png";
import CardWhite from "../Assets/credit-card-white.png";
import UPI from "../Assets/UPI.webp";
import Banking from "../Assets/net-banking.png";
import BankingWhite from "../Assets/net-banking-white.png";
import { useSelector, useDispatch } from "react-redux";
import { getCheckoutData, placeOrder } from "../Redux/CheckoutReducer/Action";
import showCustomToast from "./showCustomToast";
// import { placeOrder } from '../Redux/CheckoutReducer/Action';

const PaymentDetains1 = React.memo(
  ({
    convenienceFee,
    totalMRP,
    totalMRPDiscount,
    coupenDiscountAmount,
    pageType,
  }) => {
    return (
      <>
        <Box w="full">
          <VStack w={"full"} align={"left"} textAlign="left">
            <Text fontSize={"12px"} fontWeight={"bold"} color={"#535766"}>
              PRICE DETAILS
            </Text>
            <HStack w={"full"} justify={"space-between"}>
              <Text fontSize={"14px"} color="#282c3f">
                Total MRP
              </Text>
              <Text fontSize={"14px"} color="#282c3f">
                {totalMRP}
              </Text>
            </HStack>
            <HStack w={"full"} justify={"space-between"}>
              <Text fontSize={"14px"} color="#282c3f">
                Discount on MRP
              </Text>
              <Text fontSize={"14px"} color="#03a685">
                {totalMRPDiscount}
              </Text>
            </HStack>
            <HStack w={"full"} justify={"space-between"}>
              <Text fontSize={"14px"} color="#282c3f">
                Coupon Discount
              </Text>
              <Text fontSize={"14px"} color="#03a685">
                -{coupenDiscountAmount}
              </Text>
            </HStack>
            {pageType === 2 ? (
              <HStack w={"full"} justify={"space-between"}>
                <HStack fontSize={"14px"}>
                  <Text fontSize={"14px"} color="#282c3f">
                    Convenience Fee
                  </Text>
                  {/* <Text fontSize={"14px"} color={"#863e9c"} fontWeight={"bold"}>Know More</Text> */}
                </HStack>
                <HStack>
                  <Text fontSize={"14px"} color="#282c3f">
                    {convenienceFee}
                  </Text>
                  {/* <Text fontSize={"14px"} color="#03a685">FREE</Text> */}
                </HStack>
              </HStack>
            ) : (
              ""
            )}
          </VStack>
        </Box>
        {/* .......................... */}
      </>
    );
  }
);
const PaymentDetains2 = React.memo(
  ({
    coupenName,
    totalAmount,
    fee,
    convenienceFee = 0,
    handleConvenienceFee,
    totalMRP,
    totalMRPDiscount,
    couponAmount,
    pageType,
  }) => {
    //alert(convenienceFee)
    const navigate = useNavigate();
    const { defaultAddress, Redirect, Order_id } = useSelector(
      (store) => store.CheckoutReducer
    );
    const dispatch = useDispatch();
    const { checkOutSuccess } = useSelector((store) => store.CheckoutReducer);
    const [paymentMethod, setPaymentMethod] = useState("");
    const { userData } = useSelector((store) => store.AuthReducer);

    useEffect(() => {
      if (checkOutSuccess) {
        navigate("/address?pageType=2", { state: { addressId: 2 } });
        dispatch({ type: "RESET_STATUS" });
      }
      //eslint-disable-next-line
    }, [checkOutSuccess]);

    const handleCheckout = async () => {
      console.log("selectedAddress defaultAddress:", defaultAddress);
      const selectedAddressFromLocalStorage =
        localStorage.getItem("selectedAddress");
      let parsedAddress;
      try {
        parsedAddress = JSON.parse(selectedAddressFromLocalStorage);
      } catch (error) {
        parsedAddress = null;
      }
      // parsedAddress = JSON.parse(selectedAddressFromLocalStorage);

      if (
        defaultAddress.length === 0 &&
        (!parsedAddress || Object.keys(parsedAddress).length === 0)
      ) {
        showCustomToast(
          "Please select a valid address or set a default address.",
          "error"
        );
        return;
      }

      const param = {
        coupon_name: localStorage.getItem("coupenName"),
        user_id: userData.id,
        address_id: localStorage.getItem("selectedAddress")
          ? JSON.parse(localStorage.getItem("selectedAddress")).id
          : "",
      };

      dispatch(getCheckoutData(param));
    };

    // const handlePaymentSuccess = (response) => {
    //   const params = {
    //     grand_total: totalAmount,
    //     convenience_fee: convenienceFee,
    //     discount_amount: totalMRPDiscount,
    //     payment_type: paymentMethod,
    //     coupon_name: localStorage.getItem("coupenName"),
    //     user_id: userData.id,
    //     address_id: localStorage.getItem("selectedAddress")
    //       ? JSON.parse(localStorage.getItem("selectedAddress")).id
    //       : defaultAddress[0]
    //       ? defaultAddress[0].id
    //       : "",
    //     // address_id:localStorage.getItem("selectedAddress") ? JSON.parse(localStorage.getItem("selectedAddress")).id : null,
    //   };

    //   dispatch(
    //     placeOrder({
    //       ...params,
    //       razorpay_payment_id: response.razorpay_payment_id,
    //     })
    //   );
    // };

    // const handlePaymentFailure = (response) => {};

    // const options = {
    //   key: "rzp_test_rpYAjWo8FDJpTk", // Replace with your actual API key
    //   name: "98 Degree North",
    //   description: "Test Payment",
    //   image: "https://kk-logo.boxinallsoftech.com/logo.png", // Replace with your company logo URL
    //   theme: {
    //     color: "#F37254", // Replace with your desired theme color
    //   },
    //   prefill: {
    //     name: userData.name, // Replace with the customer's name
    //     email: userData.email, // Replace with the customer's email
    //     contact: userData.mobile, // Use the mobile number from your state
    //   },
    //   handler: function (response) {
    //     if (response.razorpay_payment_id) {
    //       handlePaymentSuccess(response);
    //     } else {
    //       handlePaymentFailure(response);
    //     }
    //   },
    // };

    function handlePaymentOption(val) {
      if (val === "1") {
        handleConvenienceFee(fee);
      } else {
        handleConvenienceFee(0);
      }
      setPaymentMethod(val);
    }

    const handleMakeOrder = () => {
      const params = {
        grand_total: totalAmount,
        convenience_fee: convenienceFee,
        discount_amount: totalMRPDiscount,
        payment_type: paymentMethod,
        coupon_name: localStorage.getItem("coupenName"),
        user_id: userData.id,
        address_id: localStorage.getItem("selectedAddress")
          ? JSON.parse(localStorage.getItem("selectedAddress")).id
          : defaultAddress[0]
          ? defaultAddress[0].id
          : "",
        // address_id:localStorage.getItem("selectedAddress") ? JSON.parse(localStorage.getItem("selectedAddress")).id : null,
      };
      //eslint-disable-next-line
      const codCallback = (order_id, amount, order_number) => {
        navigate(
          `/paymentSuccess?order_id=${order_id}&amount=${amount}&order_number=${order_number}`
        );
      };
      if (paymentMethod === "") {
        showCustomToast("Please Select Payment Mode.", "error");
        return;
      } else if (paymentMethod === "1") {
        dispatch(placeOrder(params, codCallback));
      } else {
        dispatch(placeOrder(params));
      }

      // if (paymentMethod === "1") {
      //   dispatch(placeOrder(params));
      // } else {

      //   razorpay.openRazorpayModal(1000, "INR", options);
      // }
    };

    useEffect(() => {
      if (Redirect) {
        window.location.href = `https://admin.98degreenorth.com/front/payment?order_id=${Order_id}`;
      }
      //eslint-disable-next-line
    }, [Redirect]);

    // useEffect(() => {
    //   // alert(CheckoutCartList.length );
    //   if (toastMessage && CheckoutCartList.length > 0) {
    //     navigate(`/paymentSuccess?pageType=${3}`);
    //     showCustomToast(toastMessage, "success");
    //   } else if (toastMessage && CheckoutCartList.length < 1) {
    //     showCustomToast(toastMessage, "success");
    //   }

    //   //eslint-disable-next-line
    // }, [toastMessage, OrderStatus, navigate]);

    return (
      <>
        <Box w="full">
          <VStack w={"full"} align="flex-start">
            <HStack w={"full"} justify={"space-between"}>
              <Text fontSize={"14px"} color={"#3e4152"} fontWeight={"bold"}>
                TOTAL AMOUNT
              </Text>
              <Text fontSize={"14px"} color={"#3e4152"} fontWeight={"bold"}>
                ₹ {totalAmount + convenienceFee}
              </Text>
            </HStack>

            {/* eslint-disable-next-line */}
            {pageType == 2 ? (
              <>
                {/* <Divider></Divider> */}
                <HStack justifyContent={"flex-start"} mt={5}>
                  <Text fontSize={"12px"} fontWeight={"bold"} color={"#535766"}>
                    PAYMENT MODE
                  </Text>
                </HStack>
                {/* <RadioGroup defaultValue="1" onChange={handlePaymentOptionChange}> */}
                <Box w={"full"} display={"flex"} justifyContent={"center"}>
                  <HStack spacing={4}>
                    {/* <Radio value="1"> <Text fontSize={"14px"} color="#282c3f">COD</Text></Radio>
                        <Radio value="2"> <Text fontSize={"14px"} color="#282c3f">Pay Online</Text></Radio> */}
                    <Box
                      w={70}
                      justifyContent={"center"}
                      textAlign={"center"}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      border={`1px solid #b8b8b8cb`}
                      bg={`${paymentMethod === "1" ? "#000000" : "#FFFFFF"}`}
                      boxShadow="base"
                      p={2}
                      borderRadius={5}
                      onClick={() => handlePaymentOption("1")}
                    >
                      <Image
                        w={5}
                        h={5}
                        src={`${paymentMethod === "1" ? CodWhite : Cod}`}
                      ></Image>
                      <Text
                        fontSize={"10px"}
                        color={`${
                          paymentMethod === "1" ? "#FFFFFF" : "#282c3f"
                        }`}
                      >
                        COD
                      </Text>
                    </Box>
                    <Box
                      w={70}
                      justifyContent={"center"}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      border={`1px solid #b8b8b8cb`}
                      bg={`${paymentMethod === "2" ? "#000000" : "#FFFFFF"}`}
                      boxShadow="base"
                      p={2}
                      borderRadius={5}
                      onClick={() => handlePaymentOption("2")}
                    >
                      <Image
                        w={5}
                        h={5}
                        src={`${paymentMethod === "2" ? CardWhite : Card}`}
                      ></Image>
                      <Text
                        fontSize={"8px"}
                        color={`${
                          paymentMethod === "2" ? "#FFFFFF" : "#282c3f"
                        }`}
                      >
                        Debit/Credit
                      </Text>
                    </Box>
                    <Box
                      w={70}
                      justifyContent={"center"}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      border={`1px solid #b8b8b8cb`}
                      bg={`${paymentMethod === "3" ? "#000000" : "#FFFFFF"}`}
                      boxShadow="base"
                      p={2}
                      borderRadius={5}
                      onClick={() => handlePaymentOption("3")}
                    >
                      <Image
                        w={5}
                        h={5}
                        src={`${
                          paymentMethod === "3" ? BankingWhite : Banking
                        }`}
                      ></Image>
                      <Text
                        fontSize={"8px"}
                        color={`${
                          paymentMethod === "3" ? "#FFFFFF" : "#282c3f"
                        }`}
                      >
                        Banking
                      </Text>
                    </Box>
                    <Box
                      w={70}
                      justifyContent={"center"}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      border={`1px solid #b8b8b8cb`}
                      bg={`${paymentMethod === "4" ? "#000000" : "#FFFFFF"}`}
                      boxShadow="base"
                      p={2}
                      borderRadius={5}
                      onClick={() => handlePaymentOption("4")}
                    >
                      <Image w={5} h={5} src={UPI}></Image>
                      <Text
                        fontSize={"10px"}
                        color={`${
                          paymentMethod === "4" ? "#FFFFFF" : "#282c3f"
                        }`}
                      >
                        UPI
                      </Text>
                    </Box>
                  </HStack>
                </Box>
                {/* </RadioGroup> */}
              </>
            ) : null}
            <Text
              fontSize={"14px"}
              pt="7px"
              fontWeight={"bold"}
              color="#535766"
            >
              To save convenience fee, pay online !
            </Text>

            <Button
              my={4}
              w={"100%"}
              onClick={() => {
                pageType === 2 ? handleMakeOrder() : handleCheckout();
              }}
              color={"#fff"}
              variant={"solid"}
              bg={window.innerWidth > 500 ? "red" : "black"}
              _hover={{
                bg: window.innerWidth > 500 ? "red.200" : "black",
                color: "white",
              }}
              borderRadius={3}
              fontSize={"15px"}
            >
              PLACE ORDER
            </Button>
            <Box h={20}></Box>
          </VStack>
        </Box>
        {/* .......................... */}
      </>
    );
  }
);

export { PaymentDetains1, PaymentDetains2 };
