import React, { useEffect, useState } from "react";
import {
  SimpleGrid,
  Center,
  Image,
  VStack,
  Text,
  Box,
  Spinner,
  Show,
} from "@chakra-ui/react";
import styles from "../css/Order.module.css";
import Order from "../Components/Order";
import Navbar2 from "../Components/Navbar2";
import axios from "axios";
import { Heading } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import shoppingBag from "../Assets/shopping-bag.png";

const Orders = () => {
  const { userData } = useSelector((store) => store.AuthReducer);
  const [ordersList, setOrdersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchOrdersHistoryAPICall();
    //eslint-disable-next-line
  }, []);

  const fetchOrdersHistoryAPICall = () => {
    axios({
      method: "post",
      url: process.env.REACT_APP_API + "/orderhistory",
      data: { user_id: userData.id },
      withCredentials: false,
    })
      .then((res) => {
        setOrdersList(res.data.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setOrdersList([]);
        setIsLoading(false);
      });
  };
  const onUpdate = () => {
    fetchOrdersHistoryAPICall();
  };

  return (
    <Box bg={"#b7b7b724"}>
      <Navbar2 />
      <div className={styles.main}>
        {/* <div className={styles.heading}>Order History</div> */}
        <Heading fontWeight={400} className={styles.heading} size={"lg"}>
          Your Order History
        </Heading>
      </div>
      {/* eslint-disable-next-line */}
      {isLoading ? (
        <>
          <Box
            position="fixed"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            w="full"
            h="full"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bg="rgba(255, 255, 255, 0.8)"
            zIndex="9999"
          >
            <Spinner size="lg" color="gray.500" />
          </Box>
        </>
      ) : //eslint-disable-next-line
      ordersList.length == 0 ? (
        <>
          <SimpleGrid
            columns={1}
            spacingY="60px"
            spacingX="30px"
            marginTop={"80px"}
            w="100%"
          >
            <Center>
              <VStack>
                <Image w="100px" src={shoppingBag} />
                <Text fontWeight={700} fontSize="20px" color={"424553"}>
                  Ohh!
                </Text>
                <Text
                  fontSize={"14px"}
                  pb="20px"
                  fontWeight={400}
                  color="#7e818c"
                >
                  No orders.
                </Text>
              </VStack>
            </Center>
          </SimpleGrid>{" "}
        </>
      ) : (
        <>
          <Show breakpoint="(max-width: 500px)">
            <Order onUpdate={onUpdate} ordersList={ordersList} />
          </Show>
          <Show breakpoint="(min-width: 500px)">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Order onUpdate={onUpdate} ordersList={ordersList} />
            </div>
          </Show>
        </>
      )}

      {/* <Footer /> */}
    </Box>
  );
};

export default Orders;
