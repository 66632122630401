
import styles from "../css/Profile.module.css";
import {
  Box,Grid,GridItem,Text,Icon,Button
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CiUser } from "react-icons/ci";
import { faHistory, faChevronRight, faMapMarkerAlt, faSignOutAlt,faPhone,faBook, faQuestion,faPager } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Redux/AuthReducer/Action';

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData,isAuth } = useSelector((store) => store.AuthReducer);
  const handleItemClick = (item) => {
    if (item.onClick) {
      item.onClick();
    }
    if (item.navigate) {
      navigate(item.navigate);
    }
  };

  const settingsData = [
    {
      item: "Order History",
      icon: faHistory,
      navigate: '/orders'
    },
    {
      item: "My Address",
      icon: faMapMarkerAlt,
      navigate: `/profile/address?pageType=${1}`
    },
    {
      item: "Contact us",
      icon: faPhone,
      navigate: '/contact'
    },
    {
      item: "Terms and Conditions",
      icon: faBook,
      navigate: '/termscondition'
    },
    {
      item: "FAQ",
      icon: faQuestion,
      navigate: '/faq'
    },
    {
      item: "Privacy Policy",
      icon: faPager,
      navigate: '/privacy'
    },
    {
      item: "Sign Out",
      icon: faSignOutAlt,
      onClick: () => {
        dispatch(login("logout"));
      }
    },
    // Add more objects as needed
  ];

  const settingsData1 = [
    {
      item: "Order History",
      icon: faHistory,
      navigate: '/orders'
    },
    {
      item: "My Address",
      icon: faMapMarkerAlt,
      navigate: `/profile/address?pageType=${1}`
    },
    {
      item: "Contact us",
      icon: faPhone,
      navigate: '/contact'
    },
    {
      item: "Terms and Conditions",
      icon: faBook,
      navigate: '/termscondition'
    },
    {
      item: "FAQ",
      icon: faQuestion,
      navigate: '/faq'
    },
    {
      item: "Privacy Policy",
      icon: faPager,
      navigate: '/privacy'
    },
    // Add more objects as needed
  ];

  return (
    <>
      {/* heading */}

      <div className={styles.main}>
          <Box w={'100%'} py={5} display={'grid'} justifyContent={'center'}>
            <div className={styles.heading}>Profile</div>
            <Grid w={'100%'} templateRows='repeat(2)' templateColumns='repeat(5, 1fr)' gap={2} px={10}>
              <GridItem colSpan={1}>
                <Box
                  w="70px"
                  h="70px"
                  borderRadius="50%" // This sets the box to be a circle
                  bg="black"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  fontWeight="bold"
                  mb={2}
                  fontSize="24px">
                    {isAuth ? userData.name.charAt(0).toUpperCase() : <Icon as={CiUser} fontSize="25px" /> }  
                </Box>
              </GridItem>
              <GridItem colSpan={4}>
                {isAuth ? (
                  <Box pt={3}>
                  <Text style={{ fontWeight: '400', fontSize: '14px' }}>{userData.name}</Text>
                  <Text style={{ fontWeight: '300', fontSize: '12px', color: 'rgba(128, 128, 128, 0.8)' }} >{userData.email}</Text>
                </Box>
                ) : (
                  <Box pt={5}>
                    <Button w={'full'} bg='black' color={'#FFFFFF'} colorScheme='black' size={'sm'} onClick={()=> navigate('/login')} >
                      Sign in / Join
                    </Button>                  
                  </Box>
                )}
              </GridItem>
            </Grid>
          </Box>

          {isAuth ? 
          (settingsData.map((item,index) => (
          <div key={index} onClick={() => handleItemClick(item)} style={{ cursor: 'pointer' }}>
            <div className={styles.container2}>
              <div className={styles.section1}>

                <FontAwesomeIcon icon={item.icon} style={{ color: 'rgba(128, 128, 128, 0.8)' }} />
                <p style={{ fontWeight: '300', fontSize: '14px', width: '80%' }}>{item.item}</p>
              </div>
              <div className={styles.section2}>
                <p style={{ color: '#000000' }} ><span> <FontAwesomeIcon icon={faChevronRight} style={{ color: 'rgba(128, 128, 128, 0.8)' }} /></span></p>

              </div>
            </div>
            {index !== settingsData.length - 1 && <div className={styles.divider}></div>}
          </div>
            ))) 
            : 
            (settingsData1.map((item,index) => (
              <div key={index} onClick={() => handleItemClick(item)} style={{ cursor: 'pointer' }}>
                <div className={styles.container2}>
                  <div className={styles.section1}>

                    <FontAwesomeIcon icon={item.icon} style={{ color: 'rgba(128, 128, 128, 0.8)' }} />
                    <p style={{ fontWeight: '300', fontSize: '14px', width: '80%' }}>{item.item}</p>
                  </div>
                  <div className={styles.section2}>
                    <p style={{ color: '#000000' }} ><span> <FontAwesomeIcon icon={faChevronRight} style={{ color: 'rgba(128, 128, 128, 0.8)' }} /></span></p>

                  </div>
                </div>
                {index !== settingsData.length - 1 && <div className={styles.divider}></div>}
              </div>
            )))
          }

      </div>
    </>
  );
};

export default Profile;
