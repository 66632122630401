import { useNavigate } from "react-router";
import styles from "../css/Footer.module.css";

import { Image } from "@chakra-ui/react";
import fbIcon from "../Assets/square-facebook.svg";
import instaIcon from "../Assets/square-instagram.svg";
import twitterIcon from "../Assets/square-x-twitter.svg";
import ytIcon from "../Assets/square-youtube.svg";
import threadsIcon from "../Assets/square-threads.svg";
import returnDays from "../Assets/returnDays.png";

// let searches="Makeup  Dresses For Girls  T-Shirts  Sandals  Headphones  Babydolls  Blazers For Men  Handbags  Ladies Watches  Bags  Sport Shoes  Reebok Shoes  Puma Shoes  Boxers  Wallets  Tops  Earrings  Fastrack Watches  Kurtis  Nike  Smart Watches  Titan Watches  Designer Blouse  Gowns  Rings  Cricket Shoes  Forever 21  Eye Makeup  Photo Frames  Punjabi Suits  Bikini  Myntra Fashion Show  Lipstick  Saree  Watches  Dresses  Lehenga  Nike Shoes  Goggles  Bras  Suit  Chinos  Shoes  Adidas Shoes  Woodland Shoes  Jewellery  Designers Sarees"

export default function Footer() {
  const navigate = useNavigate();
  return (
    <div style={{ padding: "0px 30px", background: "#fafbfc" }}>
      <div className={styles.main}>
        <div>
          <p>Customer Policies</p>
          <p
            onClick={() => {
              navigate("/contact");
            }}
          >
            Contact Us
          </p>
          <p
            onClick={() => {
              navigate("/faq");
            }}
          >
            FAQ
          </p>
          <p
            onClick={() => {
              navigate("/termscondition");
            }}
          >
            T&C
          </p>
          <p
            onClick={() => {
              navigate("/terms");
            }}
          >
            Terms Of Use
          </p>
          <p
            onClick={() => {
              navigate("/privacy");
            }}
          >
            {" "}
            Privacy policy
          </p>
        </div>
        <div className={styles.mobile}>



            <p>KEEP IN TOUCH</p>
            <div id="socialmedia">
              <a href="https://www.facebook.com/98degreeNorth">
                <Image src={fbIcon}></Image>
              </a>
              <a href="https://www.youtube.com/channel/UCesrrS__IzjQPxgnzJoaiVQ">
                <Image src={ytIcon}></Image>
              </a>
              <a href="https://www.threads.net/@98degreenorth">
                <Image src={threadsIcon}></Image>
              </a>
              <a href="https://twitter.com/98degreenorth">
                <Image src={twitterIcon}></Image>
              </a>
              <a href="https://www.instagram.com/98degreenorth/">
                <Image src={instaIcon}></Image>
              </a>

              {/* <img src="https://constant.myntassets.com/web/assets/img/d2bec182-bef5-4fab-ade0-034d21ec82e31574604275433-fb.png" alt="" />
                <img src="https://constant.myntassets.com/web/assets/img/f10bc513-c5a4-490c-9a9c-eb7a3cc8252b1574604275383-twitter.png" alt="" />
                <img src="https://constant.myntassets.com/web/assets/img/a7e3c86e-566a-44a6-a733-179389dd87111574604275355-yt.png" alt="" />
                <img src="https://constant.myntassets.com/web/assets/img/b4fcca19-5fc1-4199-93ca-4cae3210ef7f1574604275408-insta.png" alt="" /> */}
            </div>

        </div>
        <div className={styles.div4Footer}>
          <div>
            <img
              src="https://constant.myntassets.com/web/assets/img/6c3306ca-1efa-4a27-8769-3b69d16948741574602902452-original.png"
              alt=""
            />
            <div>
              <span className={styles.outerP}>100% ORIGINAL </span>
              <p>guarantee for all products at 98degreenorth.com</p>
            </div>
          </div>
          <div>
            <img src={returnDays} alt="" />
            <div style={{ marginLeft: 4 }}>
              <span className={styles.outerP}>Return within 7 days </span>

              <p>of receiving your order</p>
            </div>
          </div>
        </div>
        {/* <div>
              <p>USEFUL LINKS</p>
              <p>Blog</p>
              <p>Careers</p>
              <p>Site Map</p>
              <p>Corporate Information</p>
              <p>Whitehat</p>
       </div> */}
      </div>

      {/* <div className={styles.hr}>
        <p>POPULAR SEARCHES</p>
       <div>
       <hr />
       </div>
    </div>

    <div className={styles.flexLinks}>
        {searches.trim().split("  ").map((el)=>{
            return <div key={el+Math.random(100)}><a className={styles.searches} href="/">{el}</a> | </div>
        })}
    </div>
     */}
      {/* <div className={styles.lastDiv}>
        <div>
            <p>In case of any concern, </p>
            <a href="/">Contact Us</a>
        </div>
        <div>
            <p>© 2022 www.kkfabrics.com. All rights reserved.</p>
        </div>
    </div> */}
      <div>
        <hr />
      </div>
    </div>
  );
}
