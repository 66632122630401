export const GET_ADDRESS_REQUEST = "GET_ADDRESS_REQUEST";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAILURE = "GET_ADDRESS_FAILURE";

export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_SELECTED_SUCCESS = "ADD_SELECTED_SUCCESS";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";

export const SAVE_CHECKOUTDATA_SUCCESS = "SAVE_CHECKOUTDATA_SUCCESS";
export const GET_CHECKOUTDATA_FAILURE = "GET_CHECKOUTDATA_FAILURE";

//order
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";






