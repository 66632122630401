import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  VStack,
  ModalHeader
} from '@chakra-ui/react';
import CouponCard from './CoupanCard';


const ViewCoupanModal = ({ isOpen, onClose,price ,discountedprice}) => {
    const [coupenList, setCoupenList] = useState([]);
    useEffect(() => {
        axios({
          url: process.env.REACT_APP_API + "/coupons?details_page_visible=1",
        }).then((res) => {
          // console.log("coupen", res.data.data.data);
          setCoupenList(res.data.data.data)
        })
      }, []);



  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'sm'} >
      <ModalOverlay />
      <ModalContent>
      <ModalHeader>All Coupons</ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingX={4}>
        <VStack>
            {coupenList.map((coupon) => (

            <CouponCard
                key={coupon.id}
                couponData={coupon}
                price={price}
                discountedprice={discountedprice}
                showApplyButton={false}

                // onApplyCoupon={(c)=>handleApplyCoupon(c)}
            />
            ))}
            </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewCoupanModal;
