import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showCustomToast = (description, status) => {
  let toastStatus = "info";

  if (status === "success") {
    toastStatus = "success";
  } else if (status === "error") {
    toastStatus = "error";
  } else if (status === "warning") {
    toastStatus = "warning";
  }

  toast[toastStatus](description, {
    // position: toast.POSITION.BOTTOM,
    autoClose: 500,
    closeButton: true,
    hideProgressBar: true,
    draggable: true,
  });
};

export default showCustomToast;
