import * as types from "./ActionTypes.js";
import axios from "axios";
import { toast } from "react-toastify";
import { getCartCountAsync } from "../NavBarhelperdataslice.js";
import "react-toastify/dist/ReactToastify.css";

// ...............isLoading state

// -------------address------------
export const getAddressLoading = () => {
  return {
    type: types.GET_ADDRESS_REQUEST,
  };
};

export const getAddressSuccess = (payload) => {
  return {
    type: types.GET_ADDRESS_SUCCESS,
    payload,
  };
};

export const getAddressFailure = () => {
  return {
    type: types.GET_ADDRESS_FAILURE,
  };
};

export const addAddressSuccess = (payload) => {
  return {
    type: types.ADD_ADDRESS_SUCCESS,
    payload,
  };
};

export const selectedAddress = (payload) => {
  return {
    type: types.ADD_SELECTED_SUCCESS,
    payload,
  };
};

export const updateAddress = (payload) => {
  return {
    type: types.UPDATE_ADDRESS_SUCCESS,
    payload,
  };
};

export const deleteAnAddress = (payload) => {
  return {
    type: types.DELETE_ADDRESS_SUCCESS,
    payload,
  };
};

export const saveCheckoutData = (payload) => {
  return {
    type: types.SAVE_CHECKOUTDATA_SUCCESS,
    payload,
  };
};

export const getCheckoutFailure = (payload) => {
  return {
    type: types.GET_CHECKOUTDATA_FAILURE,
    payload,
  };
};
export const showToast = (message) => ({
  type: "SHOW_TOAST",
  payload: message,
});

export const getAddress = (params) => async (dispatch) => {
  dispatch(getAddressLoading());

  //console.log("before address api is hit", params);
  // return;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/getaddress`,
      params
    );
    // console.log("after address api is hit", response);

    dispatch(getAddressSuccess(response.data.data));
  } catch (error) {
    dispatch(getAddressFailure());
  }
};

export const addAddress = (params) => async (dispatch) => {
  //  alert(params.addressPage);
  dispatch(getAddressLoading());

  // console.log("before addAddress api is hit", params);
  // return;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/saveaddress`,
      params
    );

    // console.log("after addAddress api is hit", response.data.data);
    //   localStorage.setItem("selectedAddressId", JSON.stringify(response.data.data.id));
    if (params.addressPage && params.addressPage === "cart") {
      localStorage.setItem(
        "selectedAddress",
        JSON.stringify(response.data.data)
      );
    }

    dispatch(selectedAddress(response.data.data));
    dispatch(addAddressSuccess(response.data.data));
  } catch (error) {
    dispatch(getAddressFailure());
  }
};

export const editAddress = (params) => async (dispatch) => {
  dispatch(getAddressLoading());

  // console.log("oye before addAddress api is hit", params);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/editaddress`,
      params
    );
    //  console.log("after editAddress api is hit", response.data.data);
    dispatch(getAddressSuccess(response.data.data));

    //   localStorage.setItem("selectedAddressId", JSON.stringify(response.data.data.id));

    //   dispatch(selectedAddress(response.data.data));
  } catch (error) {
    dispatch(getAddressFailure());
  }
};

export const getCheckoutData = (params) => async (dispatch) => {
  // console.log("getCheckoutData is hit",params);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/checkout`,
      params
    );

    if (response.data.status === 0) {
      dispatch(getCheckoutFailure());
      showErrorToast(response.data.message);
    } else if (response.data.status === 1) {
      dispatch(saveCheckoutData(response.data));
    }

    //   localStorage.setItem("selectedAddressId", JSON.stringify(response.data.data.id));

    //   dispatch(selectedAddress(response.data.data));
  } catch (error) {
    dispatch(getCheckoutFailure());
    showErrorToast(error.response.data.message);
  }
};

export const deleteAddress = (params) => async (dispatch) => {
  dispatch(getAddressLoading());

  // console.log("oye before addAddress api is hit", params);
  dispatch(deleteAnAddress(params));
  // return;
  try {
    await axios.post(`${process.env.REACT_APP_API}/deleteaddress`, params);

    //   console.log("after addAddress api is hit", response.data.data);
    //   localStorage.setItem("selectedAddressId", JSON.stringify(response.data.data.id));

    //   dispatch(selectedAddress(response.data.data));
  } catch (error) {
    dispatch(getAddressFailure());
  }
};

export const selectAndSaveAddress = (params) => async (dispatch) => {
  localStorage.setItem("selectedAddress", JSON.stringify(params));

  dispatch(selectedAddress(params));
};

////order

export const getOrderSuccess = (payload) => {
  return {
    type: types.ADD_ORDER_SUCCESS,
    payload,
  };
};
export const getOrderFailure = (payload) => {
  return {
    type: types.GET_ORDER_FAILURE,
    payload,
  };
};

export const openPaymentGateway = (payload) => {
  return {
    type: "OPEN_PAYMENT_GATEWAY",
    payload,
  };
};

export const placeOrder = (params, codCallback) => async (dispatch) => {
  dispatch(getAddressLoading());

  // console.log("oye before addAddress api is hit", params);

  // return;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/order`,
      params
    );

    console.log("openPaymentGateway", response.data.data.order_id);
    if (params.payment_type === "1") {
      codCallback(
        response.data.data.order_id,
        response.data.data.order_total,
        response.data.data.order_number
      );
    } else {
      dispatch(openPaymentGateway(response.data.data.order_id));
    }
    dispatch(getCartCountAsync(params));
  } catch (error) {
    showErrorToast("Order Failed!!");
    dispatch(getOrderFailure());
  }
};

const showErrorToast = (message, onClose) => {
  toast.error(message, {
    autoClose: 1000, // Customize the duration (in milliseconds)
    onClose: onClose, // Pass the onClose callback
  });
};

// .......................
