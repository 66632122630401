
import * as types from "./ActionTypes";

const initialState = {
    Address: [],
    selectedAddress: [] || JSON.parse(localStorage.getItem("selectedAddress")),
    defaultAddress: {},
    CheckoutCartList: [],
    CheckoutAmount: {},
    isLoading: false,
    isError: false,
    toastMessage: null,
    OrderStatus:null,
    checkOutSuccess:false,

    Redirect:false,
    Order_id:"",

};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // isLoading state...................

        case types.GET_ADDRESS_REQUEST:
            return {
                ...state,
                isLoading: true,
                toastMessage: null,
            };

        // ...............................

        // Success state..................


        case 'OPEN_PAYMENT_GATEWAY':
            // console.log("products detail in reducer:", payload);
            localStorage.setItem("paymentStatus", "pending");
            return {
                ...state,
                Redirect: true,
                Order_id:payload

            };
        case types.GET_ADDRESS_SUCCESS:
           // console.log("default address 1 :", payload);
             //eslint-disable-next-line
            const defaultAddressFetch=payload.filter((item,index)=>{

                   //eslint-disable-next-line
                  if(item.default_address == 1)
                  {
                    return item;
                  }

            });
              console.log("default address 1 :", payload);
            console.log("selectedAddress default address :", defaultAddressFetch);
            return {
                ...state,
                Address: payload,
                defaultAddress:defaultAddressFetch,
                isLoading: false,
            };
        // Products Detail

        case types.GET_ADDRESS_FAILURE:
            // console.log("products detail in reducer:", payload);
            return {
                ...state,
                Address: [],
                isLoading: false,
            };

        case types.ADD_ADDRESS_SUCCESS:
            // console.log("products detail in reducer:", payload);
            return {
                ...state,
                Address: [...state.Address, payload],
                isLoading: false,
            };


        case types.UPDATE_ADDRESS_SUCCESS:
            const updatedAddressIndex = state.Address.findIndex((item) => item.id === payload.id);
            if (updatedAddressIndex !== -1) {
                const updatedAddresses = [...state.Address];
                updatedAddresses[updatedAddressIndex] = payload;
                // console.log("oye products detail in reducer:", updatedAddresses);
                return {
                    ...state,
                    Address: updatedAddresses,
                    isLoading: false,
                };
            }
            return state;


        case types.DELETE_ADDRESS_SUCCESS:
            const selectedAddress=JSON.parse(localStorage.getItem("selectedAddress"));
            if(selectedAddress)
            {
                var id=selectedAddress.id;
                if(id === payload.id)
                {
                    localStorage.setItem("selectedAddress", null);
                }
            }
            const updatedAddress = state.Address.filter((item) => item.id !== payload.id);

            // console.log("oye products detail in reducer:", updatedAddress);
            return {
                ...state,
                Address: updatedAddress,
                isLoading: false,
            };



        case types.ADD_SELECTED_SUCCESS:
            // console.log("products detail in reducer:", payload);
            return {
                ...state,
                selectedAddress: payload,
                isLoading: false,
            };


        case types.SAVE_CHECKOUTDATA_SUCCESS:
            // console.log("checkout detail in reducer:", payload.data);
            return {
                ...state,
                CheckoutAmount: payload.data,
                CheckoutCartList: payload.cartdata,
                isLoading: false,
                checkOutSuccess:true
            };


            case types.GET_CHECKOUTDATA_FAILURE:

                return {
                    ...state,
                    CheckoutAmount: {},
                    CheckoutCartList: [],
                    isLoading: false,
                    checkOutSuccess:false
                };

            case 'RESET_STATUS':

                return {
                    ...state,
                    checkOutSuccess:false
                };
        //

        case types.ADD_ORDER_SUCCESS:
            // console.log("checkout detail in reducer:", payload);
            return {
                ...state,
                CheckoutAmount: [],
                CheckoutCartList: [],
                OrderStatus:"Completed",
                isLoading: false,

            };



        case types.GET_ORDER_FAILURE:
            // console.log("checkout detail in reducer:", payload);
            return {
                ...state,
                OrderStatus:"Pending",
                CheckoutCartList:[],
                isLoading: false,
            };

        case 'SHOW_TOAST': // New case for SHOW_TOAST action
            return {
                ...state,
                toastMessage: payload,
            };

        // ...............................
        // default case

        default:
            return state;
    }

    // ................................
};

export { reducer };