import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, text,product }) => {
  const handleConfirm = () => {
    onConfirm(true,product); // Return true if user confirms
    onClose();
  };

  const handleCancel = () => {
    onConfirm(false); // Return false if user cancels
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{text}</ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={handleConfirm}>
            Yes
          </Button>
          <Button variant="ghost" onClick={handleCancel}>
            No
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default ConfirmationModal;