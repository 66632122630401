import {
  Box,
  HStack,
  VStack,
  StackDivider,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Text,
  Button,
  useDisclosure,
  Spinner,
  SimpleGrid,
  Center,
  Icon,
  Grid,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import Navbar from '../Components/Navbar'
import AddressModal from "../Components/AddressModal";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentDetains1, PaymentDetains2 } from "../Components/PaymentDetains";
import Navbar2 from "../Components/Navbar2";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteAddress,
  getAddress,
  getCheckoutData,
} from "../Redux/CheckoutReducer/Action";
import AddressCard from "../Components/AddressCard";
import OtherNavbar from "../Components/OtherNavbar";
import { MdLocalShipping } from "react-icons/md";

const Address = () => {
  const {
    Address,
    selectedAddress: selectedCartAddress,
    defaultAddress,
    CheckoutAmount,
    isLoading,
  } = useSelector((store) => store.CheckoutReducer);



    const defaultAddressFromLocalStorage = JSON.parse(localStorage.getItem("selectedAddress"));
    const [isDefaultAddress, setIsDefaultAddress] = useState(
        defaultAddressFromLocalStorage ? defaultAddressFromLocalStorage.id : (defaultAddress[0] ? defaultAddress[0].id : '')
      );

  console.log("selectedAddress local: ",JSON.parse(localStorage.getItem("selectedAddress")));
  console.log("selectedAddress here: ",defaultAddress[0] ? defaultAddress[0].id : '');

  useEffect(()=>{
    setIsDefaultAddress( defaultAddressFromLocalStorage ? defaultAddressFromLocalStorage.id : (defaultAddress[0] ? defaultAddress[0].id : ''))
    //eslint-disable-next-line
  },[defaultAddress])

  //   const [isDefaultAddress, setIsDefaultAddress] = useState(
  //     defaultAddress && defaultAddress.length > 0 ? defaultAddress[0].id : JSON.parse(localStorage.getItem("selectedAddress")).id
  //   );
  // const initialDefaultAddressId = defaultAddress && defaultAddress.length > 0 ? defaultAddress[0].id : 0;
  // const selectedAddressFromLocalStorage = JSON.parse(localStorage.getItem("selectedAddress"));
  // const localStorageDefaultAddressId = selectedAddressFromLocalStorage ? selectedAddressFromLocalStorage.id : 0;

  // const [isDefaultAddress, setIsDefaultAddress] = useState(
  //   initialDefaultAddressId || localStorageDefaultAddressId
  // );

  const [addressD, setAddressD] = useState(Address);
  const navigate = useNavigate();
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [selectedAddress, setselectedAddress] = useState({});
  const [orderConvenienceFee, setOrderConvenienceFee] = useState(0);
  const { userData } = useSelector((store) => store.AuthReducer);
  const location = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const pageType = parseInt(params.get("pageType"));
  const [actionType, setActionType] = useState("");
  // console.log("address1", pageType);

  // useEffect(()=>{
  //     setOrderConvenienceFee(CheckoutAmount.convenience_fee);
  // },[CheckoutAmount]);

  if (pageType === 2 && location.state && location.state.addressId) {
    // const { addressId } = location.state;
    // alert(addressId);
  }

  // useEffect(() => {

  //     if (pageType === 2 && CheckoutCartList.length === 0) {
  //         navigate("/cart")

  //     }
  //     // eslint-disable-next-line
  //    }, [CheckoutCartList]);
  useEffect(() => {
    const handlePopState = () => {
      navigate("/cart");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    if (pageType === 2) {
      // alert(JSON.parse(localStorage.getItem("coupenName")));
      const couponNameFromLocalStorage = localStorage.getItem("coupenName");

      const selectedAddressFromLocalStorage =
        localStorage.getItem("selectedAddress");

      const params = {
        coupon_name:couponNameFromLocalStorage,
        user_id: userData.id,
        address_id: selectedAddressFromLocalStorage
          ? JSON.parse(selectedAddressFromLocalStorage)?.id || ""
          : "",
      };

      dispatch(getCheckoutData(params));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const params = {
      user_id: userData.id,
    };

    dispatch(getAddress(params));

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAddressD(Address);
  }, [Address]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAddressEdit = (item) => {
    onOpen();
    //alert(item.first_name)
    setActionType("edit");

    setselectedAddress(item);
  };

  const handleAddressDelete = (item) => {
    const shouldDelete = window.confirm(
      "Are you sure you want to delete this address?"
    );
    if (shouldDelete) {
      const params = { ...item, address_id: item.id };
      dispatch(deleteAddress(params));
    }
  };

  const handleAddModal = () => {
    onOpen();
    setActionType("add");
  };

  const handleAddAddress = (newAddress) => {
    setAddressD([...addressD, newAddress]);
    // alert(newAddress.first_name);

    //dispatch(addAddress(params));
  };
  const handleConvenienceFee = (fee) => {
    // alert(fee);
    setOrderConvenienceFee(fee);
  };

  const handleCheckboxChange = (id) => {
    setIsDefaultAddress(id);
  };
  const getTaxAmount=(tax,finalValue)=>{

    const params={
      tax:Math.floor(tax),finalValue:Math.floor(finalValue)
    }

    return {
      params
    }
  }
  function formatDate(date) {
    const options = { day: 'numeric', month: 'long' };
    return date.toLocaleDateString('en-US', options);
  }
  
  function SeventhDay() {
    const today = new Date();
    const seventhDay = new Date(today);
    seventhDay.setDate(today.getDate() + 7);
    return seventhDay;
  }

  return (
    <Box>
      {
        // eslint-disable-next-line
        pageType == "2" ? (
          <>
            <OtherNavbar page="address" pageType={pageType} />
          </>
        ) : (
          <Navbar2 />
        )
      }
      <Box mx="auto" maxW={"960px"}>
        {/* <HStack
                    // px={"200px"}
                    divider={<StackDivider color={"#ededef"} />}
                    alignItems="flex-start"
                    // backgroundColor={'red'}
                    justifyContent={'center'}
                > */}
        <Grid
          templateColumns={{
            base: "1",
            sm: "50% 50%",
            md: "65% 35%",
            lg: "65% 35%",
          }}
          gap={5}
        >
          <Box>
            {/* <VStack w="full" align={"flex-start"} spacing="10px"> */}
            <Box px={5}>
              <HStack justify={"space-between"}>
                <Text color={"#282c3f"} fontWeight={"bold"}>
                  Select Delivery Address
                </Text>
                <Button
                  variant={"outline"}
                  fontSize={"12px"}
                  size="sm"
                  colorScheme="red"
                  color="#424553"
                  onClick={() => handleAddModal()}
                >
                  ADD NEW ADDRESS
                </Button>
              </HStack>
            </Box>
            {isLoading ? (
              <>
                <Box
                  // position="absolute"
                  top="0"
                  left="0"
                  w="full"
                  h="full"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bg="rgba(255, 255, 255, 0.8)"
                  zIndex="9999"
                >
                  <Spinner size="lg" color="gray.500" />
                </Box>
              </>
            ) : Address.length === 0 ? (
              <>
                <SimpleGrid
                  columns={1}
                  spacingY="60px"
                  spacingX="30px"
                  marginTop={"30px"}
                  w="100%"
                >
                  <Center>
                    <VStack>
                      <Image
                        w="100px"
                        src="https://constant.myntassets.com/checkout/assets/img/empty-bag.webp"
                      />
                      <Text fontWeight={700} fontSize="20px" color={"424553"}>
                        Ohh!
                      </Text>
                      <Text
                        fontSize={"14px"}
                        pb="20px"
                        fontWeight={400}
                        color="#7e818c"
                      >
                        No address found.Please add new address !!
                      </Text>
                    </VStack>
                  </Center>
                </SimpleGrid>
              </>
            ) : (
              <>
                <Text
                  color="#535766"
                  fontSize={"12px"}
                  px={5}
                  fontWeight="bold"
                >
                  DEFAULT ADDRESS
                </Text>
                {true
                  ? Address.map((item, i) => (
                      <>
                        <AddressCard
                          item={item}
                          handleAddressEdit={handleAddressEdit}
                          handleAddressDelete={handleAddressDelete}
                          setActiveCardIndex={setActiveCardIndex}
                          activeCardIndex={activeCardIndex}
                          pageType={pageType}
                          selectedCartAddress={selectedCartAddress}
                          handleCheckboxChange={handleCheckboxChange}
                          isDefaultAddress={isDefaultAddress}
                        />
                      </>
                    ))
                  : null}
              </>
            )}
            <Modal isOpen={isOpen} onClose={onClose} colorScheme="pink">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader
                  fontSize={"14px"}
                  color={"#535766"}
                  fontWeight={"bold"}
                >
                  ADD NEW ADDRESS
                </ModalHeader>
                <ModalCloseButton color={"#535766"} fontWeight={"bold"} />
                <AddressModal
                  onClose={onClose}
                  type={actionType}
                  addressPage={"address"}
                  selectedAddress={selectedAddress}
                  setAddress={handleAddAddress}
                />
              </ModalContent>
            </Modal>
            {/* </VStack> */}
          </Box>

          {/* ................................... */}
          {
            // eslint-disable-next-line
            pageType == "2" && Object.keys(CheckoutAmount).length > 0 ? (
              <Box px={4}>
                <VStack
                  // w={"full"}
                  divider={<StackDivider borderColor="gray.200" />}
                  // spacing="30px"
                >
                  <HStack alignItems="flex-start">
                    <Icon as={MdLocalShipping} boxSize={10} color="gray.500" />
                    <HStack>
                      <Text>Estimated delivery by</Text>
                      <Text fontWeight={"bold"}>{formatDate(SeventhDay())}</Text>
                    </HStack>
                  </HStack>

                  <PaymentDetains1
                    convenienceFee={orderConvenienceFee}
                    totalMRP={CheckoutAmount.subtotal}
                    totalMRPDiscount={
                      CheckoutAmount.mrp_discount
                    }
                    coupenDiscountAmount={CheckoutAmount.discount_amount}
                    pageType={pageType}
                    tax={getTaxAmount(CheckoutAmount.tax,CheckoutAmount.grand_total)}
                  />

                  <PaymentDetains2
                    handleConvenienceFee={handleConvenienceFee}
                    fee={CheckoutAmount.convenience_fee}
                    convenienceFee={orderConvenienceFee}
                    totalAmount={CheckoutAmount.grand_total}
                    totalMRPDiscount={
                      CheckoutAmount.grand_total - CheckoutAmount.subtotal
                    }
                    amountWithTax={getTaxAmount(CheckoutAmount.tax,CheckoutAmount.grand_total)}
                    defaultAddress={defaultAddress}
                    redirect={"/payment"}
                    pageType={pageType}
                  />

                  {/* <PaymentMethod/> */}
                </VStack>
              </Box>
            ) : null
          }
        </Grid>

        {/* </HStack> */}
      </Box>
      {/* <OtherFooter /> */}
    </Box>
  );
};

export default Address;
