import { Box, Image, SimpleGrid, Text, Skeleton } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const Category = ({
  endpoint,
  params,
  category = [],
  spacingX = 0,
  spacingY = 0,
  heading,
  column,
  isLoading,
}) => {
  const navigate = useNavigate();

  return (
    <Box textAlign="left">
      {isLoading ? (
        <Skeleton height="50px" width="100%" mb="4" />
      ) : (
        <Image src={heading} alt="Heading" />
      )}

      <Box>
        <SimpleGrid
          p={1}
          backgroundColor="#dddddd"
          columns={{ base: 1, sm: 2, md: 4, lg: 4, xl: 8 }}
          spacingX={spacingX}
          spacingY={spacingY}
          minChildWidth="150px"
        >
          {/* Skeleton loading for categories */}
          {isLoading ? (
            Array.from({ length: 8 }).map((_, i) => (
              <Box key={i} p={0} height="25vh">
                <Skeleton height="100%" />
              </Box>
            ))
          ) : (
            category.map((item, i) => (
              <Box
                key={i}
                position="relative"
                borderRadius="50%"
                p={0}
                boxShadow="lg"
                border="1px solid #5ed6a4"
                height="25vh"
                overflow="hidden"
                cursor="pointer"
                onClick={() =>
                  navigate(`/store?type=${item.cat_id}&category=${item.id}`)
                }
              >
                <Box
                  position="relative"
                  height="100%"
                  transition="transform 0.3s"
                  _hover={{ transform: "scale(1.3)" }}
                >
                  <Image src={item.image} objectFit="cover" height="100%" />
                </Box>

                {/* Category name */}
                <Box
                  textAlign="center"
                  mt={2}
                  mb={2}
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  backgroundColor="rgba(255, 255, 255, 0.8)"
                  padding="5px"
                >
                  <Text fontWeight="400" color="#53575f" fontSize="12px">
                    {item.subcategory_name}
                  </Text>
                </Box>
              </Box>
            ))
          )}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Category;
