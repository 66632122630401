import { Box, SimpleGrid, Image, Skeleton, SkeletonText } from "@chakra-ui/react";
import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeSingleProduct from "./HomeSingleProduct";

const ProductsGroup = ({
  products = [],
  spacingX = 0,
  spacingY = 0,
  heading,
  column,
  isLoading
}) => {
  return (
    <Box textAlign="left" width="100%">
      <style>{`
        @keyframes bgTransition {
          0% { background-color: #ffb3b3; }
          25% { background-color: #ff8080; }
          50% { background-color: #ff4d4d; }
          75% { background-color: #ff1a1a; }
          100% { background-color: #ffb3b3; }
        }
      `}</style>
      <Image src={heading} mt={"8"} mb={"8"} display="flex" w={'full'}></Image>

      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 5, xl: 5 }} spacing={spacingY}>
        {isLoading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <Box key={index} padding={spacingX} style={{ boxSizing: "content-box" }}>
              <Skeleton height="300px" mb={4} />
              <SkeletonText mt="4" noOfLines={3} spacing="4" />
            </Box>
          ))
        ) : (
          products?.slice(0, 5).map((el, i) => (
            <Box key={el.id} padding={spacingX} style={{ boxSizing: "content-box" }}>
              <HomeSingleProduct
                {...el}
                onAddToCart={() => {
                  // Handle add to cart functionality
                }}
              />
            </Box>
          ))
        )}
      </SimpleGrid>
    </Box>
  );
};

export default ProductsGroup;
