import { Divider, ModalBody, Radio, ModalFooter, FormControl, Text, VStack, HStack, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, } from '@chakra-ui/react'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddressModal from './AddressModal'
import { selectAndSaveAddress } from '../Redux/CheckoutReducer/Action'


const SelectAddressModal = ({ onSelectAddressClose, setAddress ,addressPage=""}) => {
  const [userAddress, setUserAddress] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { Address, selectedAddress,defaultAddress } = useSelector((store) => store.CheckoutReducer);
  useEffect(() => {
    setUserAddress(Address);
  }, [Address]);

  useEffect(() => {
    const selectedAddress = JSON.parse(localStorage.getItem("selectedAddress"));
   // const id = selectedAddress ? selectedAddress.id : null;
     const id = selectedAddress ? selectedAddress.id : (defaultAddress.length > 0 ? defaultAddress[0].id:null);
    setUserAddress(
      Address.map((address) =>
        address.id === id ? { ...address, selected: true } : { ...address, selected: false }
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Address]);
  const handleAddressRadio = (id, item) => {
    const updatedAddresses = userAddress.map((address) => {
      // console.log("----------------------------------->>>",Address[0].id)
      return address.id === id ? { ...address, selected: true } : { ...address, selected: false };
    });
    setUserAddress(updatedAddresses);
    dispatch(selectAndSaveAddress(item));
    onSelectAddressClose();
  };
  return (
    <FormControl isRequired={true}>
      <ModalBody>
        <Divider marginBottom={2} />
        {userAddress.map((item) => (
          <HStack key={item} justifyContent={'space-between'}   >
            <HStack alignItems={'flex-start'} spacing={2} >
              <VStack marginTop={2}>
                <Radio value={item.id} onChange={() => handleAddressRadio(item.id, item)} isChecked={item.selected} />
              </VStack>
              <VStack align="start" spacing={0}  >
                <Text style={{ fontWeight: 700, fontSize: '14px' }}>{item.first_name}</Text>
                <Text> {item.street_address}{' near, '}{item.landmark}{item.city}{item.pincode}{','}{item.state}</Text>
              </VStack>
            </HStack>

            <VStack justifyContent={'flex-end'} alignItems={'flex-end'}>
              <Button variant={"outline"} borderRadius={5} size={"xs"} colorScheme={"pink"} >{item.type}</Button>
            </VStack>
          </HStack>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button colorScheme={"pink"} variant='outline' w="full" onClick={onOpen}>Add new  Address</Button>
      </ModalFooter>
      <Modal isOpen={isOpen} onClose={() => { onClose(); onSelectAddressClose(); }} colorScheme="pink">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"14px"} color={"#535766"} fontWeight={"bold"}>CHANGE DELIVERY ADDRESS</ModalHeader>
          <ModalCloseButton color={"#535766"} fontWeight={"bold"} />
          <AddressModal onClose={() => { onClose(); onSelectAddressClose(); }}
          type={'add'}
          addressPage={addressPage}
          setAddress={setAddress}
          />
        </ModalContent>
      </Modal>
    </FormControl>
  )
}

export default SelectAddressModal;