export const GET_LOGIN_REQUEST = "GET_LOGIN_REQUEST";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAILURE = "GET_LOGIN_FAILURE";
export const GET_LOGOUT_SUCCESS="GET_LOGOUT_SUCCESS"


//register

export const GET_REGISTER_REQUEST = "GET_REGISTER_REQUEST";
export const GET_REGISTER_SUCCESS = "GET_REGISTER_SUCCESS";
export const GET_REGISTER_FAILURE = "GET_REGISTER_FAILURE";

export const RESET_REGISTER_PHASE_STATUS = "RESET_REGISTER_PHASE_STATUS";

export const GET_OTP_REQUEST = "GET_OTP_REQUEST";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_SUCCESS_MOBILE = "GET_OTP_SUCCESS_MOBILE";
export const GET_OTP_FAILURE = "GET_OTP_FAILURE";



