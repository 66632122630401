

import React from 'react';
// import Navbar from '../Components/Navbar';
import Profile from '../Components/Profile';
import Navbar2 from '../Components/Navbar2';

const Profiles = () => {
//   const [wishlist, setWishlist] = useState([]);
//   const [isLoading, setIsLoading] = useState(true); // Add isLoading state
//   const [isSpinner, setIsSpinner] = useState(false);


  return (
    <>
      <Navbar2 />

      <Profile/>

      {/* <Footer /> */}
    </>
  );
};

export default Profiles;
