import {
  Box,
  SimpleGrid,
  Center,
  Image,
  VStack,
  Text,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { getProducts, clearProductsstate } from "../Redux/AppReducer/Action";
import SingleProductCom from "./SingleProductCom";
import shoppingBag from "../Assets/shopping-bag.png";
import { Waypoint } from "react-waypoint";

const Products = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const initQuery = searchParams.get("q");
  const { userData } = useSelector((store) => store.AuthReducer);
  const { Products, isProductsEmpty, isLoading, Total } = useSelector(
    (store) => store.AppReducer
  );

  console.log("Products", Products, "Total", Total);

  const [page, setPage] = useState(1);
  const [maxpage, setmaxPage] = useState(1);
  const [lastapipage, setlastapipage] = useState(1);
  const lastProductRef = useRef();

  useEffect(() => {
    if ((page === 1 && Products.length === 0) || location || initQuery) {
      const type = searchParams.getAll("type");
      const category = searchParams.getAll("category");
      const innerSubCategory = searchParams.getAll("innerSubCategory");
      const brand = searchParams.getAll("brand");
      const price = searchParams.getAll("price");
      const color = searchParams.getAll("color");
      const discount = searchParams.get("discount");
      const size = searchParams.getAll("size");
      const sortBy = searchParams.get("sortBy");
      const q = searchParams.get("q");
      const bestSeller = searchParams.get("bestSeller");
      console.log("is it 1st page:");
      const getProductParams = {
        params: {
          user_id: userData.id,
          type,
          subcat_id: category, //ist sub category not category
          innersubcategory_id: innerSubCategory,
          brand_id: brand,
          price: price,
          discount: discount,
          size: size,
          sort_by: sortBy,
          q,
          best_seller: bestSeller,
          color: color,
          page: 1,
        },
      };
      setPage(1);
      setmaxPage(1);
      setlastapipage(1);
      dispatch(clearProductsstate());
      dispatch(getProducts(getProductParams));
    }
    //eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    if (page !== lastapipage && page > maxpage && Products.length < Total) {
      const type = searchParams.getAll("type");
      const category = searchParams.getAll("category");
      const innerSubCategory = searchParams.getAll("innerSubCategory");
      const brand = searchParams.getAll("brand");
      const price = searchParams.getAll("price");
      const color = searchParams.getAll("color");
      const discount = searchParams.get("discount");
      const size = searchParams.getAll("size");
      const sortBy = searchParams.get("sortBy");
      const q = searchParams.get("q");
      const bestSeller = searchParams.get("bestSeller");
      const getProductParams = {
        params: {
          user_id: userData.id,
          type,
          subcat_id: category,
          innersubcategory_id: innerSubCategory,
          brand_id: brand,
          price: price,
          discount: discount,
          size: size,
          sort_by: sortBy,
          q,
          best_seller: bestSeller,
          color: color,
          page: page,
        },
      };

      console.log("getting next page", page, maxpage, "Total", Total);
      setmaxPage(Math.max(page, maxpage));
      setlastapipage(page);
      dispatch(getProducts(getProductParams));
    }
  }, [
    page,
    Total,
    searchParams,
    userData,
    Products.length,
    maxpage,
    dispatch,
    lastapipage,
  ]);

  const handleWaypointEnter = () => {
    console.log("way point");
    if (!isLoading && Products.length < Total && page <= maxpage) {
      console.log("way point if");
      setPage((prevPage) => prevPage + 1);
    }
  };

  if (isLoading && page === 1) {
    return (
      <Box height={"100%"}>
        <Center w="100%">
          <Spinner size="xl" color="red.500" />
        </Center>
      </Box>
    );
  }

  return (
    <Box height={"full"}>
      {!isProductsEmpty && (
        <SimpleGrid
          columns={{ base: 2, md: 4, lg: 4, sm: 2 }}
          spacingX="4px"
          spacingY="30px"
          w="100%"
        >
          {Products.map((el, index) => (
            <div
              key={el.id}
              ref={index === Products.length - 1 ? lastProductRef : null}
            >
              <SingleProductCom {...el} />
            </div>
          ))}
          <Waypoint onEnter={handleWaypointEnter} topOffset="80%" />
        </SimpleGrid>
      )}
      {isProductsEmpty && !isLoading && (
        <SimpleGrid
          columns={1}
          spacingY="60px"
          spacingX="30px"
          marginTop={"30px"}
          w="100%"
        >
          <Center>
            <VStack>
              <Image w="100px" src={shoppingBag} />
              <Text fontWeight={700} fontSize="20px" color={"424553"}>
                Ohh!
              </Text>
              <Text
                fontSize={"14px"}
                pb="20px"
                fontWeight={400}
                color="#7e818c"
              >
                No products found matching your filter criteria.
              </Text>
            </VStack>
          </Center>
        </SimpleGrid>
      )}

      {isLoading && page > 1 && (
        <SimpleGrid
          columns={1}
          spacingY="60px"
          spacingX="30px"
          marginTop={"30px"}
          w="100%"
        >
          <Center>
            <Spinner size="xl" color="red.500" />
          </Center>
        </SimpleGrid>
      )}
    </Box>
  );
};

export default Products;
