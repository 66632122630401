import {
  Box,
  HStack,
  StackDivider,
  VStack,
  Text,
  Button,
  Image,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  Input,
  Spinner,
  Grid,
  Flex,
  Spacer,
  Show,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import OtherNavbar from "../Components/OtherNavbar";
import { MdOutlineLocalOffer } from "react-icons/md";
import axios from "axios";
import SingleCartProduct from "../Components/SingleCartProduct";
import OtherFooter from "../Components/OtherFooter";
import SelectAddressModal from "../Components/SelectAddressModal";
import { PaymentDetains1, PaymentDetains2 } from "../Components/PaymentDetains";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCart } from "../Redux/CartReducer/Action";
import { getAddress } from "../Redux/CheckoutReducer/Action";
import CouponCard from "../Components/CoupanCard";
import showCustomToast from "../Components/showCustomToast";
import shoppingbag from "../Assets/shopping-bag.png";

const Cart = () => {
  const [cartProducts, setCartProducts] = useState([]);
  const [coupenList, setCoupenList] = useState([]);
  const [filteredCoupenList, setFilteredCoupenList] = useState([]);
  const [coupenName, setCoupenName] = useState("");
  const [coupenType, setCoupenType] = useState({
    type: "",
    amount: 0,
    percentage: 0,
    totalAmount: 0,
    min_amount: 0,
  });

  const [coupenDiscountAmount, setCoupenDiscountAmount] = useState(0);
  const [coupenApplied, setCoupenApplied] = useState(false);
  const [qtyChange, setQtyChange] = useState(false);
  const [productRemoved, setProductRemoved] = useState(false);
  const [inputCoupon, setInputCoupon] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userData } = useSelector((store) => store.AuthReducer);
  const { Cart, isLoading } = useSelector((store) => store.CartReducer);
  const { selectedAddress, defaultAddress } = useSelector(
    (store) => store.CheckoutReducer
  );
  const [addressD, setAddress] = useState({});
  const {
    first_name = "",
    pincode = "",
    street_address = "",
    landmark = "",
    city = "",
    state = "",
  } = addressD;
  const [totalMRP, setTotalMRP] = useState(0);
  const [totalMRPDiscount, setTotalMRPDiscount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!coupenApplied) {
      localStorage.setItem("coupenName", "");
    }
  }, [coupenApplied]);

  useEffect(() => {
    setCartProducts(Cart);
  }, [Cart]);

  useEffect(() => {
    const params = {
      user_id: userData.id,
    };
    dispatch(getCart(params));
    // eslint-disable-next-line
  }, []);

  const handleCartProducts = () => {
    axios({
      method: "get",
      url: process.env.REACT_APP_MYNTRA_API + "/cart",
    }).then(({ data }) => setCartProducts(data));
  };

  useEffect(() => {
    axios({
      url: process.env.REACT_APP_API + "/coupons",
    }).then((res) => {
      console.log("coupen", res.data.data.data);
      //eslint-disable-next-line
      const filteredCoupenList = res.data.data.data.filter(item => item.details_page_visible == 1);
      setCoupenList(res.data.data.data);
      setFilteredCoupenList(filteredCoupenList)
    });
  }, []);

  useEffect(() => {
    if (coupenApplied) {
      if (!checkMinAmount(totalMRP - totalMRPDiscount, coupenType.min_amount)) {
        setCoupenApplied(false);
        setInputCoupon("");
        setCoupenDiscountAmount(0);
        return;
      }

      //eslint-disable-next-line
      if (coupenType.type == 1) {
        var amt = coupenType.amount;
        var calc = totalMRP - totalMRPDiscount - amt;
        //eslint-disable-next-line
      } else if (coupenType.type == 0) {
        var percentage = coupenType.percentage;
        //eslint-disable-next-line
        var amt = Math.round(
          (percentage / 100) * (totalMRP - totalMRPDiscount)
        );
        calc = totalMRP - totalMRPDiscount - amt;
      }
      setTotalAmount(calc);
      setCoupenDiscountAmount(parseInt(amt));
    }
    //eslint-disable-next-line
  }, [qtyChange]);

  const checkMinAmount = (amount, minamount) => {
    if (amount < minamount) {
      showCustomToast("Amount is not enough to apply coupon", "error");
      return false;
    }
    return true;
  };

  const handleCoupenClick = (inputCoupon, type) => {
    console.log("coupon check", inputCoupon, ",", coupenName.toLowerCase());
    setInputCoupon(inputCoupon);

    if (coupenList.length === 0) {
      showCustomToast("No Coupon Avaiable!!", "error");
      return;
    }
    // eslint-disable-next-line
    if (inputCoupon == "") {
      showCustomToast("Enter Coupon!!", "error");
      return;
    }
    if (coupenApplied && type !== "list") {
      setCoupenApplied(false);
      setInputCoupon("");
      setCoupenDiscountAmount(0);
      localStorage.setItem("coupenName", "");
      setTotalAmount(totalAmount + coupenDiscountAmount);
      return;
    } else {
      if (coupenList.length > 0) {
        const arr = coupenList.filter((item) => {
          //eslint-disable-next-line
          return inputCoupon.toLowerCase() == item.coupon_name.toLowerCase();
        });

        if (arr.length > 0) {
          setCoupenName(arr[0].coupon_name);
          localStorage.setItem("coupenName", arr[0].coupon_name);
          //eslint-disable-next-line
          if (arr[0].type == 1) {
            if (!checkMinAmount(totalAmount, arr[0].min_amount)) {
              return;
            }
            var amt = arr[0].amount;
            var calc = totalAmount - amt;
            setCoupenType({
              ...coupenType,
              amount: amt,
              totalAmount: calc,
              type: arr[0].type,
              min_amount: arr[0].min_amount,
            });
          }
          //eslint-disable-next-line
          else if (arr[0].type == 0) {
            // alert(totalAmount)
            if (
              !checkMinAmount(totalMRP - totalMRPDiscount, arr[0].min_amount)
            ) {
              return;
            }
            var percentage = arr[0].percentage;
            //eslint-disable-next-line
            var amt = Math.round(
              (percentage / 100) * (totalMRP - totalMRPDiscount)
            );
            calc = totalMRP - totalMRPDiscount - amt;
            setCoupenType({
              ...coupenType,
              type: arr[0].type,
              percentage: percentage,
              totalAmount: calc,
              min_amount: arr[0].min_amount,
            });
          }
          setTotalAmount(calc);
          setCoupenApplied(true);
          setCoupenDiscountAmount(parseInt(amt));
        } else {
          showCustomToast("No Coupon Exists!!", "error");
        }
      }
    }
  };
  const handleDeleteCouponDiscount = (amount) => {
    setProductRemoved(true);
    //eslint-disable-next-line
    if (coupenType.type == 0) {
      var amountToBeMinus = (coupenType.percentage / 100) * amount;
      setCoupenDiscountAmount(coupenDiscountAmount - amountToBeMinus);
    }
    //eslint-disable-next-line
    else if (coupenType.type == 1) {
      // var  amountToBeMinus=((coupenType.percentage)/100) * amount;
      // setCoupenDiscountAmount(coupenDiscountAmount-amountToBeMinus);
    }
  };
  useEffect(() => {
    if (coupenApplied) {
      if (!checkMinAmount(totalMRP - totalMRPDiscount, coupenType.min_amount)) {
        setCoupenApplied(false);
        setInputCoupon("");
        setCoupenDiscountAmount(0);
        return;
      }
    }
    //eslint-disable-next-line
  }, [productRemoved]);

  useEffect(() => {
    const params = {
      user_id: userData.id,
    };
    dispatch(getAddress(params));
    // eslint-disable-next-line
  }, [selectedAddress]);

  useEffect(() => {
    const selectedAddress = localStorage.getItem("selectedAddress")
      ? JSON.parse(localStorage.getItem("selectedAddress"))
      : "";
    console.log("products detail com", selectedAddress);
    if (selectedAddress) {
      setAddress(selectedAddress);
    } else if (defaultAddress.length > 0) {
      setAddress(defaultAddress[0]);
    } else {
      setAddress([]);
    }
  }, [selectedAddress, defaultAddress]);

  const getTaxAmount = () => {
    const value = totalMRP - totalMRPDiscount - coupenDiscountAmount;

    if (value <= 1049) {
      var finalValue = (5 * value) / 100 + value;
      var tax = (5 * value) / 100;
    } else if (value > 1049) {
      finalValue = (12 * value) / 100 + value;
      tax = (12 * value) / 100;
    }

    const params = {
      tax: Math.floor(tax),
      finalValue: Math.floor(finalValue),
    };

    return {
      params,
    };
  };
  return (
    <Box>
      <OtherNavbar page="cart" />

      {isLoading ? (
        <>
          <Box
            // position="absolute"
            top="0"
            left="0"
            w="full"
            h="full"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bg="rgba(255, 255, 255, 0.8)"
            zIndex="9999"
          >
            <Spinner size="lg" color="gray.500" />
          </Box>
        </>
      ) : cartProducts.length === 0 ? (
        <>
          <Box>
            <Center>
              <VStack>
                <Image w="100px" src={shoppingbag} />
                <Text fontWeight={700} fontSize="20px" color={"424553"}>
                  Hey, it feels so light!
                </Text>
                <Text
                  fontSize={"14px"}
                  pb="20px"
                  fontWeight={400}
                  color="#7e818c"
                >
                  There is nothing in your bag. Let's add some items.
                </Text>
                <Show breakpoint="(min-width: 500px)">
                  <Button
                    onClick={() => navigate("/wishlist")}
                    colorScheme={"red"}
                    color="#ff3f6c"
                    variant="outline"
                    borderRadius={"2px"}
                  >
                    ADD ITEM FROM WISHLIST
                  </Button>
                </Show>
                <Show breakpoint="(max-width: 500px)">
                  <Button
                    onClick={() => navigate("/wishlist")}
                    bg={"black"}
                    color="white"
                    variant="solid"
                    borderRadius={"2px"}
                  >
                    ADD ITEM FROM WISHLIST
                  </Button>
                </Show>
              </VStack>
            </Center>
          </Box>
        </>
      ) : (
        <>
          {/* My code */}
          <Box maxW="960px" mx="auto">
            <Grid
              templateColumns={{
                base: "1",
                sm: "50% 50%",
                md: "65% 35%",
                lg: "65% 35%",
              }}
              gap={5}
            >
              <Box>
                <Box border={"1px solid #ebebed"} p={4} borderRadius={"7px"}>
                  <HStack justify={"space-between"}>
                    {first_name ? (
                      <VStack spacing={0} align={"flex-start"}>
                        <HStack>
                          <Text fontSize={"14px"}>Deliver to:</Text>
                          <Text fontSize={"14px"} fontWeight={"bold"}>
                            {first_name}, {pincode}
                          </Text>
                        </HStack>

                        <Text fontSize={"12px"}>
                          {landmark}, {street_address},{city},{state}
                        </Text>
                      </VStack>
                    ) : (
                      <Text
                        fontWeight={"bold"}
                        fontSize={"14px"}
                        color="#535766"
                      >
                        Where we need to Deliver !
                      </Text>
                    )}

                    <Button
                      minW={90}
                      variant={"outline"}
                      colorScheme="red"
                      borderRadius={"2px"}
                      size={"sm"}
                      fontSize="13px"
                      onClick={onOpen}
                    >
                      SET ADDRESS
                    </Button>
                    <Modal isOpen={isOpen} onClose={onClose} colorScheme="red">
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader
                          fontSize={"14px"}
                          color={"#535766"}
                          fontWeight={"bold"}
                        >
                          CHANGE DELIVERY ADDRESS
                        </ModalHeader>
                        <ModalCloseButton
                          color={"#535766"}
                          fontWeight={"bold"}
                        />
                        <SelectAddressModal
                          addressPage={"cart"}
                          onSelectAddressClose={onClose}
                        />
                      </ModalContent>
                    </Modal>
                  </HStack>
                </Box>

                <HStack
                  justify={"left"}
                  spacing={1}
                  border={"1px solid #ebebed"}
                  p={"5px 5px"}
                  borderRadius={"3px"}
                >
                  <Image
                    w="32px"
                    src="https://constant.myntassets.com/checkout/assets/img/ship-free.webp"
                  ></Image>
                  <Text fontSize={"14px"} pt="7px" color="#626472">
                    Yay!
                  </Text>
                  <Text
                    fontSize={"14px"}
                    pt="7px"
                    fontWeight={"bold"}
                    color="#535766"
                  >
                    No convenience fee
                  </Text>
                  <Text fontSize={"14px"} pt="7px" color="#626472">
                    on prepaid order.
                  </Text>
                </HStack>
                {Array.isArray(cartProducts) &&
                  cartProducts.map((item, i) => (
                    <SingleCartProduct
                      key={item.id}
                      {...item}
                      setTotalMRP={setTotalMRP}
                      setTotalAmount={setTotalAmount}
                      totalAmount={totalAmount}
                      totalMRP={totalMRP}
                      setTotalMRPDiscount={setTotalMRPDiscount}
                      handleCartProducts={handleCartProducts}
                      handleQtyChange={() => setQtyChange(!qtyChange)}
                      handleCouponDiscount={(amount) =>
                        handleDeleteCouponDiscount(amount)
                      }
                    />
                  ))}
              </Box>

              <Box px={5}>
                <VStack
                  pt={"15px"}
                  divider={<StackDivider borderColor="gray.200" />}
                >
                  <Box w={"full"}>
                    <Text fontSize={"12px"} color={"#535766"} fontWeight={700}>
                      COUPONS
                    </Text>
                    <Flex py={"10px"}>
                      <Box>
                        <HStack>
                          <Icon
                            fontSize={"20px"}
                            as={MdOutlineLocalOffer}
                          ></Icon>
                          <Text
                            fontSize={"14px"}
                            color="535766"
                            fontWeight={"700"}
                          >
                            {coupenApplied ? "Applied" : "Apply Coupons"}
                          </Text>
                        </HStack>
                      </Box>
                      <Spacer />
                      <Box>
                        <Button
                          borderRadius={2}
                          px={4}
                          py={3}
                          variant={"outline"}
                          size={"xs"}
                          colorScheme={"red"}
                          onClick={() =>
                            handleCoupenClick(inputCoupon, "input")
                          }
                        >
                          {coupenApplied ? "Remove" : "Apply"}
                        </Button>
                      </Box>
                    </Flex>
                    {coupenApplied ? (
                      ""
                    ) : (
                      <Input
                        value={inputCoupon}
                        placeholder="Enter Coupon Code"
                        onChange={(e) => setInputCoupon(e.target.value)}
                      />
                    )}
                    <Menu>
                      <MenuButton
                        as={Button}
                        borderRadius={2}
                        px={4}
                        py={3}
                        variant="outline"
                        size="xs"
                        colorScheme="red"
                      >
                        View Coupons
                      </MenuButton>
                      <MenuList>
                        <MenuItem>
                          <VStack>
                            {filteredCoupenList.map((coupon) => (
                              <CouponCard
                                key={coupon.id}
                                couponData={coupon}
                                onApplyCoupon={(c) =>
                                  handleCoupenClick(c.coupon_name, "list")
                                }
                                // showApplyButton={true}
                                showApplyButton={
                                  coupon.min_amount <=
                                  totalMRP - totalMRPDiscount
                                }
                                discountedprice={totalMRP - totalMRPDiscount}
                              />
                            ))}
                          </VStack>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>

                  <PaymentDetains1
                    coupenDiscountAmount={Math.round(coupenDiscountAmount)}
                    totalMRP={Math.round(totalMRP)}
                    totalMRPDiscount={Math.round(totalMRPDiscount)}
                    tax={getTaxAmount()}
                  />
                  <PaymentDetains2
                    coupenName={coupenName}
                    totalAmount={Math.round(
                      totalMRP - totalMRPDiscount - coupenDiscountAmount
                    )}
                    amountWithTax={getTaxAmount()}
                    totalMRP={Math.round(totalMRP)}
                    totalMRPDiscount={Math.round(totalMRPDiscount)}
                    redirect={pincode ? "/address" : undefined}
                  />
                </VStack>
              </Box>
            </Grid>
          </Box>
        </>
      )}

      <OtherFooter />
    </Box>
  );
};

export default Cart;
