import React, { useState } from "react";
import {
  Image,
  Text,
  Stack,
  Card,
  ModalFooter,
  CardBody,
  CardFooter,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Spinner,
  ModalBody,
  ModalCloseButton,
  HStack,
  Grid,
  GridItem,
  StackDivider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Textarea,
  Flex,
  VStack,
  Link,
  Show,
  Input,
} from "@chakra-ui/react";

import RatingStar from "../Components/RatingStar";
import Navbar2 from "../Components/Navbar2";

import { StarIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getOrderDetails,
  updateSelectedDetails,
} from "../Redux/OrderReducer/Action";
import axios from "axios";
import ConfirmationModal from "../Components/ConfirmationModal";
import showCustomToast from "../Components/showCustomToast";

const OrderDetails = () => {
  const { orderNumber, id } = useParams();
  const { userData } = useSelector((store) => store.AuthReducer);
  const [clickedOrder, setClickedOrder] = useState({});
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [confirmationModalText, setConfirmationModalText] = useState("");
  const [isRateModalOpen, setIsRateModalOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [returnReason, setReturnReason] = useState({
    reason: "",
    bankAccountNumber: "",
    ifscCode: "",
    accountHolderName: "",
  });
  const [orderInfoData, setOrderInfoData] = useState([]);

  const { isLoading, OrderInfo, OrderSelected, OrderList } = useSelector(
    (store) => store.OrderDetailReducer
  );
  const dispatch = useDispatch();
  console.log("orders list 2:", OrderList);
  useEffect(() => {
    setOrderInfoData(OrderInfo);
    //eslint-disable-next-line
  }, [OrderInfo, OrderSelected, OrderList]);

  useEffect(() => {
    const params = {
      order_number: orderNumber,
      id: id,
    };
    dispatch(getOrderDetails(params));
    //eslint-disable-next-line
  }, []);

  const handleUpdateSelectedOrder = (item) => {
    dispatch(updateSelectedDetails(item));
  };
  const isRatingGiven = (order) => {
    if (order.rattings == null) {
      return false;
    }
    return true;
  };

  const showRatingOption = (order) => {
    //eslint-disable-next-line
    if (order.status == 4 && !isRatingGiven(order)) {
      return true;
    }
    return false;
  };

  if (
    OrderInfo &&
    typeof OrderInfo === "object" &&
    "shipping_cost" in OrderInfo
  ) {
    var shipping_cost = Math.round(OrderInfo.shipping_cost);
    // Use shipping_cost here
  } else {
    shipping_cost = "";
    // Handle the case where OrderInfo is missing or doesn't have a shipping_cost property
  }
  const openRateModal = (order) => {
    setClickedOrder(order);
    setIsRateModalOpen(true);
  };
  const openReturnModal = (order) => {
    setClickedOrder(order);
    setIsReturnModalOpen(true);
  };

  const openConfirmationModal = (text, status, order) => {
    setConfirmationModalText(text);
    setClickedOrder(order);
    setIsConfirmationModalOpen(true);
  };
  const handleConfirmationDialogYes = (confirm, order) => {
    if (confirm) {
      cancelReturnOrderAPICall(order.id, 6); //6 canclled,
    }
  };
  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };
  const cancelReturnOrderAPICall = (orderId, status) => {
    axios({
      method: "post",
      url: process.env.REACT_APP_API + "/cancelorder",
      data: { user_id: userData.id, status: status, order_id: orderId },
      withCredentials: false,
    })
      .then((_) => {
        showCustomToast("Order Cancelled", "success");
        const params = {
          order_number: orderNumber,
          id: OrderSelected.id,
        };

        dispatch(getOrderDetails(params));
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const closeRateModal = () => {
    setIsRateModalOpen(false);
    setComment("");
    setRating(0);
  };
  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleReturnReasonChange = (event) => {
    const { name, value } = event.target;
    setReturnReason({
      ...returnReason,
      [name]: value,
    });
  };
  const closeReturnModal = () => {
    setIsReturnModalOpen(false);
    setReturnReason({
      reason: "",
      bankAccountNumber: "",
      ifscCode: "",
      accountHolderName: "",
    });
  };

  const submitRatingAPICall = () => {
    //eslint-disable-next-line
    if (comment != null && comment != "") {
      axios({
        method: "post",
        url: process.env.REACT_APP_API + "/addratting",
        data: {
          user_id: userData.id,
          product_id: clickedOrder.product_id,
          vendor_id: clickedOrder.vendor_id,
          ratting: rating,
          comment: comment,
        },
        withCredentials: false,
      })
        .then((_) => {
          closeRateModal();
          const params = {
            order_number: orderNumber,
            id: OrderSelected.id,
          };
          // showToast("Thanks for your feedback","","success");
          dispatch(getOrderDetails(params));
          showCustomToast("Thanks for your feedback", "success");
          // window.location.reload();
          //   onUpdate();
        })
        .catch((err) => {
          closeRateModal();
          // console.log(err);
        });
    } else {
      showCustomToast("Thanks for your feedback", "error");
    }
  };

  const submitReturnAPICall = () => {
    const { reason, bankAccountNumber, ifscCode, accountHolderName } =
      returnReason;

    if (
      reason === "" ||
      bankAccountNumber === "" ||
      ifscCode === "" ||
      accountHolderName === ""
    ) {
      showCustomToast("Please enter all the fields correctly", "error");
      return;
    }

    if (
      reason !== "" &&
      bankAccountNumber !== "" &&
      ifscCode !== "" &&
      accountHolderName !== ""
    ) {
      axios({
        method: "post",
        url: process.env.REACT_APP_API + "/returnrequest",
        data: {
          user_id: userData.id,
          order_id: clickedOrder.id,
          return_reason: reason,
          account_number: bankAccountNumber,
          ifsc_code: ifscCode,
          account_holder_name: accountHolderName,
        },
        withCredentials: false,
      })
        .then((response) => {
          closeReturnModal();
          showCustomToast("Your return request has been raised", "success");
          const params = {
            order_number: orderNumber,
            id: OrderSelected.id,
          };
          dispatch(getOrderDetails(params));
        })
        .catch((err) => {
          closeReturnModal();
        });
    } else {
      showCustomToast("Please enter all the fields correctly", "error");
    }
  };

  const getStatusColor = (status) => {
    //eslint-disable-next-line
    if (status == 1) {
      return "orange";
      //eslint-disable-next-line
    } else if (status == 2) {
      return "yellow";
      //eslint-disable-next-line
    } else if (status == 3) {
      return "yellow";
      //eslint-disable-next-line
    } else if (status == 4) {
      return "green";
      //eslint-disable-next-line
    } else if (status == 5) {
      return "red";
      //eslint-disable-next-line
    } else if (status == 6) {
      return "red";
      //eslint-disable-next-line
    } else if (status == 7) {
      return "brown";
    }
    //eslint-disable-next-line
    else if (status == 0) {
      return "brown";
    } else {
      return null;
    }
  };
  const getDeliveryStatus = (status) => {
    //  alert(status);
    //eslint-disable-next-line
    if (status == 1) {
      return "Order Placed";
      //eslint-disable-next-line
    } else if (status == 2) {
      return "Order Confirmed";
      //eslint-disable-next-line
    } else if (status == 3) {
      //eslint-disable-next-line
      return "Order Shipped";
      //eslint-disable-next-line
    } else if (status == 4) {
      //eslint-disable-next-line
      return "Order Delivered";
      //eslint-disable-next-line
    } else if (status == 5) {
      return "Cancelled By Vendor";
      //eslint-disable-next-line
    } else if (status == 6) {
      return "Cancelled";
      //eslint-disable-next-line
    } else if (status == 7) {
      return "Product Return Accepted";
    }
    //eslint-disable-next-line
    else if (status == 8) {
      return "Product Returned";
    }
    //eslint-disable-next-line
    else if (status == 0) {
      return "Return Requested";
    } else {
      return null;
    }
  };
  const boxStyle = {
    display:
      OrderSelected.return_status ||
      OrderSelected.cancel_status ||
      showRatingOption(OrderSelected)
        ? "block"
        : "none",
  };

  return (
    <>
      {/* large screens */}
      <Show breakpoint="(min-width: 500px)">
        <Box bg={"#b7b7b724"}>
          <Navbar2 />
          {isLoading ? (
            <>
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                w="full"
                h="full"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bg="rgba(255, 255, 255, 0.8)"
                zIndex="9999"
              >
                <Spinner size="lg" color="gray.500" />
              </Box>
            </>
          ) : (
            <>
              <Box
                px={5}
                mt={2}
                style={{
                  maxWidth: "800px",
                  margin: "0 auto",
                }}
              >
                <p
                  style={{
                    color: getStatusColor(OrderSelected.status),
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  {/* <span class="mr1">
            <FontAwesomeIcon icon={getStatusIcon(OrderSelected.status)} />
          </span> */}
                  {"  "}
                  {getDeliveryStatus(OrderSelected.status)}
                </p>

                <Card
                  maxW="100%"
                  mt={2}
                  style={{
                    margin: 0 + " auto",
                  }}
                >
                  <CardBody pb={0}>
                    <HStack spacing={2}>
                      <Box maxW={"80px"}>
                        <Image
                          src={OrderSelected.image_url}
                          alt="Green double couch with wooden legs"
                          borderRadius="lg"
                          objectFit={"cover"}
                        />
                      </Box>

                      <Box>
                        <Stack spacing="0">
                          <Text color={"black"}>
                            {" "}
                            {OrderSelected.product_name}
                          </Text>

                          <Text color={"black"} fontSize={"sm"}>
                            {" "}
                            Size {OrderSelected.variation}{" "}
                          </Text>
                        </Stack>
                      </Box>
                    </HStack>
                  </CardBody>

                  <CardFooter py={1}>
                    <Grid templateColumns="repeat(5, 1fr)" gap={2}>
                      <GridItem colSpan={1}>
                        {" "}
                        <Text fontSize={"sm"} color={"black"}>
                          {/* ₹{OrderSelected.total} */}
                        </Text>{" "}
                      </GridItem>
                    </Grid>
                  </CardFooter>
                </Card>

                {/* Cancel BUtton */}
                <Box
                  my={2}
                  boxShadow="md"
                  // style={boxStyle}
                  bg={"white"}
                  // width={"50%"}
                  style={{
                    margin: "10px  auto",
                    // maxWidth: "250px",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <Flex
                    flexDirection={"row"}
                    justifyContent={"space-around"}
                    alignItems={"center"}
                    height={"50px"}
                  >
                    {OrderSelected.cancel_status ? (
                      <VStack
                        onClick={() =>
                          openConfirmationModal(
                            "Are you sure you want to cancel the product ?",
                            6,
                            OrderSelected
                          )
                        }
                        justifyContent={"center"}
                        cursor={"pointer"}
                      >
                        <Text fontSize={"xs"}>Cancel Product</Text>
                      </VStack>
                    ) : null}
                    {OrderSelected.return_status ? (
                      <VStack
                        cursor={"pointer"}
                        onClick={() => openReturnModal(OrderSelected)}
                      >
                        <Text fontSize={"xs"}>Return Product</Text>
                        {/* <Icon as={RepeatIcon} pb={1} w={4} h={4} color={"yellow"} /> */}
                      </VStack>
                    ) : null}

                    {showRatingOption(OrderSelected) ? (
                      <VStack
                        cursor={"pointer"}
                        onClick={() => openRateModal(OrderSelected)}
                      >
                        <Text fontSize={"xs"}>Rate Product</Text>
                        {/* <Icon as={StarIcon} pb={1} w={4} h={4} color={"yellow.400"} /> */}
                      </VStack>
                    ) : null}
                  </Flex>
                </Box>

                {/* section 3 */}
                <Box pt={2}>
                  <Card>
                    <CardBody>
                      <Box>
                        <Text fontSize={"xs"} color={"gray.500"}>
                          {/* Return/Exchange window closed on Tuesday,29 aug 2023 */}
                          The return policy for the product is{" "}
                          {OrderSelected.return_days} days.
                        </Text>

                        {/* <Text fontSize={'xs'} as={'b'} color={'blue.500'}>Return policy</Text> */}
                      </Box>

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />

                      <Box>
                        <Accordion allowToggle>
                          <AccordionItem>
                            <AccordionButton pl={0}>
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                fontSize={"xs"}
                                fontWeight={600}
                              >
                                You Rated :{" "}
                                {OrderSelected.rattings && (
                                  <Icon
                                    as={StarIcon}
                                    pb={1}
                                    w={4}
                                    h={4}
                                    color={"yellow.400"}
                                  />
                                )}{" "}
                                {OrderSelected.rattings
                                  ? OrderSelected.rattings
                                  : "No rating"}
                              </Box>

                              <AccordionIcon />
                            </AccordionButton>

                            <AccordionPanel pb={4} pl={0}>
                              <Text fontSize={"xs"} color={"gray.500"}>
                                {" "}
                                {OrderSelected.comment
                                  ? OrderSelected.comment
                                  : "No Comment"}
                              </Text>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Box>

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />

                      <Box mb={3}>
                        <Text fontSize={"sm"} as={"b"}>
                          Other Items
                        </Text>

                        <HStack spacing={2} mt={3}>
                          <Grid
                            templateColumns={{
                              base: "repeat(3, 1fr)", // 1 column on small screens
                              md: "repeat(4, 1fr)", // 4 columns on medium and larger screens
                            }}
                            gap={2}
                          >
                            {OrderList.map((item, index) =>
                              item.id !== OrderSelected.id ? (
                                <Box
                                  key={item.id}
                                  height={100}
                                  width={100}
                                  onClick={() =>
                                    handleUpdateSelectedOrder(item)
                                  }
                                >
                                  <Image
                                    src={item.image_url}
                                    borderRadius="lg"
                                    height={"100%"}
                                    width={"100%"}
                                    objectFit={"contain"}
                                  />
                                </Box>
                              ) : null
                            )}
                          </Grid>
                        </HStack>
                      </Box>

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />

                      <Show breakpoint="(min-width: 500px )">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "50px",
                            boxShadow: "0px 0px 2px #ccc",
                            borderRadius: "10px",
                            marginBottom: "10px",
                            marginTop: "10px",
                            padding: "15px",
                          }}
                        >
                          <div>
                            <Box mb={3} display="flex" alignItems="center">
                              <Text fontSize="sm" as="b">
                                Payment Details
                              </Text>
                              <Box flex="1" textAlign="right">
                                <Link
                                  color="blue.500"
                                  fontSize={"12px"}
                                  href={
                                    process.env.REACT_APP_INVOICE_URL +
                                    "/" +
                                    orderInfoData.order_number
                                  }
                                  isExternal
                                >
                                  Download Invoice
                                </Link>
                              </Box>
                            </Box>

                            <Box>
                              <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                                <GridItem colSpan={4}>
                                  <Stack>
                                    <Text fontSize={"sm"} color={"gray.500"}>
                                      Total MRP
                                    </Text>

                                    <Text fontSize={"sm"} color={"gray.500"}>
                                      Discount on MRP
                                    </Text>

                                    <Text fontSize={"sm"} color={"gray.500"}>
                                      Coupan Discount
                                    </Text>
                                    <Text fontSize={"sm"} color={"gray.500"}>
                                      Convenience Fee
                                    </Text>
                                    <Text fontSize={"sm"} as={"b"}>
                                      Total Amount
                                    </Text>
                                  </Stack>
                                </GridItem>

                                <GridItem colSpan={1}>
                                  <Stack>
                                    <Text fontSize={"sm"} color={"gray.500"}>
                                      {orderInfoData.total_mrp}
                                    </Text>

                                    <Text fontSize={"sm"} color={"gray.500"}>
                                      {orderInfoData.mrp_discount}
                                    </Text>

                                    <Text fontSize={"sm"} color={"gray.500"}>
                                      {orderInfoData.coupon_discount}
                                    </Text>
                                    <Text fontSize={"sm"} color={"gray.500"}>
                                      {orderInfoData.shipping_cost}
                                    </Text>
                                    <Text fontSize={"sm"} as={"b"}>
                                      ₹ {orderInfoData.grand_total}
                                    </Text>
                                  </Stack>
                                </GridItem>
                              </Grid>
                            </Box>
                          </div>
                          <Box>
                            <Stack spacing={0} gap={1}>
                              <Text fontSize={"sm"} as="b" mb={3}>
                                Deliver Address
                              </Text>

                              <Text fontSize={"xs"} as="b">
                                {orderInfoData.full_name}
                              </Text>

                              <Text fontSize={"xs"} color={"gray.500"}>
                                {orderInfoData.street_address}
                                {orderInfoData.landmark}, {orderInfoData.city},{" "}
                                {orderInfoData.state} -{orderInfoData.pincode}
                              </Text>

                              <Text fontSize={"xs"} color={"gray.500"}>
                                Phone : {orderInfoData.mobile}
                              </Text>
                            </Stack>
                          </Box>
                        </div>
                      </Show>

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />

                      <Box>
                        <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                          <GridItem colSpan={3}>
                            <Text fontSize={"xs"} as={"b"} color={"gray.500"}>
                              Order id : {orderInfoData.order_number}
                            </Text>
                          </GridItem>

                          <GridItem colSpan={3}>
                            <Text fontSize={"xs"} as={"b"} color={"gray.500"}>
                              Placed: {orderInfoData.date}
                            </Text>
                          </GridItem>
                        </Grid>
                      </Box>

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />
                    </CardBody>
                  </Card>
                </Box>
              </Box>

              {/*  */}
              {/* overlays */}
              <Modal isOpen={isRateModalOpen} onClose={closeRateModal}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Rate Product</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Heading
                      fontWeight={300}
                      as={"h2"}
                      color="#535665"
                      fontSize="20px"
                      size="lg"
                      mb={5}
                    >
                      {clickedOrder.product_name}{" "}
                    </Heading>
                    <FormControl>
                      <FormLabel>Rating</FormLabel>
                      <RatingStar
                        initialRating={0}
                        maxRating={5}
                        onUpdate={(count) => handleRatingChange(count)}
                      ></RatingStar>
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Comments</FormLabel>
                      <Textarea
                        placeholder="Enter your comments"
                        onChange={handleCommentChange}
                        value={comment}
                      />
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={submitRatingAPICall}
                    >
                      Save
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <Modal isOpen={isReturnModalOpen} onClose={closeReturnModal}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Return Product</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Heading
                      fontWeight={300}
                      as={"h2"}
                      color="#535665"
                      fontSize="20px"
                      size="lg"
                      mb={5}
                    >
                      {clickedOrder.product_name}{" "}
                    </Heading>
                    <FormControl mt={4}>
                      <FormLabel>Return Reason</FormLabel>
                      <Textarea
                        placeholder="Enter your comments. Based on this comment the seller will act upon your return request"
                        name="reason"
                        onChange={handleReturnReasonChange}
                        value={returnReason.reason}
                      />
                    </FormControl>
                    <Heading
                      fontWeight={500}
                      as={"h4"}
                      fontSize="18px"
                      size="lg"
                      mb={2}
                      mt={6}
                    >
                      Amount will be refunded in this account
                    </Heading>
                    <FormControl mt={4}>
                      <FormLabel>Bank Account Holder Name</FormLabel>
                      <Input
                        placeholder="Enter the account holder name"
                        name="accountHolderName"
                        onChange={handleReturnReasonChange}
                        value={returnReason.accountHolderName}
                      />
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Bank Account Number</FormLabel>
                      <Input
                        placeholder="Enter your bank account number"
                        name="bankAccountNumber"
                        onChange={handleReturnReasonChange}
                        value={returnReason.bankAccountNumber}
                      />
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>IFSC Code</FormLabel>
                      <Input
                        placeholder="Enter your IFSC code"
                        name="ifscCode"
                        onChange={handleReturnReasonChange}
                        value={returnReason.ifscCode}
                      />
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={submitReturnAPICall}
                    >
                      Save
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <ConfirmationModal
                onConfirm={(confirm, order) =>
                  handleConfirmationDialogYes(confirm, order)
                }
                isOpen={isConfirmationModalOpen}
                onClose={closeConfirmationModal}
                text={confirmationModalText}
                product={clickedOrder}
              />
            </>
          )}
        </Box>
      </Show>

      {/* mobile */}
      <Show breakpoint="(max-width: 500px)">
        <Box bg={"#b7b7b724"}>
          <Navbar2 />
          {isLoading ? (
            <>
              <Box
                position="fixed"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                w="full"
                h="full"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bg="rgba(255, 255, 255, 0.8)"
                zIndex="9999"
              >
                <Spinner size="lg" color="gray.500" />
              </Box>
            </>
          ) : (
            <>
              <Box px={5} mt={2}>
                <p style={{ color: getStatusColor(OrderSelected.status) }}>
                  {/* <span class="mr1">
            <FontAwesomeIcon icon={getStatusIcon(OrderSelected.status)} />
          </span> */}
                  {"  "}
                  {getDeliveryStatus(OrderSelected.status)}
                </p>

                <Card maxW="100%" mt={2}>
                  <CardBody pb={0}>
                    <HStack spacing={2}>
                      <Box maxW={"80px"}>
                        <Image
                          src={OrderSelected.image_url}
                          alt="Green double couch with wooden legs"
                          borderRadius="lg"
                          objectFit={"cover"}
                        />
                      </Box>

                      <Box>
                        <Stack spacing="0">
                          <Text color={"black"}>
                            {" "}
                            {OrderSelected.product_name}
                          </Text>

                          <Text color={"black"} fontSize={"sm"}>
                            {" "}
                            Size {OrderSelected.variation}{" "}
                          </Text>
                        </Stack>
                      </Box>
                    </HStack>
                  </CardBody>

                  <CardFooter py={1}>
                    <Grid templateColumns="repeat(5, 1fr)" gap={2}>
                      <GridItem colSpan={1}>
                        {" "}
                        <Text fontSize={"sm"} color={"black"}>
                          {/* ₹{OrderSelected.total} */}
                        </Text>{" "}
                      </GridItem>
                    </Grid>
                  </CardFooter>
                </Card>

                <Box my={2} boxShadow="md" style={boxStyle} bg={"white"}>
                  <Flex
                    flexDirection={"row"}
                    justifyContent={"space-around"}
                    alignItems={"center"}
                    height={"50px"}
                  >
                    {OrderSelected.cancel_status ? (
                      <VStack
                        onClick={() =>
                          openConfirmationModal(
                            "Are you sure you want to cancel the product ?",
                            6,
                            OrderSelected
                          )
                        }
                        justifyContent={"center"}
                        cursor={"pointer"}
                      >
                        <Text fontSize={"xs"}>Cancel Product</Text>
                      </VStack>
                    ) : null}
                    {OrderSelected.return_status ? (
                      <VStack
                        cursor={"pointer"}
                        onClick={() => openReturnModal(OrderSelected)}
                      >
                        <Text fontSize={"xs"}>Return Product</Text>
                        {/* <Icon as={RepeatIcon} pb={1} w={4} h={4} color={"yellow"} /> */}
                      </VStack>
                    ) : null}

                    {showRatingOption(OrderSelected) ? (
                      <VStack
                        cursor={"pointer"}
                        onClick={() => openRateModal(OrderSelected)}
                      >
                        <Text fontSize={"xs"}>Rate Product</Text>
                        {/* <Icon as={StarIcon} pb={1} w={4} h={4} color={"yellow.400"} /> */}
                      </VStack>
                    ) : null}
                  </Flex>
                </Box>

                {/* section 3 */}
                <Box pt={2}>
                  <Card>
                    <CardBody>
                      <Box>
                        <Text fontSize={"xs"} color={"gray.500"}>
                          {/* Return/Exchange window closed on Tuesday,29 aug 2023 */}
                          The return policy for the product is{" "}
                          {OrderSelected.return_days} days.
                        </Text>

                        {/* <Text fontSize={'xs'} as={'b'} color={'blue.500'}>Return policy</Text> */}
                      </Box>

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />

                      <Box>
                        <Accordion allowToggle>
                          <AccordionItem>
                            <AccordionButton pl={0}>
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                fontSize={"xs"}
                                fontWeight={600}
                              >
                                You Rated :{" "}
                                {OrderSelected.rattings && (
                                  <Icon
                                    as={StarIcon}
                                    pb={1}
                                    w={4}
                                    h={4}
                                    color={"yellow.400"}
                                  />
                                )}{" "}
                                {OrderSelected.rattings
                                  ? OrderSelected.rattings
                                  : "No rating"}
                              </Box>

                              <AccordionIcon />
                            </AccordionButton>

                            <AccordionPanel pb={4} pl={0}>
                              <Text fontSize={"xs"} color={"gray.500"}>
                                {" "}
                                {OrderSelected.comment
                                  ? OrderSelected.comment
                                  : "No Comment"}
                              </Text>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Box>

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />

                      <Box>
                        <Stack spacing={0}>
                          <Text fontSize={"sm"} as="b" mb={3}>
                            Deliver Addresss
                          </Text>

                          <Text fontSize={"xs"} as="b">
                            {orderInfoData.full_name}
                          </Text>

                          <Text fontSize={"xs"} color={"gray.500"}>
                            {orderInfoData.street_address}
                            {orderInfoData.landmark}, {orderInfoData.city},{" "}
                            {orderInfoData.state} -{orderInfoData.pincode}
                          </Text>

                          <Text fontSize={"xs"} color={"gray.500"}>
                            Phone : {orderInfoData.mobile}
                          </Text>
                        </Stack>
                      </Box>

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />

                      <Box mb={3}>
                        <Text fontSize={"sm"} as={"b"}>
                          Other Items
                        </Text>

                        <HStack spacing={2} mt={3}>
                          <Grid
                            templateColumns={{
                              base: "repeat(3, 1fr)", // 1 column on small screens
                              md: "repeat(4, 1fr)", // 4 columns on medium and larger screens
                            }}
                            gap={2}
                          >
                            {OrderList.map((item, index) =>
                              item.id !== OrderSelected.id ? (
                                <Box
                                  key={item.id}
                                  height={100}
                                  width={100}
                                  onClick={() =>
                                    handleUpdateSelectedOrder(item)
                                  }
                                >
                                  <Image
                                    src={item.image_url}
                                    borderRadius="lg"
                                    height={"100%"}
                                    width={"100%"}
                                    objectFit={"contain"}
                                  />
                                </Box>
                              ) : null
                            )}
                          </Grid>
                        </HStack>
                      </Box>

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />
                      <Box mb={3} display="flex" alignItems="center">
                        <Text fontSize="sm" as="b">
                          Payment Details
                        </Text>
                        <Box flex="1" textAlign="right">
                          <Link
                            color="blue.500"
                            fontSize={"12px"}
                            href={
                              process.env.REACT_APP_INVOICE_URL +
                              "/" +
                              orderInfoData.order_number
                            }
                            isExternal
                          >
                            Download Invoice
                          </Link>
                        </Box>
                      </Box>

                      <Box>
                        <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                          <GridItem colSpan={4}>
                            <Stack>
                              <Text fontSize={"sm"} color={"gray.500"}>
                                Total MRP
                              </Text>

                              <Text fontSize={"sm"} color={"gray.500"}>
                                Coupon Discount
                              </Text>

                              <Text fontSize={"sm"} color={"gray.500"}>
                                Convenience Fee
                              </Text>
                              {/* <Text fontSize={"sm"} color={"gray.500"}>
                                Tax
                              </Text> */}

                              <Text fontSize={"sm"} as={"b"}>
                                Total Amount
                              </Text>
                            </Stack>
                          </GridItem>

                          <GridItem colSpan={1}>
                            <Stack>
                              <Text fontSize={"sm"} color={"gray.500"}>
                                {orderInfoData.total_mrp}
                              </Text>

                              <Text fontSize={"sm"} color={"gray.500"}>
                                {orderInfoData.discount_amount}
                              </Text>

                              <Text fontSize={"sm"} color={"gray.500"}>
                                {shipping_cost}
                              </Text>
                              {/* <Text fontSize={"sm"} color={"gray.500"}>
                                {orderInfoData.tax}
                              </Text> */}

                              <Text fontSize={"sm"} as={"b"}>
                                ₹ {orderInfoData.grand_total}
                              </Text>
                            </Stack>
                          </GridItem>
                        </Grid>
                      </Box>

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />

                      <Box>
                        <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                          <GridItem colSpan={3}>
                            <Text fontSize={"xs"} as={"b"} color={"gray.500"}>
                              Order id : {orderInfoData.order_number}
                            </Text>
                          </GridItem>

                          <GridItem colSpan={3}>
                            <Text fontSize={"xs"} as={"b"} color={"gray.500"}>
                              Placed: {orderInfoData.date}
                            </Text>
                          </GridItem>
                        </Grid>
                      </Box>

                      <StackDivider
                        my={2}
                        borderColor="gray.200"
                        borderWidth={window.innerWidth > 500 ? "" : 3}
                      />
                    </CardBody>
                  </Card>
                </Box>
              </Box>

              {/*  */}
              {/* overlays */}
              <Modal isOpen={isRateModalOpen} onClose={closeRateModal}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Rate Product</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Heading
                      fontWeight={300}
                      as={"h2"}
                      color="#535665"
                      fontSize="20px"
                      size="lg"
                      mb={5}
                    >
                      {clickedOrder.product_name}{" "}
                    </Heading>
                    <FormControl>
                      <FormLabel>Rating</FormLabel>
                      <RatingStar
                        initialRating={0}
                        maxRating={5}
                        onUpdate={(count) => handleRatingChange(count)}
                      ></RatingStar>
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Comments</FormLabel>
                      <Textarea
                        placeholder="Enter your comments"
                        onChange={handleCommentChange}
                        value={comment}
                      />
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={submitRatingAPICall}
                    >
                      Save
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <Modal isOpen={isReturnModalOpen} onClose={closeReturnModal}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Return Product</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Heading
                      fontWeight={300}
                      as={"h2"}
                      color="#535665"
                      fontSize="20px"
                      size="lg"
                      mb={5}
                    >
                      {clickedOrder.product_name}{" "}
                    </Heading>
                    <FormControl mt={4}>
                      <FormLabel>Return Reason</FormLabel>
                      <Textarea
                        placeholder="Enter your comments. Based on this comment the seller will act upon your return request"
                        name="reason"
                        onChange={handleReturnReasonChange}
                        value={returnReason.reason}
                      />
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Bank Account Holder Name</FormLabel>
                      <Input
                        placeholder="Enter the account holder name"
                        name="accountHolderName"
                        onChange={handleReturnReasonChange}
                        value={returnReason.accountHolderName}
                      />
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>Bank Account Number</FormLabel>
                      <Input
                        placeholder="Enter your bank account number"
                        name="bankAccountNumber"
                        onChange={handleReturnReasonChange}
                        value={returnReason.bankAccountNumber}
                      />
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel>IFSC Code</FormLabel>
                      <Input
                        placeholder="Enter your IFSC code"
                        name="ifscCode"
                        onChange={handleReturnReasonChange}
                        value={returnReason.ifscCode}
                      />
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={submitReturnAPICall}
                    >
                      Save
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
              <ConfirmationModal
                onConfirm={(confirm, order) =>
                  handleConfirmationDialogYes(confirm, order)
                }
                isOpen={isConfirmationModalOpen}
                onClose={closeConfirmationModal}
                text={confirmationModalText}
                product={clickedOrder}
              />
            </>
          )}
        </Box>
      </Show>
    </>
  );
};

export default OrderDetails;
