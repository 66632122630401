import {
    Box,
    Center,
    Spinner,
    Heading,
    HStack,
    VStack,
    Text,
    Input,
    InputGroup,
    FormControl,
    FormLabel,
    Button,
    PinInput,
    PinInputField,
  } from "@chakra-ui/react";

  import React, { useState, useEffect } from "react";

  import { useLocation, useNavigate } from "react-router-dom";

  import { useDispatch, useSelector } from "react-redux";

  import {  indentifierOtpVerify, indentifierPasswordReset, indentifierVerify } from "../Redux/AuthReducer/Action";



  import Navbar2 from "../Components/Navbar2";


  const PasswordReset = () => {
    const navigate = useNavigate();



    const dispatch = useDispatch();

    const {isLoading,emailVerified,indentifierOtpVerified,passwordReset} = useSelector(
      (store) => store.AuthReducer
    );
//eslint-disable-next-line
    const [input, setInput] = useState({
      email: "",

      password: "",
    });
//eslint-disable-next-line
    const [isError, setIsError] = useState(false);
    const [inputIdentifier, setInputIdentifier] = useState("");
    const [inputOtp, setInputOtp] = useState("");
    const [inputPassword, setInputPassword] = useState("");

    const location = useLocation();
//eslint-disable-next-line
    const comingFrom = location?.state || "/";
    var params={
        identifier:inputIdentifier
    }
    const handleSubmit=()=>{
        if(!emailVerified)
        {
         //   alert(params.identifier);
          dispatch(indentifierVerify(params));
        }
        else if(!indentifierOtpVerified)
        {

            params={...params,otp:inputOtp};

            dispatch(indentifierOtpVerify(params));
        }
        else
        {
            params={...params,password:inputPassword};
            dispatch(indentifierPasswordReset(params));
        }
    }
      useEffect(()=>{
         if(passwordReset){
             dispatch({ type: 'PASSWORD_RESET_RESET_ALL_VALUES'});
             navigate('/login');

         }
         //eslint-disable-next-line
          },[passwordReset])
  //   alert(emailVerified);
         var page="";
    if(!emailVerified && !indentifierOtpVerified && !passwordReset)
    { //alert("email");
         page="email";
    }
    else if(emailVerified && !indentifierOtpVerified && !passwordReset)
    {
         page="otp";
    }
    else if(emailVerified && indentifierOtpVerified && !passwordReset)
    {
         page="password";
    }

    return (
      <>
        {isLoading && (
          <Box
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={9999}
            backgroundColor="rgba(0, 0, 0, 0.5)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size="xl" color="#ffffff" />
          </Box>
        )}

        <Box>
          <Navbar2 />

          <Center w={"full"} bgColor={window.innerWidth > 500 ? "#fceeea" : '#d3d3d361'} h={"100vh"}>
            <VStack w={"420px"} spacing="0">
              {/* <Box>

                              <Image src="https://assets.myntassets.com/f_webp,dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/2022/9/21/8fca3ae9-d245-443b-a142-8d568247794c1663700243878-offer-banner-300-600x240-code-_-MYNTRA400.jpg" />

                          </Box> */}

              <Box w={"100%"} p={"40px 30px 10px 30px"} bgColor="white">
                <FormControl isInvalid={isError}>
                  <FormLabel display={"flex"} as="div">
                    <Center>
                      <HStack
                        w="full"
                        alignItems={"baseline"}
                        gap="0"
                        spacing={"5px"}
                      >
                        <Heading
                          fontWeight={"600"}
                          as={"h2"}
                          color="#424553"
                          fontSize="24px"
                          size="lg"
                        >
                           <Text fontSize="lg" as="span">
                                            {page === "email" ? "Email Verify" : page === "otp" ? "Otp Verify" : "Password Reset"}
                                        </Text>
                        </Heading>
                      </HStack>
                    </Center>
                  </FormLabel>

                  <InputGroup mt={10} size={"sm"} variant={"outline"}>


                   {
                    page === "email" ? (
                     <Input
                      p={"15px 10px"}
                      focusBorderColor="#f4f4f4"
                      type="email"
                      placeholder="Email or Mobile"
                      value={input.inputIdentifier}
                      onChange={(e) => setInputIdentifier(e.target.value)}
                    />
                    ): null
                   }
                    {
                    page === "otp" ? (
                    //  <Input
                    //   p={"15px 10px"}
                    //   focusBorderColor="#f4f4f4"
                    //   type="email"
                    //   placeholder="Otp"
                    //   value={input.inputOtp}
                    //   onChange={(e) => setInputOtp(e.target.value)}
                    // />
                    <HStack
                    m={"5px 0px 25px 0px"}
                  >
                    <PinInput  value={input.inputOtp}  onChange={(value) => setInputOtp(value)} otp mask placeholder='&#10146;'>
                    <PinInputField w="30px" h="40px" />
                    <PinInputField w="30px" h="40px" />
                    <PinInputField w="30px" h="40px" />
                    <PinInputField w="30px" h="40px" />
                    <PinInputField w="30px" h="40px" />
                    <PinInputField w="30px" h="40px" />
                  </PinInput>
                  </HStack>
                    ): null
                   }
{
                    page === "password" ? (
                     <Input
                      p={"15px 10px"}
                      focusBorderColor="#f4f4f4"
                      type="password"
                      placeholder="Password"
                      value={input.inputPassword}
                      onChange={(e) => setInputPassword(e.target.value)}
                    />
                    ): null
                   }
                  </InputGroup>





                  <Button
                    w={"100%"}
                    mt={8}
                    mb={4}
                    variant="solid"
                    backgroundColor={window.innerWidth > 500 ? '#ff3f6c' : 'black'}
                    color={"#fff"}
                    borderRadius="0"
                    colorScheme={"none"}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Next
                  </Button>
                </FormControl>



                {/* <Text mb={10} color={"#a7a9af"} textAlign="left">
                  Have trouble logging in?
                  <ChakraLink
                    fontWeight={"bold"}
                    _hover={{ textDecoration: "none" }}
                    color={"#ff3f6c"}
                    href="#"
                  >
                    &nbsp;Get help
                  </ChakraLink>
                </Text> */}
              </Box>
            </VStack>
          </Center>
        </Box>
      </>
    );
  };

  export default PasswordReset;
