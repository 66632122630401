import { Box, Show } from "@chakra-ui/react";
import React, { useEffect, useState, useMemo } from "react";
import CarouselCom from "../Components/Carousel";
import Footer from "../Components/Footer";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Navbar2 from "../Components/Navbar2";
import ProductsGroup from "../Components/ProductsGroup";
import SecondaryBanner from "../Components/Banner/SecondaryBanner";
import VideoBanner from "../Components/Banner/VideoBanner";
import BannerMobile from "../Components/Banner/BannerMobile";
import CategoryGroup from "../Components/CategoryGroup";
import { resetProductsDetailsState } from "../Redux/AppReducer/Action";

const Home = () => {
  const dispatch = useDispatch();
  const [banner, setBanner] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [hotProducts, setHotProducts] = useState([]);

  //eslint-disable-next-line
  const [feauturedProducts, setFeauturedProducts] = useState([]);
  const [categoryBanner, setCategoryBanner] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);
  const [latestBanner, setLatestBanner] = useState([]);
  const [bestSellerHeading, setBestSellerHeading] = useState("");
  const [hotProductHeading, setHotProductHeading] = useState("");
  const [latestArrivalHeading, setLatestArrivalHeading] = useState("");
  const [topCategroyHeading, setTopCategroyHeading] = useState("");
  const [latestBannerHeading, setLatestBannerHeading] = useState("");
  const [mbSubCatBanner, setMbSubCatBanner] = useState([]);
  const [twoBtnBanner, setTwoBtnBanner] = useState([]);
  const [videoBanner, setVideoBanner] = useState([]);

  //eslint-disable-next-line
  const [subCategories, setSubCategories] = useState([]);
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const { userData } = useSelector((store) => store.AuthReducer);
  //eslint-disable-next-line
  const params = useMemo(
    () => ({
      user_id: userData.id,
    }),

    [userData.id]
  );

  useEffect(() => {
    dispatch(resetProductsDetailsState());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios({
      method: "get",
      url: process.env.REACT_APP_API + "/banner",
      withCredentials: false,
    })
      .then((res) => {
        setIsBannerLoading(false);
        setBanner(res.data?.sliders);
        setCategoryBanner(res.data?.categorybanner);
        setBestSeller(res.data?.bestsellerbanner);
        setLatestBanner(res.data?.latestbanner);
        setLatestBannerHeading(res.data?.latestbannerheading);
        setBestSellerHeading(res.data?.bestsellerheading);
        setHotProductHeading(res.data?.hotproductheading);
        setLatestArrivalHeading(res.data?.latestarrivalheading);
        setTopCategroyHeading(res.data?.topcategoryheading);
        setMbSubCatBanner(res.data?.mbsubcatbanner);
        setTwoBtnBanner(res.data?.twobtnbanner);
        setVideoBanner(res.data?.videobanner);
      })

      .catch((err) => {
        setIsBannerLoading(false);
      });
  }, []);

  useEffect(() => {
    axios({
      method: "post",
      url: process.env.REACT_APP_API + "/homefeeds",
      withCredentials: false,
    })
      .then((res) => {
        setNewProducts(res.data?.new_products);
        setHotProducts(res.data?.hot_products);
        setFeauturedProducts(res.data?.featured_products);
        setSubCategories(res.data?.sub_categories);
      })

      .catch((err) => {
        setNewProducts([]);
        setHotProducts([]);
        setFeauturedProducts([]);
        setSubCategories([]);
      });
  }, []);

  return (
    <>
      <Navbar2 />


        <>
          <CarouselCom data={banner}  isLoading={isBannerLoading} />
          <SecondaryBanner
                aspectRatio={1}
                data={categoryBanner}
                mr={2}
                type="CATEGORY"
                isLoading={isBannerLoading}
              />
            </>
          <Show breakpoint="(min-width:500px)">

              <>
                <Box my={2}>
                  <SecondaryBanner
                    aspectRatio={1}
                    data={bestSeller}
                    mr={0}
                    type="BEST_SELLER"
                    heading={bestSellerHeading}
                    isLoading={isBannerLoading}
                  />
                </Box>
              </>

              <>
                <Box my={2}>
                  <SecondaryBanner
                    aspectRatio={1}
                    data={latestBanner}
                    mr={0}
                    type="LATEST"
                    heading={latestBannerHeading}
                    isLoading={isBannerLoading}
                  />
                </Box>
              </>
          </Show>
          <Show breakpoint="(max-width: 500px)">
            {videoBanner.map((item) => (
              <VideoBanner data={item}  isLoading={isBannerLoading}></VideoBanner>
            ))}
          </Show>
          <Show breakpoint="(max-width: 500px)">
            {twoBtnBanner.map((item) => (
              <BannerMobile type="TWO_BTN" data={item} isLoading={isBannerLoading}></BannerMobile>
            ))}
          </Show>
          <Show breakpoint="(max-width: 500px)">
            {mbSubCatBanner.map((item) => (
              <BannerMobile type="SUBCAT" data={item} isLoading={isBannerLoading}></BannerMobile>
            ))}
          </Show>
          {hotProducts.length > 0 ? (
            <Show breakpoint="(min-width: 500px)">
              <ProductsGroup
                products={hotProducts}
                column={5}
                spacingX={"1"}
                heading={hotProductHeading}
                isLoading={isBannerLoading}
                // heading={"Raining Hot Products 🔥"}
              />
            </Show>
          ) : null}
          <Show breakpoint="(min-width: 500px)">
            <ProductsGroup
              products={newProducts}
              column={5}
              spacingX={"2"}
              heading={latestArrivalHeading}
              isLoading={isBannerLoading}
              // heading={"Latest Arrivals In Town"}
            />

            <CategoryGroup
              endpoint={"/CatToBag"}
              column={8}
              category={subCategories}
              spacingX={"2"}
              spacingY={"2"}
              spacing={2}
              heading={topCategroyHeading}
              isLoading={isBannerLoading}
              // heading={"CATEGORIES TO BAG"}
            />
            {/* <Box mt={16}>
              <Footer />{" "}
            </Box> */}
          </Show>
          <Box mt={16}>
            <Footer />{" "}
          </Box>
        </>


  );
};

export default Home;
