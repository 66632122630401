import { Box, Image, Button, Text, Skeleton } from "@chakra-ui/react";
import React from "react";

const BannerMobile = ({ type, data, isLoading }) => {
  return (
    <Box mt={2}>
      {isLoading ? (
        <Box position="relative">
          <Skeleton height="300px" width="100%" />
          <Box
            position="absolute"
            bottom="4%"
            width="100%"
            display="flex"
            justifyContent="center"
            gap="20px"
          >
            <Skeleton height="40px" width="35%" />
            {type !== "SUBCAT" && <Skeleton height="40px" width="35%" />}
          </Box>
        </Box>
      ) : (
        <>
          {/* type == subcat then we show banner with single button */}
          {type === "SUBCAT" ? (
            <Box position="relative">
              <Image
                src={data.image_url}
                alt="First Image"
                objectFit="cover"
                w="100%"
                h="100%"
              />
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  display: "flex",
                  bottom: "4%",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Button
                  colorScheme="red"
                  aspectRatio={1}
                  w={"35%"}
                  fontSize={["xs", "sm", "md", "lg"]} // Responsive font size
                  onClick={() => {
                    window.location.href = data.link;
                  }}
                >
                  <Text isTruncated>{"Shop Now"}</Text>
                </Button>
              </div>
            </Box>
          ) : (
            <Box position="relative">
              <Image
                src={data.image_url}
                alt="First Image"
                objectFit="cover"
                w="100%"
                h="100%"
              />

              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  display: "flex",
                  bottom: "4%",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <Button
                  colorScheme="red"
                  w={"35%"}
                  aspectRatio={1}
                  fontSize={["xs", "sm", "md", "lg"]}
                  onClick={() => (window.location.href = data.link1)}
                >
                  <Text isTruncated>{data.title1}</Text>
                </Button>
                <Button
                  w={"35%"}
                  colorScheme="red"
                  aspectRatio={1}
                  fontSize={["xs", "sm", "md", "lg"]}
                  onClick={() => (window.location.href = data.link2)}
                >
                  <Text isTruncated>{data.title2}</Text>
                </Button>
              </div>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default BannerMobile;
