// import { createSlice } from "@reduxjs/toolkit";
// import axios from "axios";
// const initialState = {
//   topbannerimg: "",
//   wishlistcount: 0,
//   cartcount: 0,
// };

// const NavBarhelperdataSlice = createSlice({
//   name: "NavBarhelperdata",
//   initialState: initialState,
//   reducers: {
//     setTopBannerImg: (state, action) => {
//       state.topbannerimg = action.payload;
//     },
//     setWishlistCount: (state, action) => {
//       state.wishlistcount = action.payload;
//     },
//     setCartCount: (state, action) => {
//       state.cartcount = action.payload;
//     },
//   },
// });

// async function gethelperdata(params) {
//   const response1 = await axios({
//     method: "get",
//     url: process.env.REACT_APP_API + "/banner",
//     withCredentials: false,
//   });

//   const response2 = await axios.post(
//     `${process.env.REACT_APP_API}/getwishlist`,
//     params
//   );

//   const response3 = await axios.post(
//     `${process.env.REACT_APP_API}/getcart`,
//     params
//   );

//   return { response1, response2, response3 };
// }

// export const gethelperdataAsync = (params) => async (dispatch) => {
//   const response = await gethelperdata(params);
//   dispatch(setTopBannerImg(response.response1.data.topbanner));
//   dispatch(setWishlistCount(response.response2.data.data.length));
//   dispatch(setCartCount(response.response3.data.data.length));
// };

// export const { setTopBannerImg, setWishlistCount, setCartCount } =
//   NavBarhelperdataSlice.actions;

// export default NavBarhelperdataSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  topbannerimg: "",
  wishlistcount: 0,
  cartcount: 0,
};

const NavBarhelperdataSlice = createSlice({
  name: "NavBarhelperdata",
  initialState: initialState,
  reducers: {
    setTopBannerImg: (state, action) => {
      state.topbannerimg = action.payload;
    },
    setWishlistCount: (state, action) => {
      state.wishlistcount = action.payload;
    },
    setCartCount: (state, action) => {
      state.cartcount = action.payload;
    },
  },
});

async function getTopBannerImg() {
  const response = await axios({
    method: "get",
    url: process.env.REACT_APP_API + "/banner",
    withCredentials: false,
  });

  return response.data.topbanner;
}

async function getWishlistCount(params) {
  const response = await axios.post(
    `${process.env.REACT_APP_API}/getwishlist`,
    params
  );

  return response.data.data.length;
}

async function getCartCount(params) {
  const response = await axios.post(
    `${process.env.REACT_APP_API}/getcart`,
    params
  );

  return response.data.data.length;
}

export const getTopBannerImgAsync = () => async (dispatch) => {
  const topBannerImg = await getTopBannerImg();
  dispatch(setTopBannerImg(topBannerImg));
};

export const getWishlistCountAsync = (params) => async (dispatch) => {
  const wishlistCount = await getWishlistCount(params);
  dispatch(setWishlistCount(wishlistCount));
};

export const getCartCountAsync = (params) => async (dispatch) => {
  const cartCount = await getCartCount(params);
  dispatch(setCartCount(cartCount));
};

export const { setTopBannerImg, setWishlistCount, setCartCount } =
  NavBarhelperdataSlice.actions;

export default NavBarhelperdataSlice.reducer;
