import {
  HStack,
  VStack,
  Skeleton,
  Radio,
  Switch,
  Text,
  Tag,
  Button,
  Box
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  editAddress,
  selectAndSaveAddress,
} from "../Redux/CheckoutReducer/Action";

const AddressCard = ({
  item,
  handleAddressEdit,
  handleAddressDelete,
  setActiveCardIndex,
  activeCardIndex,
  pageType,
  selectedCartAddress,
  defaultAddress,
  isDefaultAddress,
  handleCheckboxChange
}) => {
  const { isLoading } = useSelector((store) => store.CheckoutReducer);
  const dispatch = useDispatch();
  // const [isDefault, setIsDefault] = useState(defaultAddress[0].id === item.id);
 // var addressId;
   console.log("selectedAddress checkbox:",isDefaultAddress);

  // if(JSON.parse(localStorage.getItem("selectedAddress")))
  // {
  //   addressId = JSON.parse(localStorage.getItem("selectedAddress")).id
  // }

  const handleClick = () => {
    setActiveCardIndex(item.id);
  };

  const handleAddressRadio = (item) => {
    handleCheckboxChange(item.id);
  //  setIsDefault(true);
    dispatch(selectAndSaveAddress(item));
  };

  const handleOnChange = (value, item) => {
    const params = { ...item, default_address: value, address_id: item.id };
    dispatch(editAddress(params));
  };

  return (
    <Box>
    <Skeleton isLoaded={!isLoading}>
      <HStack
        onClick={() => handleClick(item)}
        spacing={"5px"}
        borderRadius={"5px"}
        boxShadow={
          "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em"
        }
        p={4}
        align={"space-between"}
      >
        {
          // eslint-disable-next-line
          pageType == 2 ? (
            <HStack mt={1} alignItems={"flex-start"} flex={0.1}>
              <Radio
                value={item.id}
                // eslint-disable-next-line
                // isChecked={isDefault}
                //eslint-disable-next-line
                isChecked={isDefaultAddress == item.id}
                onChange={() => handleAddressRadio(item)}
              />
            </HStack>
          ) : (
            <></>
          )
        }

        <VStack alignItems={"flex-start"} flex={0.5}>
          <HStack justifyContent="space-between">
            <HStack textAlign="left">
              <Text fontSize="14px" color="#282c3f" fontWeight="bold">
                {item.first_name}
              </Text>
              <Tag fontSize="10px"

                color="#03a685"
                colorScheme="gray"
                borderRadius="25%"
                fontWeight="bold"
              >
                {item.type}
              </Tag>
            </HStack>
          </HStack>

          <VStack
            align="flex-start"
            fontSize={"13px"}
            color="#424553"
            fontWeight={"400"}
            spacing={0}
          >
            <Text>
              Address: {item.street_address}-{item.landmark}-{item.city}
              {","} <span>{item.pincode}</span>
            </Text>
          </VStack>
          <HStack fontSize={"13px"} color="#424553">
            <Text>Mobile:</Text>
            <Text color={"#424553"} fontWeight={"bold"}>
              {item.mobile}
            </Text>
          </HStack>
          <HStack fontSize={"13px"} color="#424553">
            <Text>City:</Text>
            <Text color={"#424553"} fontWeight={"bold"}>
              {item.city}
            </Text>
          </HStack>
          <HStack fontSize={"13px"} color="#424553">
            <Text>State:</Text>
            <Text color={"#424553"} fontWeight={"bold"}>
              {item.state}
            </Text>
          </HStack>


          <HStack>
            <Button
              onClick={() => {
                handleAddressEdit(item);
              }}
              variant={"outline"}
              fontSize={"12px"}
              size="sm"
              colorScheme="blackAlpha"
              color="#424553"
            >
              EDIT ADDRESS
            </Button>
            <Button
              onClick={() => {
                handleAddressDelete(item);
              }}
              variant={"outline"}
              fontSize={"12px"}
              size="sm"
              colorScheme="blackAlpha"
              color="#424553"
            >
              REMOVE ADDRESS
            </Button>
          </HStack>
        </VStack>
        <HStack
          textAlign="right"
          alignItems={"flex-start"}
          justifyContent={"flex-end"}
          flex={0.4}
        >
          <Text
            // display={activeCardIndex === item.id ? "flex" : "none"}
            fontSize="12px"
            color="#14cda8"
            fontWeight={"bold"}
          >
            MAKE THIS DEFAULT{" "}
            <span>
              {" "}
              <Switch
                paddingLeft={1}
                size="sm"
                colorScheme="teal"
                // eslint-disable-next-line
                isChecked={item.default_address == 1}
                onChange={(e) => handleOnChange(e.target.checked, item)}
              />
            </span>
          </Text>
        </HStack>
      </HStack>
    </Skeleton>
    </Box>
  );
};

export default AddressCard;
