import * as types from "./ActionTypes.js"
import axios from "axios"


// ...............isLoading state

export const getOrderDetailLoading = () => {
  return {
    type: types.GET_ORDERDETAIL_REQUEST
  }
}

// .................................

// ...................Success state

export const getOrderDetailSuccess = (payload) => {
  return {
    type: types.GET_ORDERDETAIL_SUCCESS,
    payload
  }
}
// .................................

// .................isError state

export const getOrderDetailFailure = () => {
  return {
    type: types.GET_ORDERDETAIL_FAILURE
  }
}


export const updateOrderStatus = (params) => async (dispatch) => {


 try {
  await axios.post(
     `${process.env.REACT_APP_API}/orderstatus`,
     params,
   );

 } catch (error) {

 }

}

export const getOrderDetails = (params) => async (dispatch) => {

     dispatch(getOrderDetailLoading());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/orderdetails`,
        params,
      );
      console.log("getOrderDetails here:",response.data);
      dispatch(getOrderDetailSuccess(response.data));
    } catch (error) {
      dispatch(getOrderDetailFailure());
    }

  }


  export const updateSelectedDetails = (params) => async (dispatch) => {

    try {
        dispatch({ type: 'UPDATE_SELECTED_ORDER',payload:params});
    } catch (error) {
      dispatch(getOrderDetailFailure());
    }

  }




// .......................