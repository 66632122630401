import * as types from "./ActionTypes";

const initialState = {
  isAuth: JSON.parse(localStorage.getItem("isAuth")) || false,
  isLoading: false,
  isError: false,
  isErrorMessage: false, // Add the isErrorMessage state
  isSignUpErrorMessage:false,
  userData: JSON.parse(localStorage.getItem("userData")) || [],
  registerPhaseStatus:false,
  registerFailMessage:"",
  isOtpVerify:false,
  loginPhaseStatus:false,
  phonenumber:'',
  ////password reset/////
  emailVerified:false,
  indentifierOtpVerified:false,
  passwordReset:false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isErrorMessage: false, // Reset isErrorMessage on login request
      };
    case types.GET_LOGIN_SUCCESS:
      localStorage.setItem("isAuth", true);
      localStorage.setItem("userData", JSON.stringify(action.payload));
      //console.log("user data:",action.payload);
      return {
        ...state,
        isLoading: false,
        isAuth: true,
        isErrorMessage: false, // Reset isErrorMessage on login success
        userData: action.payload,
      };
    case types.GET_LOGOUT_SUCCESS:
      localStorage.setItem("isAuth", false);
      localStorage.setItem("userData", null);
      return {
        ...state,
        isLoading: false,
        isAuth: false,
        isErrorMessage: false, // Reset isErrorMessage on logout success
        userData: [],
      };


      case 'VERIFY_OTP':
        localStorage.setItem("userData", JSON.stringify(action.payload));
        return {
          ...state,
          isLoading: false,
          isAuth:false,
          loginPhaseStatus:true,
          userData: action.payload,
        };

        case 'VERIFY_OTP_MOBILE_VERSION':
        return {
          ...state,
          isLoading: false,
          isAuth:false,
          loginPhaseStatus:true,
        };

      case 'USER_PHONE_NUMBER':
        localStorage.setItem("phonenumber", JSON.stringify(action.payload));
        return{
          ...state,
          phonenumber: action.payload,
        }; 

    case types.GET_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isErrorMessage: true, // Set isErrorMessage to true on login failure
        loginPhaseStatus:false,
      };

      //---------------------------------------register----------------------------------------------
      case types.GET_REGISTER_REQUEST:
        return {
          ...state,
          isLoading: true,
          isSignUpErrorMessage: false,
          registerPhaseStatus:false,
          isErrorMessage:false
          // Reset isErrorMessage on login request
        };
      case types.GET_REGISTER_SUCCESS:
        // localStorage.setItem("isAuth", true);
            // console.log("register", action.payload);
        localStorage.setItem("userData", JSON.stringify(action.payload));
        //console.log("user data:",action.payload);
        return {
          ...state,
          isLoading: false,
          isAuth: false,
          isSignUpErrorMessage: false, // Reset isErrorMessage on login success
          userData: action.payload,
          registerPhaseStatus:true,
          isErrorMessage:false
          // registerFailMessage:
        };




        case types.GET_REGISTER_FAILURE:
          return {
            ...state,
            isLoading: false,
            isSignUpErrorMessage: true,
            registerPhaseStatus:false,
            isErrorMessage:false

            // Set isErrorMessage to true on login failure
          };

          case types.RESET_REGISTER_PHASE_STATUS:
            return {
              ...state,
              isLoading: false,
              isSignUpErrorMessage: false,
              registerPhaseStatus:false,
              isErrorMessage:false

              // Set isErrorMessage to true on login failure
            };

            case types.GET_OTP_REQUEST:
              return {
                ...state,
                isLoading: true,
                isErrorMessage: false,
                registerPhaseStatus:false,
                isAuth: false,
                // Reset isErrorMessage on login request
              };


              case types.GET_OTP_SUCCESS:
              // console.log("user data here:",JSON.parse(localStorage.getItem("userData")));
                localStorage.setItem("isAuth", JSON.stringify(true));
                return {
                  ...state,
                  isLoading: false,
                  isAuth:   JSON.parse(localStorage.getItem("isAuth")),
                  isErrorMessage: false, // false isErrorMessage on otp success
                  registerPhaseStatus:false,
                  loginPhaseStatus:false,
                  isOtpVerify:true,
                  userData: JSON.parse(localStorage.getItem("userData")) || [],
                  // registerFailMessage:
                };

                case types.GET_OTP_SUCCESS_MOBILE:
              // console.log("user data here:",JSON.parse(localStorage.getItem("userData")));
              // console.log("user data here:",action.payload);
                localStorage.setItem("userData", JSON.stringify(action.payload));
                localStorage.setItem("isAuth", JSON.stringify(true));
                return {
                  ...state,
                  isLoading: false,
                  isAuth:   JSON.parse(localStorage.getItem("isAuth")),
                  isErrorMessage: false, // false isErrorMessage on otp success
                  registerPhaseStatus:false,
                  loginPhaseStatus:false,
                  isOtpVerify:true,
                  userData: JSON.parse(localStorage.getItem("userData")) || [],
                  // registerFailMessage:
                };


                case types.GET_OTP_FAILURE:

                return {
                  ...state,
                  isLoading: false,
                  isAuth: false,
                  isErrorMessage: true, // true isErrorMessage on otp invalid
                  registerPhaseStatus:false,
                  isOtpVerify:false,
                  userData: JSON.parse(localStorage.getItem("userData")) || [],
                  // registerFailMessage:
                };


                case 'PASSWORD_RESET_EMAIL_VERIFY':

                return {
                  ...state,
                  emailVerified: true,

                  // registerFailMessage:
                };
                case 'PASSWORD_RESET_OTP_VERIFY':

                return {
                  ...state,
                  emailVerified: true,
                  indentifierOtpVerified:true

                  // registerFailMessage:
                };

                case 'PASSWORD_RESET':

                return {
                  ...state,
                  emailVerified: true,
                  indentifierOtpVerified:true,
                  passwordReset:action.payload

                  // registerFailMessage:
                };


                case 'PASSWORD_RESET_RESET_ALL_VALUES':

                return {
                  ...state,
                  emailVerified: false,
                  indentifierOtpVerified:false,
                  passwordReset:false

                  // registerFailMessage:
                };

                case 'SHOW_LOADER':

                return {
                  ...state,
                  isLoading: true,

                  // registerFailMessage:
                };

                case 'STOP_LOADER':

                return {
                  ...state,
                  isLoading: false,

                  // registerFailMessage:
                };

                case 'RESET_AUTH_STATUS':
                 return {
                  ...state,
                  isLoading: false,
                  loginPhaseStatus:false,
                  // registerFailMessage:
                };

    default:
      return state;
  }
};

export { reducer };
