import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import Navbar2 from "../Components/Navbar2";

function Faq() {
  return (
    <>
    <Navbar2></Navbar2>
    <Box bg={'white'} minH="100vh" p={4} color="black">
      <Container maxW="container.xl">
        <Heading as="h1" fontSize="2xl" textAlign="center" mb={8}>
          Frequently Asked Questions
        </Heading>
        <Accordion allowMultiple>
          {faqData.map((item, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text as={'b'}>
                        {item.question}
                    </Text>
                  </Box>
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <Text>{item.answer}</Text>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

      </Container>
    </Box>
    </>

  );
}

const faqData = [
    {
      question: "How long will the delivery take?",
      answer:
        "It's really quick! We strive to deliver all our orders/products in the fastest time possible. Depending upon your address, the delivery time may vary between 2 - 9 working days. That still won't stop us from trying to get you your order faster. On each product's display page, you can enter your location's pin code to check when you can expect to receive the delivery.",
    },
    {
      question: "How can I track my order?",
      answer:
        "You can track your order as soon as it is shipped out. To know the status of your item real-time, just tap on the tracking link in the email sent to you. Alternatively, you can also login to 98degreenorth.com and visit the “Profile “ section to know your order status.",
    },
    {
      question: "Will all the products be shipped out together?",
      answer:
        "We try to ship all products of an order together. However, sometimes few products of an order may get dispatched from different locations. If so, we might ship some products out first and ship the remaining in a separate shipment to ensure that you don't wait even a minute longer than you have to. We will notify you each time a shipment is dispatched or it is out for delivery; so that you can track it till we knock on your door.",
    },
    {
      question: "How much will the shipping cost me?",
      answer: "Shipping fee of Rs. 79 will be applied on all orders below Rs 500.",
    },
    {
      question: "Will I have to be personally present to receive the order?",
      answer:
        "We recommend that you receive the order yourself to ensure complete satisfaction. In case you are not present, the parcel will be delivered to the person available and this may be confirmed on call with you by our delivery executive. For security and validation, the signature of the receiver will be taken at the time of delivery.",
    },
    {
      question: "What should I do if I miss my delivery?",
      answer:
        "We understand that you can’t wait at your delivery address all the time, and that is exactly why we will try to attempt delivering your order thrice; each attempt on a consecutive day. So if you have missed the delivery please do not worry, we will try to make an attempt again.",
    },
    {
      question: "Can I change my delivery address and mobile number once the order has been placed?",
      answer:
        "Sorry, that is not possible at the moment as the system would have already passed the mobile number and address to our warehouse to pack and ship your product. That said, we never say never! You can always cancel the order before it has been packed and can place a new order. Don't worry, there are no cancellation charges.",
    },
    {
      question: "Why is the cash on delivery (COD) option not offered in my location?",
      answer:
        "Few places are not covered for COD by our delivery partners. Any inconvenience you face is regretted. This issue might also occur if the cash amount payable at the time of delivery exceeds a certain limit which is decided by our courier partners. Hence, we request you to always enter your pin code to check the availability of COD in your area. Alternatively, may we suggest an alternate form of payment? Perhaps a Credit Card, Debit Card, or Net Banking. We are not saying that this will be permanent; your pin code might be COD-enabled in the future. Do check!",
    },
    {
      question: "Do You Deliver everywhere in India?",
      answer:
        "That's the big dream and we're sure we'll achieve it soon! We strive to deliver to all the pin codes of India. At the time of shopping, please check if your pin code is covered by our Logistics Partner. If not, we would request you to provide an alternate shipping address.",
    },
    {
      question: "Do you deliver Internationally?",
      answer:
        "Right now, we do not deliver items internationally. You can make a purchase from anywhere in the world using Indian currency as the mode of payment as long as the shipping address is within India.",
    },
    {
      question: "What delivery partners do you use?",
      answer:
        "We will notify you of the name of the partner delivering your order, in advance.",
    },
    {
      question: "Are your products genuine?",
      answer:
        "You bet! We make sure that our products are 100% Genuine. Along with that, we take each product through a multiple-point quality check before shipping it out to you. That's the last thing you need to worry about! ☺",
    },
    {
      question: "I need a copy of invoice for my order placed with 98degreenorth. How do I get about it?",
      answer:
        "We ship all packages with an invoice that can be found along with the product. If after receiving the package, you need another invoice, give us a buzz and we'll email you a soft copy.",
    },
    {
      question: "What are the various statuses an order can have?",
      answer:
        "The various statuses are: Confirmed: This means that an order has been placed and the confirmation has been sent to you. Packed: This means that your order is packed and ready for dispatch. Dispatched: This means that the order is speeding towards you. Delivered: This means that the order has been delivered to you.",
    },
    {
      question: "The package I received was damaged?",
      answer:
        "We request you not to accept a package if it is tampered. In case you already have and that has an issue, Please reach out for help.",
    },
    {
      question: "Can I cancel my order?",
      answer:
        "Yes! You can cancel an order as long as we haven't packed it for you. As soon as the order is cancelled, we refund the entire amount paid for the order.",
    },
    {
      question: "What are the steps to be followed to cancel my order?",
      answer:
        "Please log in to your 98degreenorth account. Click on the Profile section at the top right corner of your screen. Select the order/item you want to cancel. Locate the 'Cancel' button and click on it. Voila! The order has been cancelled.",
    },
    {
      question: "How long will the refund take place after cancellation?",
      answer:
        "We initiate the refund immediately upon cancellations. The time for the refund to reflect in your source account might vary depending on the payment mode used. Generally, it is 7-10 days.",
    },
    {
      question: "Does 98degreenorth assure the complete refund after cancellation?",
      answer:
        "Absolutely! Although we must add that cancellations are not a very happy thing for us.",
    },
    {
      question: "What are the various modes of payment at 98degreenorth?",
      answer:
        "You can pay using the following methods of Payment: 1. Credit Card 2. Debit Card 3. Net Banking 4. E-Wallets (Paytm, MobiKwik, Freecharge, and more) 5. Cash on Delivery. All payment methods depend on your pin code, so please ensure that you check your pin code to find out the available methods of payment over there.",
    },
    {
      question: "What does convenience fee mean?",
      answer:
        "Convenience fee is a combination of COD charges (if applicable), fulfilment fees (to have a hassle-free shopping experience), and delivery fees. Convenience fee is non-refundable.",
    },
    {
      question: "What does opting for cash on delivery mean?",
      answer:
        "We understand that you might want to pay for an order by cash. That's why we've got a very convenient option where you can pay at your doorstep before receiving the package and signing the delivery sheet. However, a nominal convenience fee will be charged for COD orders. This fee is non-refundable.",
    },
    {
      question: "Will I have to pay for any hidden cost like GST, OCTROI, etc…?",
      answer:
        "You should not be asked to pay anything extra for the order. Only whatever is mentioned on the invoice can be paid. Please let us know if you've been bothered otherwise.",
    },
    {
      question: "Can I pay using international currency?",
      answer: "Unfortunately, at this time we accept only INR at 98degreenorth.",
    },
    {
      question: "What if the amount got debited from my side but I did not receive an Order ID?",
      answer:
        "We ensure that an Order ID is confirmed only once our bank receives the payment from your bank. Sometimes, due to unforeseen reasons, the amount might be debited from your side but wouldn't have been received by us yet. Please send an email at customercare@98degreenorth and we'll sort it out for you.",
    },
    {
      question: "For Cash-on-delivery orders, can I check the package before making a payment?",
      answer:
        "Our courier person will hand over the package to you only once the payment has been made. Please do not accept any package if it is tampered.",
    },
    {
      question: "How do I return my product?",
      answer:
        "Super easy! Please login with your credentials and visit the Profile section. Select the order you want to return and click on Return and follow the instructions on this page to obtain a Return ID.",
    },
    {
      question: "What is your return policy?",
      answer:
        "At AJIO, we have a very flexible returns policy which is absolutely customer-friendly. If you do not find the product(s) satisfying, you can return it as long as the following conditions are met: It should be unused. You can wear it to check for fit and comfort, making sure that any smell does not transfer to the product (natural body odour, body deodorant, perfume etc.). It should be unwashed. We don't want you wasting any detergent! ☺. The price tags, brand tags, shoe-box and all original packaging must be present. The product must be returned within 7 days. Any gifts received must also be returned along with the product.",
    },
    {
      question: "Does the Return policy change for any 98degreenorth promotions?",
      answer:
        "At times, the returns policy may vary. To get the exact information, always refer to the Terms and Conditions of the promotion running at that time. That said, we always strive to keep our policies as flexible and customer-friendly as possible. ☺.",
    },
    {
      question: "Can I return the product to the delivery person if I did not like it?",
      answer:
        "Please don't do that! We have a very flexible policy where you can create a Return on the app, website, or by giving us a call. To avoid any confusion or delays, our courier partners are instructed to not accept a package as a return while performing a package delivery.",
    },
    {
      question: "How to get a refund for Cash on delivery in my bank account?",
      answer: "Super easy! All you need to do is update your bank details while creating a return.",
    },
    {
      question: "Why did my bank account transfer request fail?",
      answer:
        "This happens very rarely, only if we encounter a technical glitch while processing your refund. Don't worry! you can still get your amount in the bank account by visiting Order details page and re-submitting the bank account details. In case this is still failing, it may be due to : 1. Incorrect account details 2. Restriction on account by the Bank If so, your bank will be able to fix it for you. In case the issue still persists, please reach out to us and we will be happy to help.",
    },
    {
      question: "Do I get shipping charges refund in case of return?",
      answer:
        "If you have returned all the products in your order, you will be refunded the entire shipping charges. If you return the order partially, the shipping charges will be refunded on a pro-rated basis.",
    },
    {
      question: "What are the non-returnable items on 98degreenorth?",
      answer:
        "Fashion and Lifestyle products: Typically, we do not accept the below products Swimwear, Fragrances and Deodorants, Hair Care, Inner Wear, Shaving, Skin Care, Silver Jewellery, idols and coins, Lingerie and Innerwear, Makeup, Maternity, Beauty and Care, Mobile and Accessories, Music and Entertainment, Gadgets and Appliances, Accessories, Smart Wearables, Bath and Laundry, Decor and Gifting, Home Needs, Kitchen and Dining, Beauty Appliances, Night and Loungewear, Clothing Accessories, Ethnic Wear, Baby Care. To be certain if the product is non-returnable or not, you can refer to the disclaimer provided in the Returns tab provided on the Product Display Page. Note: If you've received a non-returnable product in a damaged/defective condition, please reach out to our customer service team immediately to initiate a request for a refund.",
    },
    {
      question: "Do I have to return the whole order?",
      answer: "Not at all! You do not need to return the whole order. Just return the items you don’t want.",
    },
    {
      question: "When is the refund of my return provided?",
      answer:
        "Once the Return ID is generated, we share a promised pick-up date. By that date, your return order will be picked up. We also notify you of the estimated refund date as soon as the Return ID is generated.",
    },
    {
      question: "I have not received my refund amount. Please help!",
      answer:
        "Once the returned product has been picked up, you will be notified of the promised date of refund. Before this date, a refund should get initiated. Once initiated, it should reflect in your account based on your mode of payment.",
    },
    {
      question: "My area is not serviceable for return. How do I return my order?",
      answer:
        "We strive to service all pin codes in India for forward and reverse delivery. But don't worry, even if your pin code is not serviceable, you can still return the product by shipping it to us. In some cases, you can also drop the return item at one of our Trends stores. Please login and select the relevant order for more details.",
    },
    {
      question: "Why is my return put on hold?",
      answer:
        "That's just because we need some additional information. A return request can be on hold or pending verification if our system doesn’t recognize the EAN entered by you while creating a return. Don't worry, typically in such cases, we call you within 48 hours to check and assist accordingly.",
    },
    {
      question: "When will the return order be picked?",
      answer:
        "Once the Return ID is generated, we share a promised pick-up date. By that date, your return order will be picked up. We also notify you of the estimated refund date as soon as the Return ID is generated.",
    },
  ];

  

export default Faq;
