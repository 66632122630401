import { Box, Image, Text, Button, Skeleton } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const HomeSingleProduct = (el) => {
  const { isLoading } = useSelector((store) => store.AppReducer);
  const { id = "", productimage = "", product_name = "" } = el;
  // const discount = (((product_price - discounted_price) / product_price) * 100).toFixed(0);
  const navigate = useNavigate();
  return (
    <>
      {productimage != null ? (
        <Skeleton isLoaded={!isLoading}>
          <Box
            borderWidth="1px"
            borderRadius="md"
            overflow="hidden"
            boxShadow="md"
            position="relative"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Image
              src={productimage.image_url}
              alt={product_name}
              objectFit="cover"
              onClick={() => navigate(`../fetchProductDetails/${id}`)}
            />
            <Box p="4">
              <Text
                m={"2px 0px"}
                fontWeight="400"
                color={"#53575f"}
                fontSize="14px"
                isTruncated
              >
                {product_name}
              </Text>
              {/* <div className={styles.prc}>
                <p>₹ {discounted_price}</p>
                <p>
                    <span>₹ </span>
                    {product_price}
                </p>
                <p>{`(${discount}% OFF)`}</p>
            </div> */}
              <Button
                colorScheme="red"
                width="100%"
                size={"sm"}
                mt={2}
                onClick={() => navigate(`../fetchProductDetails/${id}`)}
              >
                Shop Now
              </Button>
            </Box>
          </Box>
        </Skeleton>
      ) : (
        <></>
      )}
    </>
  );
};
export default HomeSingleProduct;
