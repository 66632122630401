import {
  Box,
  Center,
  Heading,
  VStack,
  Spinner,
  Text,
  Icon,
  Input,
  InputLeftAddon,
  InputGroup,
  FormControl,
  FormLabel,
  FormHelperText,
  Link as ChakraLink,
  Button,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { EmailIcon, LockIcon, PhoneIcon } from "@chakra-ui/icons";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../Redux/AuthReducer/Action";
import { resetRegisterPhaseStatus } from "../Redux/AuthReducer/Action";
import Navbar2 from "../Components/Navbar2";
import showCustomToast from "../Components/showCustomToast";

const Signup = () => {
  const navigate = useNavigate();
  const { isAuth, isLoading, isSignUpErrorMessage, registerPhaseStatus } =
    useSelector((store) => store.AuthReducer);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isMobileError, setIsMobileError] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const dispatch = useDispatch();
  const handleInputChange = (e, field) => {
    const value = e.target.value;

    switch (field) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "mobile":
        setMobile(value);
        break;
      case "name":
        setName(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email.trim() === "" || !email.includes("@")) {
      setIsEmailError(true);
      return;
    } else {
      setIsEmailError(false);
    }

    if (password.trim() === "") {
      setIsPasswordError(true);
      return;
    } else {
      setIsPasswordError(false);
    }

    if (mobile.length !== 10 || isNaN(mobile)) {
      setIsMobileError(true);
      return;
    } else {
      setIsMobileError(false);
    }

    if (name.trim() === "") {
      setIsNameError(true);
      return;
    } else {
      setIsNameError(false);
    }
    const params = {
      email: email,
      password: password,
      mobile: mobile,
      name: name,
      register_type: "email",
      token: "123456",
    };
    try {
      dispatch(register(params));
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (isSignUpErrorMessage && !isAuth) {
      showCustomToast("Sign failed", "error");
    } else if (registerPhaseStatus && !isAuth) {
      dispatch({ type: "USER_PHONE_NUMBER", payload: mobile });
      navigate("/otp");
    }
    //eslint-disable-next-line
  }, [isSignUpErrorMessage, registerPhaseStatus]);

  useEffect(() => {
    dispatch(resetRegisterPhaseStatus());
    localStorage.setItem("isAuth", false);
    localStorage.setItem("userData", JSON.stringify([]));
  }, [dispatch]);

  return (
    <>
      {isLoading && (
        <Box
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={9999}
          backgroundColor="rgba(0, 0, 0, 0.5)"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="xl" color="#ffffff" />
        </Box>
      )}
      <Box>
        <Navbar2 />
      </Box>
      <Box>
        <Center
          w={"full"}
          bgColor={window.innerWidth > 500 ? "#fceeea" : "#d3d3d361"}
          h={"100vh"}
        >
          <VStack w={"420px"} spacing="0">
            <Box w={"100%"} p={"40px 30px 10px 30px"} bgColor="white">
              <FormControl>
                <FormLabel>
                  <Heading
                    fontWeight={"600"}
                    as={"h2"}
                    color="#424553"
                    fontSize="24px"
                    size="lg"
                  >
                    Signup
                  </Heading>
                </FormLabel>
                <InputGroup mt={2}>
                  <InputLeftAddon
                    p="15px 10px"
                    children={<FaUser color="#718096" />}
                  />
                  <Input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => handleInputChange(e, "name")}
                  />
                </InputGroup>

                {isNameError && (
                  <FormHelperText fontSize={12}>
                    Please enter your name.
                  </FormHelperText>
                )}
                <InputGroup mt={2}>
                  <InputLeftAddon p="15px 10px">
                    <Icon as={EmailIcon} color="gray.500" boxSize={4} />
                  </InputLeftAddon>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => handleInputChange(e, "email")}
                  />
                </InputGroup>

                {isEmailError && (
                  <FormHelperText fontSize={12}>
                    Please enter a valid email address.
                  </FormHelperText>
                )}

                <InputGroup mt={2}>
                  <InputLeftAddon p="15px 10px">
                    <Icon as={PhoneIcon} color="gray.500" boxSize={4} />
                  </InputLeftAddon>
                  <Input
                    type="tel"
                    maxLength={"10"}
                    placeholder="Mobile Number"
                    value={mobile}
                    onChange={(e) => handleInputChange(e, "mobile")}
                  />
                </InputGroup>

                {isMobileError && (
                  <FormHelperText fontSize={12}>
                    Please enter a valid mobile number (10 digits).
                  </FormHelperText>
                )}

                <InputGroup mt={2}>
                  <InputLeftAddon p="15px 10px">
                    <Icon as={LockIcon} color="gray.500" boxSize={4} />
                  </InputLeftAddon>
                  <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => handleInputChange(e, "password")}
                  />
                </InputGroup>

                {isPasswordError && (
                  <FormHelperText fontSize={12}>
                    Please enter a password.
                  </FormHelperText>
                )}

                <FormHelperText
                  mt={8}
                  color={"#a7a9af"}
                  textAlign="left"
                  fontSize={"12px"}
                >
                  By continuing, I agree to the&nbsp;
                  <ChakraLink
                    fontWeight={"bold"}
                    onClick={() => {
                      navigate("/terms");
                    }}
                    _hover={{ textDecoration: "none" }}
                    color={window.innerWidth > 500 ? "#ff3f6c" : "black"}
                  >
                    Terms of Use&nbsp;
                  </ChakraLink>
                  &amp;&nbsp;
                  <ChakraLink
                    onClick={() => {
                      navigate("/privacy");
                    }}
                    fontWeight={"bold"}
                    _hover={{ textDecoration: "none" }}
                    color={window.innerWidth > 500 ? "#ff3f6c" : "black"}
                  >
                    Privacy Policy&nbsp;
                  </ChakraLink>
                </FormHelperText>
                <Button
                  w={"100%"}
                  mt={8}
                  mb={4}
                  variant="solid"
                  backgroundColor={
                    window.innerWidth > 500 ? "#ff3f6c" : "black"
                  }
                  color={"#fff"}
                  borderRadius="0"
                  colorScheme="none"
                  type="submit"
                  onClick={handleSubmit}
                >
                  CONTINUE
                </Button>
              </FormControl>
              <Text
                mb={10}
                color={"#a7a9af"}
                textAlign="left"
                fontSize={"13px"}
              >
                Already have a account ?{" "}
                <Text
                  as={"span"}
                  cursor={"pointer"}
                  onClick={() => navigate("/login")}
                  fontWeight={"bold"}
                  _hover={{ textDecoration: "none" }}
                  color={"#ff3f6c"}
                  href="#"
                >
                  Login
                </Text>
              </Text>
            </Box>
          </VStack>
        </Center>
      </Box>
    </>
  );
};

export default Signup;
