import * as types from "./ActionTypes";

const initialState = {
  OrderList: [],
  OrderInfo: {},
  OrderSelected: {},
  isLoading: false,
  isError: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // isLoading state...................

    case types.GET_ORDERDETAIL_REQUEST:
      //console.log("rendering:", state);
      return {
        ...state,
        OrderList: [],
        isLoading: true,
      };

    case types.GET_ORDERDETAIL_SUCCESS:
      console.log("orders list:", payload.order_data);
      return {
        ...state,
        OrderInfo: { ...payload.order_info },
        OrderList: payload.order_data,
        OrderSelected: payload.order_data[0],
        isLoading: false,
      };

    case types.GET_ORDERDETAIL_FAILURE:
      //console.log("rendering:", state);
      return {
        ...state,
        OrderDetails: [],
        isLoading: false,
      };

    case types.UPDATE_SELECTED_ORDER:
      //console.log("rendering:", state);
      return {
        ...state,
        OrderSelected: payload,
        isLoading: false,
      };

    // ...............................
    // default case

    default:
      return state;
  }

  // ................................
};

export { reducer };