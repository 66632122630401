export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_HEADING = "GET_PRODUCTS_HEADING";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";
export const GET_TOTAL = "GET_TOTAL";
export const GET_STATE_CLEAR = "GET_STATE_CLEAR";
export const UPDATE_WISHLIST_SUCCESS = "UPDATE_WISHLIST_SUCCESS";

export const GET_PRODUCTSDETAIL_REQUEST = "GET_PRODUCTSDETAIL_REQUEST";
export const GET_PRODUCTSDETAIL_SUCCESS = "GET_PRODUCTSDETAIL_SUCCESS";
export const GET_PRODUCTSDETAIL_FAILURE = "GET_PRODUCTSDETAIL_FAILURE";

export const GET_NAVDATA_SUCCESS = "GET_NAVDATA_SUCCESS";
