import { Box, Icon } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { useEffect } from 'react';

const RatingStar = ({ initialRating = 0, maxRating = 5, onUpdate,edit=true }) => {
  const [rating, setRating] = useState(initialRating);


  useEffect(()=>{
    setRating(initialRating);
  },[initialRating])

  const handleRatingClick = (newRating) => {
    if(edit === false) {

    }
    else {
      setRating(newRating);
      onUpdate(newRating);
    }

  };

  return (
    <Box>
      {[...Array(maxRating)].map((_, index) => (
        <Icon
          key={index}
          as={StarIcon}
          w={5}
          h={5}
          color={index < rating ? 'yellow.400' : 'gray.300'}
          cursor="pointer"
          onClick={() => handleRatingClick(index + 1)}
        />
      ))}
    </Box>
  );
};
export default RatingStar;