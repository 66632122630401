import {
  Divider,
  ModalBody,
  ModalFooter,
  FormControl,
  Input,
  VStack,
  HStack,
  FormLabel,
  Button,
  Select,
} from "@chakra-ui/react";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAddress, editAddress } from "../Redux/CheckoutReducer/Action";

import showCustomToast from "./showCustomToast";
import statesAndCities from "../Constants/states.json";
const AddressModal = ({
  onClose,
  setAddress,
  selectedAddress = {},
  type = "add",
  addressPage,
}) => {
  const dispatch = useDispatch();
  const [addressType, setAddressType] = useState("");
  const { userData } = useSelector((store) => store.AuthReducer);
  const [addressD, setAddressD] = useState({});
  const handleAddressType = (type) => {
    setAddressType(type);
  };

  const handleStateChange = (event) => {
    // setSelectedState(event.target.value);
    // setSelectedCity("");
    setAddressD({ ...addressD, state: event.target.value, city: "" });
    // setAddressD({ ...addressD, city: ''})
  };

  const handleCityChange = (event) => {
    if (!addressD.state) {
      alert("Please select a state first.");
      setAddressD({ ...addressD, city: "" });
    } else {
      setAddressD({ ...addressD, city: event.target.value });
    }
  };

  useEffect(() => {
    if (type === "edit") {
      // console.log("address page", selectedAddress);
      setAddressD(selectedAddress);
      setAddressType(selectedAddress.type);
    }
  }, [type, selectedAddress]);

  const handleAddress = () => {
    const {
      first_name,
      mobile,
      pincode,
      street_address,
      landmark,
      city,
      state,
    } = addressD;

    console.log("check addressD: ",addressD);
    // eslint-disable-next-line
    if (
      first_name &&
      mobile &&
      addressType  &&
      pincode &&
      street_address &&
      landmark &&
      city &&
      state !== "" &&
      // +mobile === mobile &&
      // // eslint-disable-next-line
      // first_name != +first_name &&
      // pincode === +pincode &&
      // street_address !== +street_address &&
      // landmark !== +landmark &&
      // city !== +city &&
      // // eslint-disable-next-line
      // state != +state &&
      first_name.length > 2 &&
      mobile.length === 10 &&
      pincode.length === 6
    ) {
      const params = {
        user_id: userData.id,
        first_name: first_name,
        mobile: mobile,
        last_name: first_name,
        pincode: pincode,
        street_address: street_address,
        landmark: landmark,
        city: city,
        state: state,
        type: addressType,
        addressPage: addressPage,
      };

      //eslint-disable-next-line
      if (type == "add") {
        dispatch(addAddress(params));
      }
      //eslint-disable-next-line
      else if (type == "edit") {
        const params = {
          ...addressD,
          address_id: addressD.id,
          last_name: addressD.first_name,
          type: addressType,
        };
        dispatch(editAddress(params));
      }
      onClose();
    } else {
      // alert("check for validation");
      showCustomToast("Check if all mandatory feilds are filled ", "error");
    }
  };

  return (
    <FormControl isRequired={true}>
      <ModalBody>
        <Divider />

        <VStack>
          <VStack w={"full"} align="flex-start">
            <FormLabel htmlFor="Contact Details">CONTACT DETAILS</FormLabel>
            <Input
              type="text"
              maxLength={15}
              value={addressD.first_name}
              onChange={(e) =>
                setAddressD({ ...addressD, first_name: e.target.value })
              }
              focusBorderColor="grey"
              placeholder="Name*"
            />
            <Input
              type={"text"}
              minLength={10}
              maxLength={10}
              value={addressD.mobile}
              onChange={(e) =>
                setAddressD({ ...addressD, mobile: e.target.value })
              }
              focusBorderColor="grey"
              placeholder="Mobile No*"
            />
          </VStack>
          <VStack w={"full"} align="flex-start">
            <FormLabel>ADDRESS</FormLabel>
            {/* <Input type={"text"} maxLength={6} value={addressD.pincode}
              onChange={(e) => setAddressD({ ...addressD, pincode: e.target.value })}
              focusBorderColor="grey" placeholder='Pin Code*'></Input> */}

            <Input
              type={"text"}
              maxLength={40}
              value={addressD.street_address}
              onChange={(e) =>
                setAddressD({ ...addressD, street_address: e.target.value })
              }
              focusBorderColor="grey"
              placeholder="Address (House No,Building,Street Area)*"
            ></Input>
            <Input
              type={"text"}
              maxLength={40}
              value={addressD.landmark}
              onChange={(e) =>
                setAddressD({ ...addressD, landmark: e.target.value })
              }
              focusBorderColor="grey"
              placeholder="Locality/Town*"
            ></Input>
            <Select
              placeholder="Select a state"
              value={addressD.state}
              onChange={handleStateChange}
            >
              {Object.keys(statesAndCities).map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Select>
            {/* City Dropdown */}
            {addressD.state && (
              <Select
                value={addressD.city}
                onChange={handleCityChange}
                placeholder="Select a city"
              >
                {statesAndCities[addressD.state].map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
              </Select>
            )}
            <Input
              type={"text"}
              maxLength={6}
              value={addressD.pincode}
              onChange={(e) =>
                setAddressD({ ...addressD, pincode: e.target.value })
              }
              focusBorderColor="grey"
              placeholder="Pin Code*"
            ></Input>
            {/* <HStack>
              <Input type={"text"} maxLength={20} value={addressD.city} onChange={(e) => setAddressD({ ...addressD, city: e.target.value })} focusBorderColor="grey" placeholder='City/District*'></Input>
              <Input type={"text"} maxLength={20} value={addressD.state} onChange={(e) => setAddressD({ ...addressD, state: e.target.value })} focusBorderColor="grey" placeholder='State*'></Input>
            </HStack> */}
            <FormLabel>TYPE OF ADDRESS</FormLabel>

            <HStack spacing={"15px"}>
              <Button
                variant={addressType === "home" ? "solid" : "outline"}
                onClick={() => handleAddressType("home")}
                borderRadius={2}
                px={4}
                py={3}
                size={"xs"}
                colorScheme={"red"}
              >
                Home
              </Button>
              <Button
                variant={addressType === "work" ? "solid" : "outline"}
                onClick={() => handleAddressType("work")}
                borderRadius={2}
                px={4}
                py={3}
                size={"xs"}
                colorScheme={"red"}
              >
                Work
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Button
          colorScheme={"red"}
          variant="outline"
          w="full"
          onClick={handleAddress}
        >
          Submit Address
        </Button>
      </ModalFooter>
    </FormControl>
  );
};

export default AddressModal;
