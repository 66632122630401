import * as types from "./ActionTypes";

const initialState = {
  WishList: [],
  isLoading: false,

};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // isLoading state...................




    case types.ADD_WISHLIST:
        return {
          ...state,
          WishList:payload,
          isLoading: false,

        };

      case types.ADD_WISHLIST_COUNT:
        return {
          ...state,
          WishList:payload.length,
          isLoading: false,

        };


    // ...............................
    // default case

    default:
      return state;
  }

  // ................................
};



export { reducer };
