import {
  Box,
  HStack,
  SimpleGrid,
  Text,
  Image,
  Spinner,
  Center,
  VStack,
  Show,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import Footer from "../Components/Footer";

import SingleWishlistProduct from "../Components/SingleWishlistProduct";
import { useDispatch, useSelector } from "react-redux";
import Navbar2 from "../Components/Navbar2";
import { useNavigate } from "react-router";
import { getWishlistCountAsync } from "../Redux/NavBarhelperdataslice";

const Wishlist = () => {
  const [wishlist, setWishlist] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const [isSpinner, setIsSpinner] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector((store) => store.AuthReducer);

  const getWishlistProd = useCallback(() => {
    axios({
      method: "post",
      url: process.env.REACT_APP_API + "/getwishlist",
      data: {
        user_id: userData.id,
      },
      withCredentials: false,
    })
      .then((res) => {
        setWishlist(res.data.data);

        dispatch({ type: "ADD_WISHLIST", payload: res.data.data });
        // dispatch(addWishListCount(res.data.data))
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
    //eslint-disable-next-line
  }, [userData.id]);

  useEffect(() => {
    setIsLoading(true);
    getWishlistProd();
  }, [getWishlistProd]);

  const handleDelete = (id) => {
    setIsSpinner(true);
    const params = {
      product_id: id,
      user_id: userData.id,
    };

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/removefromwishlist`,
      data: params,
    })
      .then((res) => {
        getWishlistProd();
        dispatch(getWishlistCountAsync(params));
        setIsSpinner(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsSpinner(false);
      });
  };

  const handleAddCart = (el) => {
    navigate(`../fetchProductDetails/${el.id}`);
  };

  return (
    <>
      <Navbar2 />

      <Box w="full">
        <HStack spacing="5px">
          <Text fontSize="18px" fontWeight={500} color="#282c3f">
            My Wishlist
          </Text>
          <Text fontSize="17px" fontWeight={400} color="#b2b4b9">
            {wishlist.length} items
          </Text>
        </HStack>
        {isLoading ? (
          <Box
            position="absolute"
            top="0"
            left="0"
            w="full"
            h="full"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bg="rgba(255, 255, 255, 0.8)"
            zIndex="9999"
          >
            <Spinner size="lg" color="gray.500" />
          </Box>
        ) : wishlist.length === 0 ? (
          // <Box w="full" textAlign="center" mt={8}>
          //   <Text fontSize="18px" fontWeight={500} color="#282c3f">
          //     No items found in the wishlist.
          //   </Text>
          // </Box>
          <SimpleGrid
            columns={1}
            spacingY="60px"
            spacingX="30px"
            marginTop={"30px"}
            w="100%"
          >
            <Center>
              <VStack>
                <Image
                  w="100px"
                  src="https://constant.myntassets.com/checkout/assets/img/empty-bag.webp"
                />
                <Text fontWeight={700} fontSize="20px" color={"424553"}>
                  Ohh!
                </Text>
                <Text
                  fontSize={"14px"}
                  pb="20px"
                  fontWeight={400}
                  color="#7e818c"
                >
                  No products added in your wishlist !
                </Text>
              </VStack>
            </Center>
          </SimpleGrid>
        ) : (
          <Box w={"full"} h={"full"}>
            <SimpleGrid
              h="full"
              columns={{ base: 2, md: 4, lg: 4 }}
              mt={8}
              mb={8}
            >
              {wishlist?.map((el) => (
                <SingleWishlistProduct
                  key={el.id}
                  el={el}
                  handleAddCart={handleAddCart}
                  handleDelete={handleDelete}
                  isLoading={isSpinner}
                />
              ))}
            </SimpleGrid>
          </Box>
        )}
      </Box>
      <Show breakpoint="(min-width: 500px)">
        <Footer />
      </Show>
    </>
  );
};

export default Wishlist;
