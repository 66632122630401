import { Box, Image, Button, Text, SimpleGrid, Show, Skeleton } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const SecondaryBanner = ({ aspectRatio, data, mr, type, heading, isLoading }) => {
  const navigate = useNavigate();

  if (data.length < 2) {
    return null;
  }

  return (
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 2 }}
      spacing={1}
      position={"relative"}
    >
      <Box aspectRatio={aspectRatio} position="relative">
        <Skeleton isLoaded={!isLoading} height="100%">
          <Image
            src={data[0].image_url}
            alt="First Image"
            objectFit="cover"
            w="100%"
            h="100%"
            onClick={
              type === "CATEGORY"
                ? () => navigate(`/store?type=${data[0].cat_id}`)
                : type === "BEST_SELLER"
                ? () => (window.location.href = data[0].link)
                : () => (window.location.href = data[0].link)
            }
          />
        </Skeleton>
        {type === "CATEGORY" && (
          <div
            style={{
              width: "100%",
              position: "absolute",
              display: "flex",
              bottom: "4%",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Button
              colorScheme={window.innerWidth > 500 ? "gray" : "red"}
              color={window.innerWidth > 500 ? "black" : "white"}
              aspectRatio={1}
              w={"35%"}
              fontSize={["xs", "sm", "md", "lg"]} // Responsive font size
              onClick={() => navigate(`/store?type=${data[0].cat_id}`)}
            >
              <Text isTruncated>{data[0].category_name}</Text>
            </Button>
          </div>
        )}
      </Box>

      <Box aspectRatio={1} position="relative">
        <Skeleton isLoaded={!isLoading} height="100%">
          <Image
            src={data[1].image_url}
            alt="Second Image"
            objectFit="cover"
            w="100%"
            h="100%"
            onClick={
              type === "CATEGORY"
                ? () => navigate(`/store?type=${data[1].cat_id}`)
                : type === "BEST_SELLER"
                ? () => (window.location.href = data[1].link)
                : () => (window.location.href = data[1].link)
            }
          />
        </Skeleton>
        {type === "CATEGORY" && (
          <div
            style={{
              width: "100%",
              position: "absolute",
              display: "flex",
              bottom: "4%",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <Button
              colorScheme={window.innerWidth > 500 ? "gray" : "red"}
              color={window.innerWidth > 500 ? "black" : "white"}
              aspectRatio={1}
              w={"35%"}
              fontSize={["xs", "sm", "md", "lg"]} // Responsive font size
              onClick={() => navigate(`/store?type=${data[1].cat_id}`)}
            >
              <Text isTruncated>{data[1].category_name}</Text>
            </Button>
          </div>
        )}
      </Box>

      <Show breakpoint="(max-width: 500px)">
        {(type === "BEST_SELLER" || type === "LATEST") && (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            textAlign="center"
            width="55%"
          >
            <Box px={2} py={1}>
              <Image src={heading} />
            </Box>
          </Box>
        )}
      </Show>

      <Show breakpoint="(min-width: 500px)">
        {(type === "BEST_SELLER" || type === "LATEST") && (
          <Box
            position="absolute"
            top="45%"
            left="50%"
            transform="translate(-50%, -50%)"
            textAlign="center"
            width="30%"
            onClick={() => (window.location.href = data[1].link)}
          >
            <Box borderRadius="md" px={2} py={1}>
              <Image src={heading} />
            </Box>
          </Box>
        )}
      </Show>
    </SimpleGrid>
  );
};

export default SecondaryBanner;
