import { Box, Skeleton } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

const VideoBanner = ({ data, isLoading }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (!isLoading) {
      const playPromise = videoRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Autoplay started
          })
          .catch((error) => {
            // Autoplay was prevented
            console.error('Autoplay prevented: ', error);
          });
      }
    }
  }, [isLoading]);

  return (
    <Box mt={2} onClick={() => (window.location.href = data.link)}>
      {isLoading ? (
        <Skeleton height="100%" width="100%" />
      ) : (
        <video
          ref={videoRef}
          width="100%"
          height="100%"
          autoPlay
          loop
          playsInline
          muted // Ensure video is muted for autoplay on mobile
          style={{ objectFit: 'cover' }}
          poster=""
        >
          <source src={data.image_url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </Box>
  );
};

export default VideoBanner;
