import React from 'react';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import Navbar2 from '../Components/Navbar2';

const Contact = () => {
  return (
    <>
    <Navbar2/>
    <Box py={8} bg="#fff">
      <Container maxW="container.md">
        <Heading as="h2" textAlign="center" color={window.innerWidth > 500 ? "#ff3f6c" : 'black'} mb={4}>
          Contact Us
        </Heading>
        <Box bg="#f9f9f9" p={4} borderRadius="md" boxShadow="md" mb={4}>
          <Text fontWeight="bold" fontSize="lg" mb={2}>
            Phone Number:
          </Text>
          <Text>+91 9875982194</Text>
        </Box>
        <Box bg="#f9f9f9" p={4} borderRadius="md" boxShadow="md" mb={4}>
          <Text fontWeight="bold" fontSize="lg" mb={2}>
            Email Address:
          </Text>
          <Text>customercare@98degreenorth.com</Text>
        </Box>
        <Box bg="#f9f9f9" p={4} borderRadius="md" boxShadow="md" mb={4}>
          <Text fontWeight="bold" fontSize="lg" mb={2}>
            Address:
          </Text>
          <Text>
          M/S K.K.Fabrics 1740/27-c, Balloke Road , Haibowal Kalan , Ludhiana-141001,Punjab
          </Text>
        </Box>
        <Box bg="#f9f9f9" p={4} borderRadius="md" boxShadow="md" mb={4}>
          <Text fontWeight="bold" fontSize="lg" mb={2}>
          Business Hours:
          </Text>
          <Text>
          10AM to 6PM, Enquiries will be answered during business Hours
          </Text>
        </Box>
      </Container>
    </Box>
    </>
  );
};

export default Contact;
