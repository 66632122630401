import * as types from "./ActionTypes.js";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCartCountAsync } from "../NavBarhelperdataslice";

// ...............isLoading state

export const getCartLoading = () => {
  return {
    type: types.GET_CART_REQUEST,
  };
};

// .................................

// ...................Success state

export const getCartSuccess = (payload) => {
  return {
    type: types.GET_CART_SUCCESS,
    payload,
  };
};

export const deleteCartSuccess = (payload) => {
  return {
    type: types.DELETE_CART_SUCCESS,
    payload,
  };
};
// .................................

// .................isError state

export const getCartFailure = () => {
  return {
    type: types.GET_CART_FAILURE,
  };
};

export const showToast = (message) => ({
  type: "SHOW_TOAST",
  payload: message,
});
// .......................

export const addToCart = (params, successCallback) => async (dispatch) => {
  dispatch(getCartLoading());
  // console.log("before api is hit", params);
  //return;
  try {
    await axios.post(`${process.env.REACT_APP_API}/addtocart`, params);
    showSuccessToast("Item added to cart!!!");
    dispatch({ type: types.ADD_CART_SUCCESS, payload: params });
    dispatch(getCart(params));
    dispatch(getCartCountAsync(params));
    successCallback(true);
    // dispatch(showToast('Item added to cart!!'));
  } catch (error) {
    showErrorToast(error.response.data.message, () => {
      dispatch(getCartFailure());
    });
  }
};

export const updateCart = (params) => async (dispatch) => {
  // dispatch(getCartLoading());

  const new_params = {
    id: params.cart_id,
    qty: params.qty,
  };
  // console.log("before api is hit", params);
  //return;
  try {
    await axios.post(`${process.env.REACT_APP_API}/qtyupdate`, params);
    // console.log("after  api is hit", response.data);
    //  showSuccessToast('Item updated to cart!!!');
    dispatch({ type: types.UPDATE_CART_SUCCESS, payload: new_params });
    // dispatch(showToast('Item added to cart!!'));
  } catch (error) {
    showErrorToast(error.response.data.message, () => {
      dispatch(getCartFailure());
    });
  }
};

export const updateCartItemSize = (params, Cart) => async (dispatch) => {
  // alert("jj");
  // dispatch(getCartLoading());
  const updatedCart = Cart.map((item) => {
    if (item.id === params.id) {
      return { ...item, variation: params.variation };
    }
    return item;
  });

  dispatch({ type: types.UPDATE_CART_ITEM_SIZE, payload: updatedCart });
};

export const updateCartIfSizeExits =
  (params, updateCart) => async (dispatch) => {
    // dispatch(getCartLoading());
    dispatch(deleteCartSuccess(updateCart));

    // dispatch({ type: types.UPDATE_CART_IF_SIZE_EXISTS, payload:updatedCarts});
    try {
      await axios.post(`${process.env.REACT_APP_API}/deleteproduct`, params);
      // console.log("after  api is hit", response.data);
      //  showSuccessToast('Item updated to cart!!!');

      // dispatch(showToast('Item added to cart!!'));
    } catch (error) {
      dispatch(getCartFailure());
      // showErrorToast(error.response.data.message, () => {
      //   dispatch(getCartFailure());
      // });
    }
  };

export const deleteCart = (params, updateCart) => async (dispatch) => {
  dispatch(getCartLoading());

  dispatch(deleteCartSuccess(updateCart));

  try {
    await axios.post(`${process.env.REACT_APP_API}/deleteproduct`, params);
    // dispatch(getCart(params));
    dispatch(getCartCountAsync(params));

    // console.log("after  api is hit", response.data);
    //  showSuccessToast('Item updated to cart!!!');

    // dispatch(showToast('Item added to cart!!'));
  } catch (error) {
    dispatch(getCartFailure());
    // showErrorToast(error.response.data.message, () => {
    //   dispatch(getCartFailure());
    // });
  }
};
const showSuccessToast = (message) => {
  toast.success(message, {
    autoClose: 1000, // Customize the duration (in milliseconds)
  });
};
const showErrorToast = (message, onClose) => {
  toast.error(message, {
    autoClose: 1000, // Customize the duration (in milliseconds)
    onClose: onClose, // Pass the onClose callback
  });
};
// api call....Success/error/loading...........
export const getCart = (params) => async (dispatch) => {
  dispatch(getCartLoading());
  // console.log("get-cart",params);

  // return;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/getcart`,
      params
    );
    // console.log("after api is hit", response.data.data);

    dispatch(getCartSuccess(response.data.data));
  } catch (error) {
    dispatch(getCartFailure());
  }
};

//wishlist

// .......................
