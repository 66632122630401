import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore,
} from "redux";
import { reducer as AppReducer } from "./AppReducer/Reducer";
import { reducer as AuthReducer } from "./AuthReducer/Reducer";
import { reducer as CartReducer } from "./CartReducer/Reducer";
import { reducer as CheckoutReducer } from "./CheckoutReducer/Reducer";
import { reducer as WishListReducer } from "./WishListReducer/Reducer";
import { reducer as OrderDetailReducer } from "./OrderReducer/Reducer";
import NavBarhelperdataReducer from "./NavBarhelperdataslice";
import thunk from "redux-thunk";

const composeEnhancers = window._REDUX_DEVTOOLS_EXTENTION_COMPOSE_ || compose;

const rootReducer = combineReducers({
  AppReducer,
  AuthReducer,
  CartReducer,
  CheckoutReducer,
  WishListReducer,
  OrderDetailReducer,
  NavBarhelperdata: NavBarhelperdataReducer,
});

export const store = legacy_createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
