import React from "react";
import { Route, Routes } from "react-router-dom";
import Address from "../Pages/Address";
import Cart from "../Pages/Cart";
import Home from "../Pages/Home";
import Otp from "../Pages/Otp";
import Payment from "../Pages/Payment";
import PaymentSuccess from "../Pages/PaymentSuccess";
import PaymentCancel from "../Pages/PaymentCancel";
import Signup from "../Pages/Signup";
import SingleProduct from "../Pages/SingleProduct";
import Store from "../Pages/Store";
import Wishlist from "../Pages/Wishlist";
import { PrivateRoute, PrivateRoute2 } from "./PrivateRoutes";
import Login from "../Pages/Login";
import Orders from "../Pages/Orders";
import Profiles from "../Pages/Profiles";
import ProfilesMobile from "../Pages/Profiles_Mobile";
import OrderDetails from "../Pages/OrderDetails";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Terms from "../Pages/Terms";
import Contact from "../Pages/Contact";
import PasswordReset from "../Pages/PasswordReset";
import Faq from "../Pages/Faq";
import TermsCondition from "../Pages/TermsCondition";
//import { PrivateStore } from './PrivateRoutes'

export const MainRoutes = () => {
  return (
    // <HashRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/store" element={<Store />} />
      <Route path="/fetchProductDetails/:id" element={<SingleProduct />} />
      <Route
        path="/signup"
        element={
          <PrivateRoute2>
            <Signup />
          </PrivateRoute2>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgetPassword" element={<PasswordReset />} />
      <Route
        path="/otp"
        element={
          <PrivateRoute2>
            <Otp />
          </PrivateRoute2>
        }
      />
      <Route
        path="/wishlist"
        element={
          <PrivateRoute>
            <Wishlist />
          </PrivateRoute>
        }
      />
      <Route
        path="/orders"
        element={
          <PrivateRoute>
            <Orders />
          </PrivateRoute>
        }
      />
      <Route
        path="/profileSettings"
        element={
          <PrivateRoute>
            <Profiles />
          </PrivateRoute>
        }
      />
      <Route path="/profilesSettigsMob" element={<ProfilesMobile />} />
      <Route
        path="/cart"
        element={
          <PrivateRoute>
            <Cart />
          </PrivateRoute>
        }
      />
      {/* <Route path='/address' element={<PrivateRoute3><Address/></PrivateRoute3>}/> */}
      <Route
        path="/address"
        element={
          <PrivateRoute>
            <Address />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile/address"
        element={
          <PrivateRoute>
            <Address />
          </PrivateRoute>
        }
      />
      <Route
        path="/payment"
        element={
          <PrivateRoute>
            <Payment />
          </PrivateRoute>
        }
      />
      {/* <Route path="/paymentSuccess/:order_id/:bank_ref" element={<PrivateRoute><PaymentSuccess /></PrivateRoute>}/> */}
      <Route
        path="/paymentSuccess"
        element={
          <PrivateRoute>
            <PaymentSuccess />
          </PrivateRoute>
        }
      />
      <Route
        path="/paymentCancel"
        element={
          <PrivateRoute>
            <PaymentCancel />
          </PrivateRoute>
        }
      />
      <Route
        path="/orderDetails/:orderNumber/:id"
        element={
          <PrivateRoute>
            <OrderDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/order"
        element={
          <PrivateRoute>
            <Orders />
          </PrivateRoute>
        }
      />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/termscondition" element={<TermsCondition />} />
    </Routes>
    // </HashRouter>
  );
};
