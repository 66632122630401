
import { Box, Image, VStack, Circle, HStack, useDisclosure,Text, Spinner, Button, Modal, ModalOverlay, ModalCloseButton, ModalContent } from '@chakra-ui/react';
import React from 'react';
import SizeSelectModal from './SizeSelectModal';
import styles from '../css/Wishlist.module.css'

const SingleWishlistProduct = ({ el, handleAddCart, handleDelete, isLoading }) => {
  const {  productimages, discounted_price, product_name } = el;
  const { isOpen, onOpen, onClose } = useDisclosure()



  return (
    <Box
      border="1px solid #e8ecf3"
      w="full"
      mb={2}
      borderRadius={10}
      cursor={'pointer'}
      position="relative"
      filter={isLoading ? 'blur(2px)' : 'none'}
      pointerEvents={isLoading ? 'none' : 'auto'}
    >

      {isLoading && (
        <Box
          position="absolute"
          top="0"
          left="0"
          w="full"
          h="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bg="rgba(255, 255, 255, 0.8)"
          zIndex="9999"
        >
          <Spinner size="lg" color="gray.500" />
        </Box>
      )}

      <Box w="full" position="relative">
        <Circle
            onClick={() => handleDelete(el.id)}
            bgColor="#eeeded"
            p="4px 8px"
            fontSize="12px"
            cursor="pointer"
            position="absolute"
            top="4px"
            right="4px"
            zIndex={'1'}
          >

            &#x2718;
          </Circle>
          <Image onClick={() => handleAddCart(el)} w="full" h="full" src={productimages[0].image_url} borderRadius={10}/>

      </Box>
      <VStack w="full" padding="10px" minHeight={'90px'} spacing={0}  alignItems={'flex-start'}>
        <Text w="full" m="2px 0px" fontWeight={500} className={window.innerWidth > 500 ? styles.prc_name : styles.prc_name_mob}>
          {product_name}
        </Text>
        <HStack>
          <Text  className={window.innerWidth > 500 ? styles.prc : styles.prc_mob}>
            ₹ {discounted_price}
          </Text>
        </HStack>
      </VStack>
      <Button
        onClick={() => { onOpen(); }}
        fontWeight={500}
        position={'relative'}
        bottom={'0px'}
        bg={window.innerWidth > 500 ? "" : "black"}
        color={window.innerWidth > 500 ? "#ff3f6c" : "white"}
        w="full"
        borderRadius="0"
        borderTop="1px solid #e8ecf3"
        variant="unstyled"
        _hover={{ bgColor: 'none' }}
      >
        MOVE TO BAG
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} colorScheme="pink">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton color={"#535766"} fontWeight={"bold"} />

          <SizeSelectModal
            onClose={onClose}
            handleDelete={handleDelete}
            item={el}
          />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SingleWishlistProduct;


