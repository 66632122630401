// import React, { useState } from "react";
// import styles from "../css/Order.module.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faTimes,
//   faCheck,
//   faTruck,
//   faX,
//   faHourglass,
// } from "@fortawesome/free-solid-svg-icons";
// import { ChevronRightIcon, EditIcon } from "@chakra-ui/icons";
// import ConfirmationModal from "../Components/ConfirmationModal";
// import RatingStar from "../Components/RatingStar";
// import {
//   Text,
//   Menu,
//   MenuButton,
//   MenuList,
//   MenuItem,
//   Button,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalBody,
//   ModalCloseButton,
//   FormControl,
//   FormLabel,
//   Textarea,
//   ModalFooter,
//   Heading,
//   Card,
//   CardBody,
//   Image,
//   Stack,
//   Box,
//   Show,
//   Grid,
//   GridItem,
//   Icon,
//   HStack,
// } from "@chakra-ui/react";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import showCustomToast from "./showCustomToast";
// import { useNavigate } from "react-router-dom";

// const Order = ({ ordersList, onUpdate }) => {
//   const navigate = useNavigate();
//   const { userData } = useSelector((store) => store.AuthReducer);
//   const [isRateModalOpen, setIsRateModalOpen] = useState(false);
//   const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
//   const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
//   const [confirmationModalText, setConfirmationModalText] = useState("");
//   const [rating, setRating] = useState(0);
//   const [comment, setComment] = useState("");
//   const [clickedOrder, setClickedOrder] = useState({});
//   const [returnReason, setReturnReason] = useState("");

//    console.log("order",ordersList);

//   const isRatingGiven = (order) => {
//     if (order.ratting == null && order.comment == null) {
//       return false;
//     }
//     return true;
//   };
//   const showRatingOption = (order) => {
//     //eslint-disable-next-line
//     if (order.status == 4 && !isRatingGiven(order)) {
//       return true;
//     }
//     return false;
//   };
//   const showMoreDetailsButton = (order) => {
//     if (
//       !showRatingOption(order) &&
//       !order.cancel_staus &&
//       !order.return_status
//     ) {
//       return false;
//     }
//     return true;
//   };
//   const openRateModal = (order) => {

//     setClickedOrder(order);
//     setIsRateModalOpen(true);
//   };
//   const openReturnModal = (order) => {
//     setClickedOrder(order);
//     setIsReturnModalOpen(true);
//   };
//   const closeRateModal = () => {
//     setIsRateModalOpen(false);
//     setComment("");
//     setRating(0);
//   };
//   const closeReturnModal = () => {
//     setIsReturnModalOpen(false);
//     setReturnReason("");
//   };

//   const openConfirmationModal = (text, status, order) => {
//     setConfirmationModalText(text);
//     setClickedOrder(order);
//     setIsConfirmationModalOpen(true);
//   };

//   const closeConfirmationModal = () => {
//     setIsConfirmationModalOpen(false);
//   };
//   const handleConfirmationDialogYes = (confirm, order) => {
//     if (confirm) {
//       cancelReturnOrderAPICall(order.id, 6); //6 canclled,
//     }
//   };
//   const cancelReturnOrderAPICall = (orderId, status) => {
//     axios({
//       method: "post",
//       url: process.env.REACT_APP_API + "/cancelorder",
//       data: { user_id: userData.id, status: status, order_id: orderId },
//       withCredentials: false,
//     })
//       .then((_) => {
//         showCustomToast("Order Cancelled", "success");

//         onUpdate();
//       })
//       .catch((err) => {
//         // console.log(err);
//       });
//   };
//   const submitRatingAPICall = () => {

//     //eslint-disable-next-line
//     if (rating != null) {
//       axios({
//         method: "post",
//         url: process.env.REACT_APP_API + "/addratting",
//         data: {
//           user_id: userData.id,
//           product_id: clickedOrder.product_id,
//           ratting: rating,
//           comment: comment,
//         },
//         withCredentials: false,
//       })
//         .then((_) => {
//           closeRateModal();
//           showCustomToast("Thanks for your feedback", "success");

//           onUpdate();
//         })
//         .catch((err) => {
//           closeRateModal();
//           // console.log(err);
//         });
//     } else {
//       showCustomToast("Please fill the stars","error");

//     }
//   };
//   const submitRatingMobileAPICall = (newRating,order) => {
//     //eslint-disable-next-line
//     if (newRating != 0) {
//       axios({
//         method: "post",
//         url: process.env.REACT_APP_API + "/addratting",
//         data: {
//           user_id: userData.id,
//           product_id: order.product_id,
//           ratting: newRating,
//         },
//         withCredentials: false,
//       })
//         .then((_) => {

//           showCustomToast("Thanks for your feedback","success");

//           onUpdate();
//         })
//         .catch((err) => {

//           // console.log(err);
//         });
//     } else {
//       showCustomToast("Please fill the stars","error");
//     }
//   };
//   const submitReturnAPICall = () => {
//     //eslint-disable-next-line
//     if (returnReason != null && returnReason != "") {
//       axios({
//         method: "post",
//         url: process.env.REACT_APP_API + "/returnrequest",
//         data: {
//           user_id: userData.id,
//           order_id: clickedOrder.id,
//           return_reason: returnReason,
//         },
//         withCredentials: false,
//       })
//         .then((_) => {
//           closeReturnModal();
//           showCustomToast("Your return request has been raised", "success");

//           onUpdate();
//         })
//         .catch((err) => {
//           closeReturnModal();
//           // console.log(err);
//         });
//     } else {
//       showCustomToast("Please fill the return reason feild", "error");
//     }
//   };
//   const handleRatingChange = (newRating,isModal,order=null) => {
//     setRating(newRating);
//     if(!isModal) {
//       submitRatingMobileAPICall(newRating,order);
//     }
//   };

//   const handleCommentChange = (event) => {
//     setComment(event.target.value);
//   };
//   const handleReturnReasonChange = (event) => {
//     setReturnReason(event.target.value);
//   };

//   const getStatusIcon = (status) => {
//     //eslint-disable-next-line
//     if (status == 1) {
//       return faX;
//       //eslint-disable-next-line
//     } else if (status == 2) {
//       return "Order Confirmed";
//       //eslint-disable-next-line
//     } else if (status == 3) {
//       return faTruck;
//       //eslint-disable-next-line
//     } else if (status == 4) {
//       return faCheck;
//       //eslint-disable-next-line
//     } else if (status == 5) {
//       return faX;
//       //eslint-disable-next-line
//     } else if (status == 6) {
//       return faTimes;
//       //eslint-disable-next-line
//     } else if (status == 7) {
//       return faHourglass;
//     }
//     //eslint-disable-next-line
//     else if (status == 0) {
//       return faHourglass;
//     } else {
//       return null;
//     }
//   };

//   const getStatusColor = (status) => {
//     //eslint-disable-next-line
//     if (status == 1) {
//       return "orange";
//       //eslint-disable-next-line
//     } else if (status == 2) {
//       return "yellow";
//       //eslint-disable-next-line
//     } else if (status == 3) {
//       return "yellow";
//       //eslint-disable-next-line
//     } else if (status == 4) {
//       return "green";
//       //eslint-disable-next-line
//     } else if (status == 5) {
//       return "red";
//       //eslint-disable-next-line
//     } else if (status == 6) {
//       return "red";
//       //eslint-disable-next-line
//     } else if (status == 7) {
//       return "brown";
//     }
//     //eslint-disable-next-line
//     else if (status == 0) {
//       return "brown";
//     } else {
//       return null;
//     }
//   };
//   const getDeliveryStatus = (status) => {
//     //eslint-disable-next-line
//     if (status == 1) {
//       return "Order Placed";
//       //eslint-disable-next-line
//     } else if (status == 2) {
//       return "Order Confirmed";
//       //eslint-disable-next-line
//     } else if (status == 3) {
//       //eslint-disable-next-line
//       return "Order Shipped";
//       //eslint-disable-next-line
//     } else if (status == 4) {
//       //eslint-disable-next-line
//       return "Order Delivered";
//       //eslint-disable-next-line
//     } else if (status == 5) {
//       return "Cancelled By Vendor";
//       //eslint-disable-next-line
//     } else if (status == 6) {
//       return "Cancelled";
//       //eslint-disable-next-line
//     } else if (status == 7) {
//       return "Product Returned";
//     }
//     //eslint-disable-next-line
//     else if (status == 0) {
//       return "Return Requested";
//     } else {
//       return null;
//     }
//   };
//   return (
//     <>
//       {ordersList.map((order, index) => (
//         <div key={order.id}>
//           <Show breakpoint="(max-width: 500px)">
//             <Box px={5} py={3}>

//               { index !== 0 ? ordersList[index-1].order_number === order.order_number ? <></> :  <Text fontSize={'13px'} fontWeight={600}>#{order.order_number}</Text> :  <Text fontSize={'13px'} fontWeight={600}>#{order.order_number}</Text>  }

//               <Card
//                 direction={{ base: 'row' }}
//                 overflow='hidden'
//                 variant='outline'>
//                 <Box maxW={'80px'}>
//                   <Image
//                     objectFit="cover"
//                     src={
//                       order.image_url
//                         ? order.image_url
//                         : "https://kk-logo.boxinallsoftech.com/No_image.png"
//                     }
//                     alt="Product Image"
//                   />
//                 </Box>
//                 {/* <HStack display={'flex'} width={'100%'}  px={1} justifyContent={'space-evenly'} > */}
//                 <Grid
//                   w={"100%"}
//                   templateRows="repeat(2)"
//                   templateColumns="repeat(5, 1fr)"
//                   gap={2}
//                 >
//                   <GridItem colSpan={4}>
//                     <Stack>
//                       <CardBody p={2}>
//                         <Text fontSize={"13px"}>{order.product_name}</Text>
//                         <p
//                           style={{
//                             color: getStatusColor(order.status),
//                             fontSize: "10px",
//                           }}
//                        >
//                           <span class="mr1">
//                             {/* <FontAwesomeIcon icon={getStatusIcon(order.status)} /> */}
//                           </span>
//                           {"  "}
//                           {getDeliveryStatus(order.status)}
//                         </p>
//                         <Text fontWeight={600} fontSize={"12px"}>
//                           ₹ {order.grand_total}
//                         </Text>
//                       </CardBody>

//                       {/* <CardFooter p={2} flexWrap='wrap'>
//                         <Text fontSize={'12px'} fontWeight={600} pt={'6px'} mr={5}>Rate</Text>
//                         <RatingStar
//                           initialRating={0}
//                           maxRating={5}
//                           onUpdate={(count) => handleRatingChange(count)}
//                         ></RatingStar>
//                       </CardFooter> */}
//                     </Stack>
//                   </GridItem>
//                   <GridItem colSpan={1}>
//                     <Box pt={2}>
//                       <Icon
//                         as={ChevronRightIcon}
//                         boxSize={6}
//                         onClick={() =>
//                           navigate(
//                             `../orderDetails/${order.order_number}/${order.id}`
//                           )
//                         }
//                       />
//                     </Box>
//                   </GridItem>
//                 </Grid>

//                 {/* </HStack> */}
//               </Card>
//                     {
//                       /* eslint-disable-next-line */
//                       order.status == 4 ?
//                       <HStack bg="gray.100" padding={2} justifyContent={'space-around'}>
//                           <Text as={'b'} fontSize={'12px'}>Rate this product</Text>
//                           <Box ml={3} mb={1}>
//                           <RatingStar
//                             initialRating={order.ratting}
//                             maxRating={5}
//                             onUpdate={(count) => handleRatingChange(count,false,order) }>

//                             </RatingStar>
//                           </Box>
//                           <Box onClick={()=>{  openRateModal(order)}}>
//                                  <Icon  as={EditIcon} cursor={'pointer'} pb={1} w={4} h={4} />
//                             </Box>
//                       </HStack> :
//                       <>

//                       </>
//                     }

//             </Box>
//           </Show>
//           <Show breakpoint="(min-width: 500px)">
//             <div className={styles.container_main}>
//               <div className={styles.container1}>
//                 <div className={styles.image_Section}>
//                   <img
//                     src={
//                       order.image_url
//                         ? order.image_url
//                         : "https://kk-logo.boxinallsoftech.com/No_image.png"
//                     }
//                     alt=""
//                   />
//                 </div>
//                 <div className={styles.section1}>
//                   <Text fontSize="xs">#{order.order_number}</Text>
//                   <Text fontSize="md">{order.product_name}</Text>
//                   <p style={{ fontWeight: "300", fontSize: "14px" }}>
//                     {order.date}
//                   </p>
//                   {isRatingGiven(order) ? (
//                     <div className={styles.rating}>
//                       <div>
//                         <span>{order.ratting}</span> &nbsp;
//                         <span className={styles.star}>&#9733; </span>
//                       </div>
//                     </div>
//                   ) : (
//                     <></>
//                   )}
//                 </div>

//                 <div className={styles.section2}>
//                   <p style={{ color: getStatusColor(order.status) }}>
//                     <span class="mr1">
//                       <FontAwesomeIcon icon={getStatusIcon(order.status)} />
//                     </span>
//                     {"  "}
//                     {getDeliveryStatus(order.status)}
//                   </p>
//                   <p>₹ {order.grand_total}</p>
//                   {showMoreDetailsButton(order) ? (
//                     <Menu>
//                       <MenuButton
//                         as={Button}
//                         variant="link"
//                         size={"xs"}
//                         className={styles.underline}
//                       >
//                         More options
//                       </MenuButton>
//                       <MenuList>
//                         {order.return_status ? (
//                           <MenuItem onClick={() => openReturnModal(order)}>
//                             Return
//                           </MenuItem>
//                         ) : (
//                           <></>
//                         )}
//                         {order.cancel_staus ? (
//                           <MenuItem
//                             onClick={() =>
//                               openConfirmationModal(
//                                 "Are you sure you want to cancel the product ?",
//                                 6,
//                                 order
//                               )
//                             }
//                           >
//                             Cancel
//                           </MenuItem>
//                         ) : (
//                           <></>
//                         )}
//                         {showRatingOption(order) ? (
//                           <MenuItem onClick={() => openRateModal(order)}>
//                             Rate
//                           </MenuItem>
//                         ) : (
//                           <></>
//                         )}
//                       </MenuList>
//                     </Menu>
//                   ) : (
//                     <></>
//                   )}
//                 </div>
//               </div>
//             </div>
//             {index !== ordersList.length - 1 && (
//               <div className={styles.divider}></div>
//             )}
//           </Show>
//         </div>
//       ))}

//       <Modal isOpen={isRateModalOpen} onClose={closeRateModal}>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Rate Product</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <Heading
//               fontWeight={300}
//               as={"h2"}
//               color="#535665"
//               fontSize="20px"
//               size="lg"
//               mb={5}
//             >
//               {clickedOrder.product_name}{" "}
//             </Heading>
//             { clickedOrder.ratting == null ?
//             <FormControl>
//               <FormLabel>Rating</FormLabel>
//               <RatingStar
//                 initialRating={0}
//                 maxRating={5}
//                 onUpdate={(count) => handleRatingChange(count,true,null)}
//               ></RatingStar>
//             </FormControl> : <></> }

//             <FormControl mt={4}>
//               <FormLabel>Comments</FormLabel>
//               <Textarea
//                 placeholder="Enter your comments"
//                 onChange={handleCommentChange}
//                 value={comment}
//               />
//             </FormControl>
//           </ModalBody>
//           <ModalFooter>
//             <Button colorScheme="blue" mr={3} onClick={submitRatingAPICall}>
//               Save
//             </Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//       <Modal isOpen={isReturnModalOpen} onClose={closeReturnModal}>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>Return Product</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <Heading
//               fontWeight={300}
//               as={"h2"}
//               color="#535665"
//               fontSize="20px"
//               size="lg"
//               mb={5}
//             >
//               {clickedOrder.product_name}{" "}
//             </Heading>
//             <FormControl mt={4}>
//               <FormLabel>Return Reason</FormLabel>
//               <Textarea
//                 placeholder="Enter your comments. Based on this comment the seller will act upon your return request"
//                 onChange={handleReturnReasonChange}
//                 value={returnReason}
//               />
//             </FormControl>
//           </ModalBody>
//           <ModalFooter>
//             <Button colorScheme="blue" mr={3} onClick={submitReturnAPICall}>
//               Save
//             </Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//       <ConfirmationModal
//         onConfirm={(confirm, order) =>
//           handleConfirmationDialogYes(confirm, order)
//         }
//         isOpen={isConfirmationModalOpen}
//         onClose={closeConfirmationModal}
//         text={confirmationModalText}
//         product={clickedOrder}
//       />
//     </>
//   );
// };

// export default Order;

import React, { useState } from "react";
// import styles from "../css/Order.module.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faTimes,
//   faCheck,
//   faTruck,
//   faX,
//   faHourglass,
// } from "@fortawesome/free-solid-svg-icons";
import { ChevronRightIcon, EditIcon } from "@chakra-ui/icons";
import ConfirmationModal from "../Components/ConfirmationModal";
import RatingStar from "../Components/RatingStar";
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea,
  ModalFooter,
  Heading,
  Card,
  CardBody,
  Image,
  Stack,
  Box,
  Show,
  Grid,
  GridItem,
  Icon,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useSelector } from "react-redux";
import showCustomToast from "./showCustomToast";
import { useNavigate } from "react-router-dom";

const Order = ({ ordersList, onUpdate }) => {
  const navigate = useNavigate();
  const { userData } = useSelector((store) => store.AuthReducer);
  const [isRateModalOpen, setIsRateModalOpen] = useState(false);
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  //eslint-disable-next-line
  const [confirmationModalText, setConfirmationModalText] = useState("");
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [clickedOrder, setClickedOrder] = useState({});
  const [returnReason, setReturnReason] = useState("");

  console.log("order", ordersList);

  // const isRatingGiven = (order) => {
  //   if (order.ratting == null && order.comment == null) {
  //     return false;
  //   }
  //   return true;
  // };
  // const showRatingOption = (order) => {
  //   //eslint-disable-next-line
  //   if (order.status == 4 && !isRatingGiven(order)) {
  //     return true;
  //   }
  //   return false;
  // };
  // const showMoreDetailsButton = (order) => {
  //   if (
  //     !showRatingOption(order) &&
  //     !order.cancel_staus &&
  //     !order.return_status
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };
  const openRateModal = (order) => {
    setClickedOrder(order);
    setIsRateModalOpen(true);
  };
  // const openReturnModal = (order) => {
  //   setClickedOrder(order);
  //   setIsReturnModalOpen(true);
  // };
  const closeRateModal = () => {
    setIsRateModalOpen(false);
    setComment("");
    setRating(0);
  };
  const closeReturnModal = () => {
    setIsReturnModalOpen(false);
    setReturnReason("");
  };

  // const openConfirmationModal = (text, status, order) => {
  //   setConfirmationModalText(text);
  //   setClickedOrder(order);
  //   setIsConfirmationModalOpen(true);
  // };

  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };
  const handleConfirmationDialogYes = (confirm, order) => {
    if (confirm) {
      cancelReturnOrderAPICall(order.id, 6); //6 canclled,
    }
  };
  const cancelReturnOrderAPICall = (orderId, status) => {
    axios({
      method: "post",
      url: process.env.REACT_APP_API + "/cancelorder",
      data: { user_id: userData.id, status: status, order_id: orderId },
      withCredentials: false,
    })
      .then((_) => {
        showCustomToast("Order Cancelled", "success");

        onUpdate();
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const submitRatingAPICall = () => {
    //eslint-disable-next-line
    if (rating != null) {
      axios({
        method: "post",
        url: process.env.REACT_APP_API + "/addratting",
        data: {
          user_id: userData.id,
          product_id: clickedOrder.product_id,
          ratting: rating,
          comment: comment,
        },
        withCredentials: false,
      })
        .then((_) => {
          closeRateModal();
          showCustomToast("Thanks for your feedback", "success");

          onUpdate();
        })
        .catch((err) => {
          closeRateModal();
          // console.log(err);
        });
    } else {
      showCustomToast("Please fill the stars", "error");
    }
  };
  const submitRatingMobileAPICall = (newRating, order) => {
    //eslint-disable-next-line
    if (newRating != 0) {
      axios({
        method: "post",
        url: process.env.REACT_APP_API + "/addratting",
        data: {
          user_id: userData.id,
          product_id: order.product_id,
          ratting: newRating,
        },
        withCredentials: false,
      })
        .then((_) => {
          showCustomToast("Thanks for your feedback", "success");

          onUpdate();
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      showCustomToast("Please fill the stars", "error");
    }
  };
  const submitReturnAPICall = () => {
    //eslint-disable-next-line
    if (returnReason != null && returnReason != "") {
      axios({
        method: "post",
        url: process.env.REACT_APP_API + "/returnrequest",
        data: {
          user_id: userData.id,
          order_id: clickedOrder.id,
          return_reason: returnReason,
        },
        withCredentials: false,
      })
        .then((_) => {
          closeReturnModal();
          showCustomToast("Your return request has been raised", "success");

          onUpdate();
        })
        .catch((err) => {
          closeReturnModal();
          // console.log(err);
        });
    } else {
      showCustomToast("Please fill the return reason feild", "error");
    }
  };
  const handleRatingChange = (newRating, isModal, order = null) => {
    setRating(newRating);
    if (!isModal) {
      submitRatingMobileAPICall(newRating, order);
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const handleReturnReasonChange = (event) => {
    setReturnReason(event.target.value);
  };

  // const getStatusIcon = (status) => {
  //   //eslint-disable-next-line
  //   if (status == 1) {
  //     return faX;
  //     //eslint-disable-next-line
  //   } else if (status == 2) {
  //     return "Order Confirmed";
  //     //eslint-disable-next-line
  //   } else if (status == 3) {
  //     return faTruck;
  //     //eslint-disable-next-line
  //   } else if (status == 4) {
  //     return faCheck;
  //     //eslint-disable-next-line
  //   } else if (status == 5) {
  //     return faX;
  //     //eslint-disable-next-line
  //   } else if (status == 6) {
  //     return faTimes;
  //     //eslint-disable-next-line
  //   } else if (status == 7) {
  //     return faHourglass;
  //   }
  //   //eslint-disable-next-line
  //   else if (status == 0) {
  //     return faHourglass;
  //   } else {
  //     return null;
  //   }
  // };

  const getStatusColor = (status) => {
    //eslint-disable-next-line
    if (status == 1) {
      return "orange";
      //eslint-disable-next-line
    } else if (status == 2) {
      return "yellow";
      //eslint-disable-next-line
    } else if (status == 3) {
      return "yellow";
      //eslint-disable-next-line
    } else if (status == 4) {
      return "green";
      //eslint-disable-next-line
    } else if (status == 5) {
      return "red";
      //eslint-disable-next-line
    } else if (status == 6) {
      return "red";
      //eslint-disable-next-line
    } else if (status == 7) {
      return "brown";
    }
    //eslint-disable-next-line
    else if (status == 0) {
      return "brown";
    } else {
      return null;
    }
  };
  const getDeliveryStatus = (status) => {
    //eslint-disable-next-line
    if (status == 1) {
      return "Order Placed";
      //eslint-disable-next-line
    } else if (status == 2) {
      return "Order Confirmed";
      //eslint-disable-next-line
    } else if (status == 3) {
      //eslint-disable-next-line
      return "Order Shipped";
      //eslint-disable-next-line
    } else if (status == 4) {
      //eslint-disable-next-line
      return "Order Delivered";
      //eslint-disable-next-line
    } else if (status == 5) {
      return "Cancelled By Vendor";
      //eslint-disable-next-line
    } else if (status == 6) {
      return "Cancelled";
      //eslint-disable-next-line
    } else if (status == 7) {
      return "Product Return Accepted";
      //eslint-disable-next-line
    } else if (status == 8) {
      return "Product Returned";
    }
    //eslint-disable-next-line
    else if (status == 0) {
      return "Return Requested";
    } else {
      return null;
    }
  };
  return (
    <>
      {ordersList.map((order, index) => (
        <div key={order.id}>
          <Show breakpoint="(max-width: 500px)">
            <Box px={5} py={3}>
              {index !== 0 ? (
                ordersList[index - 1].order_number === order.order_number ? (
                  <></>
                ) : (
                  <Text fontSize={"13px"} fontWeight={600}>
                    #{order.order_number}
                  </Text>
                )
              ) : (
                <Text fontSize={"13px"} fontWeight={600}>
                  #{order.order_number}
                </Text>
              )}

              <Card
                direction={{ base: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Box maxW={"80px"}>
                  <Image
                    objectFit="cover"
                    src={
                      order.image_url
                        ? order.image_url
                        : "https://kk-logo.boxinallsoftech.com/No_image.png"
                    }
                    alt="Product Image"
                  />
                </Box>
                {/* <HStack display={'flex'} width={'100%'}  px={1} justifyContent={'space-evenly'} > */}
                <Grid
                  w={"100%"}
                  templateRows="repeat(2)"
                  templateColumns="repeat(5, 1fr)"
                  gap={2}
                >
                  <GridItem colSpan={4}>
                    <Stack>
                      <CardBody p={2}>
                        <Text fontSize={"13px"}>{order.product_name}</Text>
                        <p
                          style={{
                            color: getStatusColor(order.status),
                            fontSize: "10px",
                          }}
                        >
                          <span class="mr1">
                            {/* <FontAwesomeIcon icon={getStatusIcon(order.status)} /> */}
                          </span>
                          {"  "}
                          {getDeliveryStatus(order.status)}
                        </p>
                        <Text fontWeight={600} fontSize={"12px"}>
                          ₹ {order.grand_total}
                        </Text>
                      </CardBody>
                    </Stack>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Box pt={2}>
                      <Icon
                        as={ChevronRightIcon}
                        boxSize={6}
                        onClick={() =>
                          navigate(
                            `../orderDetails/${order.order_number}/${order.id}`
                          )
                        }
                      />
                    </Box>
                  </GridItem>
                </Grid>

                {/* </HStack> */}
              </Card>
              {
                /* eslint-disable-next-line */
                order.status == 4 ? (
                  <HStack
                    bg="gray.100"
                    padding={2}
                    justifyContent={"space-around"}
                  >
                    <Text as={"b"} fontSize={"12px"}>
                      Rate this product
                    </Text>
                    <Box ml={3} mb={1}>
                      <RatingStar
                        initialRating={order.ratting}
                        maxRating={5}
                        onUpdate={(count) =>
                          handleRatingChange(count, false, order)
                        }
                      ></RatingStar>
                    </Box>
                    <Box
                      onClick={() => {
                        openRateModal(order);
                      }}
                    >
                      <Icon
                        as={EditIcon}
                        cursor={"pointer"}
                        pb={1}
                        w={4}
                        h={4}
                      />
                    </Box>
                  </HStack>
                ) : (
                  <></>
                )
              }
            </Box>
          </Show>

          <Show breakpoint="(min-width: 500px)">
            <Box px={5} py={3}>
              {index !== 0 ? (
                ordersList[index - 1].order_number === order.order_number ? (
                  <></>
                ) : (
                  <Text fontSize={"13px"} fontWeight={600}>
                    #{order.order_number}
                  </Text>
                )
              ) : (
                <Text fontSize={"13px"} fontWeight={600}>
                  #{order.order_number}
                </Text>
              )}

              <Card
                direction={{ base: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Box maxW={"80px"}>
                  <Image
                    objectFit="cover"
                    src={
                      order.image_url
                        ? order.image_url
                        : "https://kk-logo.boxinallsoftech.com/No_image.png"
                    }
                    alt="Product Image"
                  />
                </Box>
                {/* <HStack display={'flex'} width={'100%'}  px={1} justifyContent={'space-evenly'} > */}
                <Grid
                  w={"300px"}
                  templateRows="repeat(2)"
                  templateColumns="repeat(5, 1fr)"
                  gap={2}
                >
                  <GridItem colSpan={4}>
                    <Stack>
                      <CardBody p={2}>
                        <Text fontSize={"13px"}>{order.product_name}</Text>
                        <p
                          style={{
                            color: getStatusColor(order.status),
                            fontSize: "10px",
                          }}
                        >
                          <span class="mr1">
                            {/* <FontAwesomeIcon icon={getStatusIcon(order.status)} /> */}
                          </span>
                          {"  "}
                          {getDeliveryStatus(order.status)}
                        </p>
                        <Text fontWeight={600} fontSize={"12px"}>
                          ₹ {order.grand_total}
                        </Text>
                      </CardBody>

                      {/* <CardFooter p={2} flexWrap='wrap'>
                        <Text fontSize={'12px'} fontWeight={600} pt={'6px'} mr={5}>Rate</Text>
                        <RatingStar
                          initialRating={0}
                          maxRating={5}
                          onUpdate={(count) => handleRatingChange(count)}
                        ></RatingStar>
                      </CardFooter> */}
                    </Stack>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Box pt={2}>
                      <Icon
                        as={ChevronRightIcon}
                        boxSize={6}
                        onClick={() =>
                          navigate(
                            `../orderDetails/${order.order_number}/${order.id}`
                          )
                        }
                      />
                    </Box>
                  </GridItem>
                </Grid>

                {/* </HStack> */}
              </Card>
              {
                /* eslint-disable-next-line */
                order.status == 4 ? (
                  <HStack
                    bg="gray.100"
                    padding={2}
                    justifyContent={"space-around"}
                  >
                    <Text as={"b"} fontSize={"12px"}>
                      Rate this product
                    </Text>
                    <Box ml={3} mb={1}>
                      <RatingStar
                        initialRating={order.ratting}
                        maxRating={5}
                        onUpdate={(count) =>
                          handleRatingChange(count, false, order)
                        }
                      ></RatingStar>
                    </Box>
                    <Box
                      onClick={() => {
                        openRateModal(order);
                      }}
                    >
                      <Icon
                        as={EditIcon}
                        cursor={"pointer"}
                        pb={1}
                        w={4}
                        h={4}
                      />
                    </Box>
                  </HStack>
                ) : (
                  <></>
                )
              }
            </Box>
          </Show>
        </div>
      ))}

      <Modal isOpen={isRateModalOpen} onClose={closeRateModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rate Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading
              fontWeight={300}
              as={"h2"}
              color="#535665"
              fontSize="20px"
              size="lg"
              mb={5}
            >
              {clickedOrder.product_name}{" "}
            </Heading>
            {clickedOrder.ratting == null ? (
              <FormControl>
                <FormLabel>Rating</FormLabel>
                <RatingStar
                  initialRating={0}
                  maxRating={5}
                  onUpdate={(count) => handleRatingChange(count, true, null)}
                ></RatingStar>
              </FormControl>
            ) : (
              <></>
            )}

            <FormControl mt={4}>
              <FormLabel>Comments</FormLabel>
              <Textarea
                placeholder="Enter your comments"
                onChange={handleCommentChange}
                value={comment}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={submitRatingAPICall}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isReturnModalOpen} onClose={closeReturnModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Return Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading
              fontWeight={300}
              as={"h2"}
              color="#535665"
              fontSize="20px"
              size="lg"
              mb={5}
            >
              {clickedOrder.product_name}{" "}
            </Heading>
            <FormControl mt={4}>
              <FormLabel>Return Reason</FormLabel>
              <Textarea
                placeholder="Enter your comments. Based on this comment the seller will act upon your return request"
                onChange={handleReturnReasonChange}
                value={returnReason}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={submitReturnAPICall}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ConfirmationModal
        onConfirm={(confirm, order) =>
          handleConfirmationDialogYes(confirm, order)
        }
        isOpen={isConfirmationModalOpen}
        onClose={closeConfirmationModal}
        text={confirmationModalText}
        product={clickedOrder}
      />
    </>
  );
};

export default Order;
