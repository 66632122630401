import React from 'react';
import { Box, Container, Heading, Text,Show } from '@chakra-ui/react';
import Navbar2 from "../Components/Navbar2";
import Footer from "../Components/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
        <Navbar2></Navbar2>
        <Box py={4} mr={10} ml={10} mb={10}>
      <Container maxW="container.LG" >
        <Heading as="h1" mb={4} textAlign={'center'} fontWeight={400}>Terms of use</Heading>
        <Text mb={4} fontSize={'13px'}>
        This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of domain name [www.98DegreeNorth.com] (“Website”), including the related mobile site and mobile application (hereinafter referred to as “Platform”) The Platform is owned by M/S K.K.Fabrics, a Partnership with its registered office at 1740/27-C, Balloke Road , Haibowal Kalan,Ludhiana-141001,Punjab , (hereinafter referred to as "98degreenorth"). Your use of the Platform and services and tools are governed by the following terms and conditions ("Terms of Use") as applicable to the Platform including the applicable policies which are incorporated herein by way of reference. If You transact on the Platform, You shall be subject to the policies that are applicable to the Platform for such transaction. By mere use of the Platform, You shall be contracting with M/S K.K.Fabrics and these terms and conditions including the policies constitute Your binding obligations, with M/S K.K.Fabrics. For the purpose of these Terms of Use, wherever the context so requires "You" or "User" shall mean any natural or legal person who has agreed to become a buyer on the Platform by providing Registration Data while registering on the Platform as Registered User using the computer systems. M/S K.K.Fabrics allows the User to surf the Platform or making purchases without registering on the Platform. The term "We", "Us", "Our" shall mean M/S K.K.Fabrics. 
      </Text>
      </Container>
    </Box>
    <Show breakpoint='(min-width: 500px)' >
      <Footer></Footer>
    </Show>
    </div>
  );
};

export default PrivacyPolicy;
