import * as types from "./ActionTypes";

const initialState = {
  Cart: [],
  isLoading: false,
  isError: false,
  DeleteFromWishlist:false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // isLoading state...................

    case types.GET_CART_REQUEST:
      return {
        ...state,
        isLoading: true,

      };

    // ...............................

    // Success state..................

    case types.GET_CART_SUCCESS:
      // console.log("products:", payload);
      return {
        ...state,
        Cart: payload,
        isLoading: false,
      };
    // Products Detail

    case types.GET_CART_FAILURE:
      // console.log("products detail in reducer:", payload);
      return {
        ...state,
        // Cart: payload,
        isLoading: false,

      };

      case types.ADD_CART_SUCCESS:
        // console.log("products:", payload);
        return {
          ...state,
           Cart: payload,
          isLoading: false,
          DeleteFromWishlist:!state.DeleteFromWishlist
        };

        case types.UPDATE_CART_SUCCESS:
          // console.log("products:", payload);
          const updatedCart = state.Cart.map(item => {
            if (item.id === payload.id) {
              return { ...item, qty: payload.qty };
            }
            return item;
          });

          return {
            ...state,
            Cart: updatedCart,
            isLoading: false,
          };




          case types.DELETE_CART_SUCCESS:


          // console.log("updated:", payload);

            return {
              ...state,
              Cart: payload,
              isLoading: false,
          };

          case types.UPDATE_CART_IF_SIZE_EXISTS:

          // console.log("updated:", payload);
            return {
              ...state,
              Cart: payload,
              isLoading: false,
          };

          case types.UPDATE_CART_ITEM_SIZE:

          // console.log("updated:", payload);
            return {
              ...state,
              Cart: payload,
              isLoading: false,
          };


        case 'SHOW_TOAST': // New case for SHOW_TOAST action
      return {
        ...state,
        carttoastMessage: payload,
      };

    // ...............................
    // default case

    default:
      return state;
  }

  // ................................
};



export { reducer };
