
import styles from "../css/Profile.module.css";
import {
  Box,Grid,GridItem,Text,Show
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faChevronRight, faMapMarkerAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Redux/AuthReducer/Action';

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((store) => store.AuthReducer);
  const handleItemClick = (item) => {
    if (item.onClick) {
      item.onClick();
    }
    if (item.navigate) {
      navigate(item.navigate);
    }
  };

  const settingsData = [
    {
      item: "Order History",
      icon: faHistory,
      navigate: '/orders'
    },
    {
      item: "My Address",
      icon: faMapMarkerAlt,
      navigate: `/profile/address?pageType=${1}`
    },
    {
      item: "Sign Out",
      icon: faSignOutAlt,
      onClick: () => {
        dispatch(login("logout"));
      }
    },
    // Add more objects as needed
  ];



  return (
    <>
      {/* heading */}

      <div className={styles.main}>
        <Show breakpoint='(max-width: 500px)'>
          <Box w={'100%'} py={5} display={'grid'} justifyContent={'center'}>
            <div className={styles.heading}>Profile</div>
            <Grid w={'100%'} templateRows='repeat(2)' templateColumns='repeat(5, 1fr)' gap={2} px={10}>
              <GridItem colSpan={1}>
                <Box
                  w="70px"
                  h="70px"
                  borderRadius="50%" // This sets the box to be a circle
                  bg="red.500"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  fontWeight="bold"
                  mb={2}
                  fontSize="24px">
                  {userData.name.charAt(0).toUpperCase()}
                </Box>
              </GridItem>
              <GridItem colSpan={4}>
                  <Box pt={3}>
                    <Text style={{ fontWeight: '400', fontSize: '14px' }}>{userData.name}</Text>
                    <Text style={{ fontWeight: '300', fontSize: '12px', color: 'rgba(128, 128, 128, 0.8)' }} >{userData.email}</Text>
                  </Box>
              </GridItem>
            </Grid>
          </Box>
        </Show>
        <Show breakpoint='(min-width: 500px)'>
          <div className={styles.heading}>Profile</div>

          <div className={styles.container1}>
            <Box
              w="70px"
              h="70px"
              borderRadius="50%" // This sets the box to be a circle
              bg="red.500"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              fontWeight="bold"
              mb={2}
              fontSize="24px">
              {userData.name.charAt(0).toUpperCase()}
            </Box>
        
            <p style={{ fontWeight: '400', fontSize: '14px' }}>{userData.name}</p>
            <p style={{ fontWeight: '300', fontSize: '12px', color: 'rgba(128, 128, 128, 0.8)' }} >{userData.email}</p>

          </div>
        </Show>

        {settingsData.map((item, index) => (
          <div key={index} onClick={() => handleItemClick(item)} style={{ cursor: 'pointer' }}>
            <div className={styles.container2}>
              <div className={styles.section1}>

                <FontAwesomeIcon icon={item.icon} style={{ color: 'rgba(128, 128, 128, 0.8)' }} />
                <p style={{ fontWeight: '300', fontSize: '14px', width: '80%' }}>{item.item}</p>
              </div>
              <div className={styles.section2}>
                <p style={{ color: '#000000' }} ><span> <FontAwesomeIcon icon={faChevronRight} style={{ color: 'rgba(128, 128, 128, 0.8)' }} /></span></p>

              </div>
            </div>
            {index !== settingsData.length - 1 && <div className={styles.divider}></div>}
          </div>
        ))}
      </div>
    </>
  );
};

export default Profile;
