import React from 'react';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import Navbar2 from "../Components/Navbar2";
import Footer from "../Components/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
        <Navbar2></Navbar2>
        <Box py={4} mr={10} ml={10} mb={10}>
      <Container maxW="container.LG" >
        <Heading as="h1" mb={4} textAlign={'center'} fontWeight={400}>Privacy Policy</Heading>
        <Heading size={'md'} mb={1} mt={5} fontWeight={600}>General</Heading>
<Text mb={4} fontSize={'13px'}>
M/S K.K.Fabrics, having its registered address at 1740/27-C, Balloke Road Haibowal Kalan, Ludhiana-141001, Punjab, India (“K.K.Fabrics”, “we”, “us” or “our”) is managing and operating the website www.98degreenorth.com and the mobile application thereof (98degreenorth) are collectively referred to as the “Platforms”) through which it inter alia facilitates the sale and purchase of a diverse range of fashion, home, and other products listed on the Platforms from time to time and other products (“Products”) to the users of the Platforms (“User” or “Users” or “you” or “your”).

This privacy policy (“Privacy Policy”), together with the Terms governs your use of the Platforms and describes our policies and procedures on the collection, use, disclosure, processing, transfer, and storage of the information provided to us by you. By using, browsing, accessing, or purchasing from the Platforms you agree to be bound by the terms of this Privacy Policy and consent to the collection, storage, possession, dealing, handling, sharing, disclosure, or transfer of your information in accordance with the terms of the Privacy Policy. We shall not use the User’s information in any manner except as provided under this Privacy Policy. Capitalized terms used herein if not defined shall have the same meaning as ascribed to them under the Terms.

This document is an electronic record and is governed by the provisions under the Information Technology Act, 2000 and rules made thereunder as may be applicable, and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.

This Privacy Policy sets out the type of information collected from the Users, including the nature of the Sensitive Personal Data or Information (defined hereinafter), the purpose, means, and modes of usage of such information and how and to whom we shall disclose or transfer such information. You may at any time withdraw your consent for collection and use of your information including Personal Information (defined hereinafter) or Sensitive Personal Data or Information. However, please note that if you withdraw your consent, we may no longer be able to provide you with the corresponding service for which you have withdrawn your consent. It is hereby clarified that your decision to withdraw your consent will not affect the processing of Personal Information based on your previous consent prior to the withdrawal.

Please take a moment to familiarize yourself with our Privacy Policy. If you do not agree with any provisions of the Terms or this Privacy Policy, we advise you to not use or access the Platforms.
</Text>
<Heading size={'md'} mb={1} fontWeight={600}>Type of Information Collected</Heading>
<Text mb={4} fontSize={'13px'}>
You may be asked to provide your information anytime you visit, access, use, or browse the Platforms. We may share information and use it consistent with the provisions of this Privacy Policy. We may also combine it with other information to provide and improve our Products, services, content, and advertising.

You agree to provide information, which shall be true, correct, up to date and accurate. You may access, amend, alter or require deletion of your information partially or fully by contacting us at customercare@98degreenorth.com

The Platforms are not directed towards minors. We do not knowingly collect Personal Information from any User who is under 18 (Eighteen) years. If we are made aware that we have collected the Personal Information of a person below the age of 18 (Eighteen) years, we will take steps to delete the information within a reasonable time frame. If a parent or guardian becomes aware that his or her child has provided his/her information on the Platforms without their consent, he or she shall contact us for the deletion of such information. In the event, where in contravention to the Privacy Policy and Terms, a person below the age of 18 (Eighteen) uses the services, we shall not be held liable or responsible for any damage or injury suffered by such person.

We may collect a variety of information from you, including your name, mailing address, phone number, email address and contact preferences, including any other usage and interaction details with us or our affiliates. We may collect such information which when combined with other pieces of information available with us could reasonably allow you to be identified (“Personal Information”). Personal Information shall include but is not limited to your full name, personal contact numbers, residential address, email address, gender or date of birth. Personal Information can be in any format including but not limited to photographs or documents.
</Text>

<Heading size={'md'} mb={1} fontWeight={600}>Use of Information Collected</Heading>
<Text mb={4} fontSize={'13px'}>
We may collect, use or process your information including Personal Information and Sensitive Personal Data or Information for the following purposes:

3.1.1 For creating and giving you access to your registered account on the Platforms.
3.1.2 To develop, deliver, process and improve our Products, services, content in order to personalize and improve your experience.
3.1.3 To inform you about our Products, services, offers, updates, upcoming events, including providing you information in relation to order confirmations, invoices, technical notices, security alerts.
3.1.4 For internal analytical and research purposes such as auditing, data analysis and research to improve Our Products, services and customer communications.
3.1.5 To meet any legal or regulatory requirement or comply with a request from any governmental or judicial authority.
3.1.6 To resolve any request, dispute, grievance or complaint raised by you in relation to your use of the Platforms.
3.1.7 To detect or monitor any fraudulent or illegal activity on the Platforms
</Text>

<Heading size={'md'} mb={1} fontWeight={600}>Disclosure of Information Collected</Heading>
<Text mb={4} fontSize={'13px'}>
We may from time to time be required to disclose the information collected from you to our trusted third party service providers who assist us in order to facilitate the provision of services and purchase of Products on the Platforms. For instance, we may share your information with third party payment gateway provider to process transactions on the Platforms. By using the Platforms, you consent to any such disclosure of your information with third party service providers. We ensure that such third party service providers are bound by reasonable confidentiality obligations and/or use, maintain and follow generally accepted industry and security standards with respect to such information.

We may also disclose your information when such disclosure is requisitioned under any law or judicial decree or when we, in our sole discretion, deem it necessary in order to protect our rights or the rights of other Users, to prevent harm to persons or property, to fight fraud and credit risk.

We may also disclose or transfer your information to any third party as a part of reorganization or a sale of the assets, division or transfer of a part or whole of us. We shall ensure that third party to which we transfer or sell our assets will have appropriate confidentiality and security measures, at least as protective as those described in this Privacy Policy, to handle your Personal Information. You will have the opportunity to opt out of any such transfer if the new entity's planned processing of your information differs materially from that set forth in this Privacy Policy.

A third party payment gateway provider may be required to collect certain financial information from you including, but not restricted to, your credit/debit card number or your bank account details (collectively referred to as “Financial Information”). All Financial Information collected from you by such third party payment gateway providers will be used only for billing and payment processes. The Financial Information collected from you is transacted through secure digital platforms of approved payment gateways which are under encryption, thereby complying with reasonably expected technology standards. The verification of the Financial Information shall be accomplished only by you through a process of authentication in which we shall have no role to play and hence, we shall bear no liability in relation to the same. We shall neither be liable nor responsible for any actions or inactions of third party payment gateway providers or any breach of conditions, representations and warranties given by them. We shall also not be obligated to mediate or resolve any dispute or disagreement between you and such third party payment service providers.
</Text>

<Heading size={'md'} mb={1} fontWeight={600}>Security</Heading>
<Text mb={4} fontSize={'13px'}>
The security of your Personal Information is important to us. We have implemented security policies, rules and technical measures, as required under applicable law including firewalls, transport layer security and other physical and electronic security measures to protect the Personal Information that it has under its control from unauthorized access, improper use or disclosure, unauthorized modification and unlawful destruction or accidental loss. When you submit your information on the Platforms, your information is protected through our security systems. Please note that we use international and industry-recognized standards such as per applicable laws, rules and regulations.

Your information is contained within secured networks and is only accessible by a limited number of authorized persons who have access rights to such systems or otherwise require such information for the purposes provided in this Privacy Policy. These authorized persons are also under an obligation to keep such information confidential.

Although we make best possible efforts to transmit and store all the information provided by you in a secure operating environment that is not open to public, you understand and acknowledge that there is no such thing as complete security and we do not guarantee that there will be no unintended disclosures of any information and potential security breaches. You agree not to hold us responsible for any breach of security or for any action of any third parties that receive your Personal Information or events that are beyond our reasonable control including, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, technical glitches, breach of security and encryption, etc.
</Text>

<Heading size={'md'} mb={1} fontWeight={600}>Cookies Policy</Heading>
<Text mb={4} fontSize={'13px'}>
Due to the communications standards on the Internet, when you visit, access or browse the Platforms, we automatically receive the uniform resource locator of the site from which such you visit, access or browse the Platforms, details of the website you visit on leaving the Platforms, the internet protocol (“IP”) address of each User’s computer operating system, type of web browser the User is using, email patterns, and the name of the User’s internet service provider. This information is used solely to analyze overall User trends and to help us improve our services. Please note that the link between the User’s IP address and the User’s personally identifiable information is not shared with third parties without such User’s permission or except when required by law or to provide or facilitate the User with the services. Notwithstanding the above, the User acknowledges that we reserve the right to share some of the aggregate findings, including the personal information provided by the Users in an unidentifiable, aggregate form, and not the specific data with advertisers, sponsors, investors, strategic partners, and others in order to help grow the business. The amount of information sent to us depends on the settings of the web browser used by the User to access the Platforms. The User may refer to the browser used, if the User wishes to learn what information is provided to us.

The Platforms use temporary cookies to store certain data. We do not store Personal Information in the cookies. Information collected by us, by any means whatsoever, that does not personally identify the User as an individual (such as patterns of utilization described above) is exclusively owned by us and may be used by us and third party service providers for technical administration of the Platforms, user administration, research, development, and other purposes.

You understand that you may set or amend your web browsers to delete or disable cookies. If you choose to disable cookies on your computer or mobile telecommunication device, it may impair, degrade or restrict access to certain areas of the Platforms.

We may allow other companies or entities to serve advertisements to you. These companies or entities include third party advertisement servers, advertisement agencies, advertisement technology vendors and research firms. We may target some advertisements to you that fit a certain general profile. We do not use Personal Information to target advertisements to you. In the course of serving advertisements or optimizing the services to its Users, we may allow authorized third parties to place or recognize a unique cookie on the User’s browser.

You agree and understand that we do not exercise control over third party websites displayed as search results or links on the Platforms. These other sites may place their own cookies or other files on the Users’ computer, collect data or solicit personal information from the Users, on which we have no control and shall not be held responsible or liable. We do not make any representations concerning the privacy practices or policies of such third parties or terms of use of such websites, nor do we guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages or other materials available on such websites. The inclusion or exclusion does not imply any endorsement by us of such websites, the websites’ provider, or the information on the website.

We may keep records of telephone calls received from and made to Users for the purpose of administration of services, research and development, training, business intelligence, business development, or for User administration. We may share such telephone records with third parties when required by law or when required to provide or facilitate the User with the services.

You consent to our reproduction/publishing of all testimonials and reviews given by you on the Platforms in relation to the services or the Products. You agree that we may edit the testimonials and reviews provided by you and reproduce/publish such edited or paraphrased versions of the testimonials and reviews on the Platforms. If the User has any concerns with the reproduction/publication of any testimonial or review provided by you, the User may contact us at customercare@98degreenorth.com
</Text>

<Heading size={'md'} mb={1} fontWeight={600}>Opt-Out Policy</Heading>
<Text mb={4} fontSize={'13px'}>
The third party service providers with whom we may share information provided by you are not permitted to market their own services or send promotional e-mails or engage in promotional communication with you. We provide you with the opportunity to opt-out of receiving non-essential, promotional, or marketing-related communication from itself or its partners.

If you wish to remove your contact information from all our mailing lists and newsletters, you can click on the "unsubscribe" link or follow the instructions in each e-mail message. Alternatively, you can contact us at customercare@98degreenorth.com. We reserve the right to limit membership based on availability of contact information. All Users will be notified by email prior to any actions taken.
</Text>

<Heading size={'md'} mb={1} fontWeight={600}>Retention of Information</Heading>
<Text mb={4} fontSize={'13px'}>
We will retain your information and any data for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted under the applicable law.
</Text>

<Heading size={'md'} mb={1} fontWeight={600}>Modification</Heading>
<Text mb={4} fontSize={'13px'}>
We reserve the right to amend this Privacy Policy at any time. The Privacy Policy, as and when modified, shall be updated on the Platforms. We encourage you to review this Privacy Policy whenever you visit our Platforms to understand how your Personal Information is used.
</Text>

<Heading size={'md'} mb={1} fontWeight={600}>Grievance Redressal</Heading>
<Text mb={4} fontSize={'13px'}>
In case you have any questions, grievance or complaints about this Privacy Policy, or the Platforms, you may contact our grievance officer on the below mentioned details:

Name: Sachin Chopra
Designation: Grievance Officer
Email Address: sachin@kkfabric.com
</Text>

<Heading size={'md'} mb={1} fontWeight={600}>Governing Law</Heading>
<Text mb={4} fontSize={'13px'}>
The terms of this Privacy Policy shall be governed and construed in accordance with the laws of India. Any dispute regarding or arising out of this Privacy Policy shall be subject to the exclusive jurisdiction of the courts in Ludhiana, Punjab.
</Text>

<Heading size={'md'} mb={1} fontWeight={600}>Severability</Heading>
<Text mb={4} fontSize={'13px'}>
Whenever possible, each section of this Privacy Policy shall be interpreted in a manner so as to be valid under applicable law. However, in the event any provision is held to be prohibited or invalid, such provision shall be ineffective only to the extent of such prohibition or invalidity, without invalidating the remainder of such provision or other remaining provisions of this Privacy Policy.
</Text>

      </Container>
    </Box>
    <Footer></Footer>
    </div>
  );
};

export default PrivacyPolicy;
