import {
  Center,
  Box,
  Image,
  Heading,
  Button,
  Text,
  HStack,
  PinInput,
  PinInputField,
  Spinner,
  Show,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resendOtp,
  resetRegisterPhaseStatus,
  verifyPhoneOtp,
} from "../Redux/AuthReducer/Action";
import Navbar2 from "../Components/Navbar2";
import showCustomToast from "../Components/showCustomToast";

const Otp = () => {
  const {
    isAuth,
    isErrorMessage,
    registerPhaseStatus,
    userData,
    isLoading,
    phonenumber,
  } = useSelector((store) => store.AuthReducer);
  const navigate = useNavigate();
  const [mobileOtpValue, setMobileValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (registerPhaseStatus) {
      dispatch(resetRegisterPhaseStatus());
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuth) {
      navigate("/");
    }
  });

  useEffect(() => {
    if (isErrorMessage) {
      showCustomToast("Authentication failed", "error");
    }
  }, [isErrorMessage]);

  const handleMobileOtpSubmit = () => {
    if (mobileOtpValue.length === 6) {
      const params = {
        mobile: phonenumber,
        mobile_otp: mobileOtpValue,
      };
      dispatch(verifyPhoneOtp(params));
    } else {
      showCustomToast("Enter Complete Otp.", "error");
    }
  };

  const handleResendotp = () => {
    const params = {
      identifier: userData.email,
    };
    dispatch(resendOtp(params)); //
  };

  return (
    <>
      <Box>
        <Navbar2 />
        {isLoading && (
          <Box
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={9999}
            backgroundColor="rgba(0, 0, 0, 0.5)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size="xl" color="#ffffff" />
          </Box>
        )}
        <Center
          // w={"full"}
          bgColor={window.innerWidth > 500 ? "#fceeea" : "#d3d3d361"}
          // h={"100vh"}
          // bgColor="red"
        >
          <Box w={"400px"} p="50px" bgColor={"white"} mt={10} mb={10}>
            <Box>
              <Image
                size="50px"
                boxSize={"100px"}
                src="https://constant.myntassets.com/pwa/assets/img/3a438cb4-c9bf-4316-b60c-c63e40a1a96d1548071106233-mobile-verification.jpg"
              />
            </Box>

            <Box textAlign={"left"} mt={2} mb={2}>
              <Heading
                fontWeight={"600"}
                as={"h2"}
                color="#424553"
                fontSize="20px"
                size="lg"
              >
                Verify with OTP
              </Heading>
              <Text fontSize={"12px"} mt={2} color={"#a7a9af"}>
                Send to {phonenumber}
              </Text>
            </Box>
            {/* <Text fontSize={"12px"} fontWeight={'bold'} mt={4} color={"#a7a9af"} >Email</Text>
            <HStack
              m={"5px 0px 25px 0px"}
            >

              <PinInput value={emailOtpValue} onChange={setEmailOtpValue} otp mask placeholder='&#10146;'>
                <PinInputField w="30px" h="40px" />
                <PinInputField w="30px" h="40px" />
                <PinInputField w="30px" h="40px" />
                <PinInputField w="30px" h="40px" />
                <PinInputField w="30px" h="40px" />
                <PinInputField w="30px" h="40px" />
              </PinInput>
            </HStack> */}
            <Text
              fontSize={"12px"}
              fontWeight={"bold"}
              mt={4}
              color={"#a7a9af"}
            >
              Mobile
            </Text>
            <HStack m={"10px 0px 25px 0px"}>
              <PinInput
                value={mobileOtpValue}
                onChange={setMobileValue}
                otp
                mask
                placeholder="&#10146;"
              >
                <PinInputField w="30px" h="40px" />
                <PinInputField w="30px" h="40px" />
                <PinInputField w="30px" h="40px" />
                <PinInputField w="30px" h="40px" />
                <PinInputField w="30px" h="40px" />
                <PinInputField w="30px" h="40px" />
              </PinInput>
            </HStack>

            <Box mb={8}>
              <HStack justifyContent={"space-between"} alignItems={"center"}>
                <Show breakpoint="(min-width: 500px)">
                  <Button
                    // mt={8}
                    // mb={4}
                    size="sm"
                    variant="solid"
                    backgroundColor="#ff3f6c"
                    color={"#fff"}
                    borderRadius="5"
                    colorScheme={"none"}
                    type="submit"
                    onClick={handleMobileOtpSubmit}
                  >
                    VERIFY
                  </Button>
                </Show>
                <Show breakpoint="(max-width: 500px)">
                  <Button
                    // mt={8}
                    // mb={4}
                    size="sm"
                    variant="solid"
                    backgroundColor="#000000"
                    color={"#fff"}
                    borderRadius="5"
                    colorScheme={"none"}
                    type="submit"
                    onClick={handleMobileOtpSubmit}
                  >
                    VERIFY
                  </Button>
                </Show>

                <Text
                  textAlign={"left"}
                  color={"red"}
                  fontWeight="bold"
                  fontSize={"12px"}
                  cursor={"pointer"}
                  onClick={handleResendotp}
                >
                  RESEND OTP
                </Text>
              </HStack>
            </Box>
            <Show breakpoint="(min-width: 500px)">
              <Text color={"#a7a9af"} fontSize="12px" textAlign="left">
                Log in using{" "}
                <Text
                  as={"span"}
                  onClick={() => navigate("/login")}
                  cursor={"pointer"}
                  fontWeight={"bold"}
                  _hover={{ textDecoration: "none" }}
                  color={"red"}
                  href="#"
                >
                  Mobile Number
                </Text>
              </Text>
            </Show>

            {/* <Text mt={8} color={"#a7a9af"} fontSize="12px" textAlign="left">
              Having trouble logging in? <Text fontWeight={"bold"} _hover={{ textDecoration: "none" }} color={"#ff3f6c"} href="#" >Get help</Text>
            </Text> */}
          </Box>
        </Center>
      </Box>
    </>
  );
};

export default Otp;
