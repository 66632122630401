import { useDispatch, useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import { getCart } from "../Redux/CartReducer/Action"
import React, { useState, useEffect } from 'react'
import { useSearchParams } from "react-router-dom"

export const PrivateRoute=({children})=>{
    const isAuth=useSelector((store)=>store.AuthReducer.isAuth)
    const location=useLocation()

    if(!isAuth){
        return <Navigate to={"/login"}  state={{data:location.pathname}} replace />
    }
    return children
}

export const PrivateRoute2=({children})=>{
    const isAuth=useSelector((store)=>store.AuthReducer.isAuth)
    const location=useLocation();

    //console.log("checking auth value:",isAuth)

    if(isAuth){
        return <Navigate to={"/"}  state={{data:location.pathname}} replace />
    }
    return children
}


export const PrivateStore = ({ children }) => {
      //eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    // const isAuth = useSelector((store) => store.AuthReducer.isAuth);
    const location = useLocation();

    //console.log("checking auth value:", isAuth);

    const params = new URLSearchParams(location.search);
    const type = params.get('type');

    if (!type) {

            return <Navigate to={"/store?type=1"}  state={{data:location.pathname}} replace />

    } else {
        return children;
    }


  };


export const PrivateRoute3=({children})=>{

    const { Cart} = useSelector((store) => store.CartReducer);
    const {isAuth,userData}=useSelector((store)=>store.AuthReducer)
     //eslint-disable-next-line
    const [cart,setCart]=useState([]);
    const location=useLocation();
    const dispatch=useDispatch();


    //console.log("private:",Cart)
    const params={
        user_id:userData.id
    };

    useEffect(() => {

        dispatch(getCart(params));
          // eslint-disable-next-line
       }, []);

       useEffect(() => {

        setCart(Cart);
       },[Cart]);


    if (isAuth && Cart.length === 0) {

        return <Navigate to={"/cart"}  state={{data:location.pathname}} replace />
    }
    // else if(isAuth && Cart === 0)
    // {
    //     return <Navigate to={"/store"}  state={{data:location.pathname}} replace />
    // }
    else{
        return children
    }


}

