import React from "react";
import {
  Box,
  VStack,
  Checkbox,
  Text,
  CheckboxGroup,
  HStack,
  Skeleton,
  RadioGroup,
  Radio,
  Accordion,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
  Grid,
  GridItem,
  Flex,
  Spacer,
  Show,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";

const Skeletons = () => {
  return (
    <Box>
      {[...Array(4)].map((_, index) => (
        <Skeleton key={index} height="20px" width="100px" mt="1" />
      ))}
    </Box>
  );
};

const Filter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  // ...........................
  const [reset, setReset] = useState(false);

  const initType = searchParams.getAll("type");
  const [type, setType] = useState(initType || []);
  const [typeChange, setTypeChange] = useState([]);

  // const [typeChange, setTypeChange] = useState("");

  // ...........................

  const initCategory = searchParams.getAll("category");
  const initInnerSubCategory = searchParams.getAll("innerSubCategory");
  const [category, setCategory] = useState(initCategory || []);
  const [innerSubCategory, setInnerSubCategory] = useState(
    initInnerSubCategory || []
  );
  // const [innerSubCategorySubcat, setInnerSubCategorySubcat] = useState([]);
  const initSortBy = searchParams.getAll("sortBy");
  const [sortBy, setSortBy] = useState(initSortBy || []);
  // ...........................
  const initBrand = searchParams.getAll("brand");
  const [brand, setBrand] = useState(initBrand || []);
  const [unChangedfilter, setUnChangedfilterFilter] = useState({
    category: [],
    brand: [],
    subcategory: [],
    innersubcategories: [],
    price: [],
    discount: [],
    size: [],
    color: [],
  });
  const [filter, setFilter] = useState({
    category: [],
    brand: [],
    subcategory: [],
    innersubcategories: [],
    price: [],
    discount: [],
    size: [],
    color: [],
  });

  const [filterCategoryClicked, setFilterCategoryClicked] = useState("Gender");
  // ............................

  const initPrice = searchParams.getAll("price");
  const [price, setPrice] = useState(initPrice || []);

  const initColor = searchParams.getAll("color");
  const [color, setColor] = useState(initColor || []);
  // ............................
  //const navigateType = searchParams.get("navigateType");
  const initDiscount = searchParams.getAll("discount");
  const [discount, setDiscount] = useState(initDiscount || []);

  const initBestSeller = searchParams.get("bestSeller");
  //eslint-disable-next-line
  const [bestSeller, setBestSeller] = useState(initBestSeller || []);

  const initSize = searchParams.getAll("size");
  const [size, setSize] = useState(initSize || []);

  // ............................

  const initQuery = searchParams.get("q");
  const [q, setQuery] = useState(initQuery || "");

  const handleType = (el) => {
    setType(el);
    setTypeChange(el);

    //setTypeChange(!typeChange);
  };

  const handleCategory = (el) => {
    setCategory(el);
  };

  const handleInnerSubcategory = (items) => {
    //     const selectedSubcatIds = unChangedfilter.innersubcategories
    // .filter((item) => items.includes(item.id.toString()))
    // .map((item) => item.subcat_id);
    // const selectedSubcatIds = unChangedfilter.innersubcategories
    //   .filter((item) => items.includes(item.id.toString()))
    //   .map((item) => ({ subcat_id: item.subcat_id, id: item.id }));
    setInnerSubCategory(items);
    // setInnerSubCategorySubcat(selectedSubcatIds);
    // console.log("selectedSubcatIds:", selectedSubcatIds);
  };

  const handlePrice = (el) => {
    setPrice(el);
  };

  const handleDiscount = (el) => {
    setDiscount(el);
  };

  const handleSize = (el) => {
    setSize(el);
  };
  const handleColor = (el) => {
    setColor(el);
  };

  const handleSubcategoryUpdateFilter = () => {
    const typeValue = parseInt(initType);
    if (!isNaN(typeValue)) {
      // console.log("filter 7 before", unChangedfilter);
      const filteredSubcategory = unChangedfilter.subcategory.filter(
        (subcategoryItem) => {
          //eslint-disable-next-line
          return subcategoryItem.cat_id == type;
        }
      );

      setFilter((prevFilter) => ({
        ...prevFilter,
        subcategory: filteredSubcategory,
      }));
    }
  };
  const handleInnerSubCategoryUpdateFilter = () => {
    const typeValue = parseInt(initType);

    if (!isNaN(typeValue)) {
      var filteredInnerSubcategories =
        unChangedfilter.innersubcategories.filter((item) => {
          // console.log("typeValues11:", category);
          return (
            //eslint-disable-next-line
            item.cat_id == type &&
            (category.length === 0 ||
              category.includes(item.subcat_id.toString()))
          );
        });
    } else {
      //eslint-disable-next-line
      var filteredInnerSubcategories =
        unChangedfilter.innersubcategories.filter((item) => {
          return (
            category.length === 0 ||
            category.includes(item.subcat_id.toString())
          );
        });
    }

    setFilter((prevFilter) => ({
      ...prevFilter,
      innersubcategories: filteredInnerSubcategories,
    }));
  };

  useEffect(() => {
    const typeValue = parseInt(initType);

    if (!isNaN(typeValue)) {
      // alert("type");

      handleSubcategoryUpdateFilter();
      handleInnerSubCategoryUpdateFilter();
    }
    //eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    if (category) {
      const params = {};
      type && (params.type = type);
      q && (params.q = q);
      // console.log("typechange", typeChange);
      // setSearchParams(params);
      if (typeChange.length > 0) {
        setBestSeller([]);
        setSearchParams(params);
      }

      //  setSearchParams(params);

      // setSearchParams(params);
      // setType(type);
      // setQuery(q);
      // setInnerSubCategory([]);
      // setInnerSubCategorySubcat([]);
      // setCategory([]);
      // setDiscount([]);
    }
    //eslint-disable-next-line
  }, [typeChange]);

  // useEffect(() => {

  //     setSearchParams(new URLSearchParams(`type=${type}`));

  // }, [typeChange]);

  useEffect(() => {
    const typeValue = parseInt(initType);
    handleSubcategoryUpdateFilter();
    // Check if typeValue is a valid integer before proceeding
    if (!isNaN(typeValue)) {
      //  alert(typeValue)

      const filteredInnerSubcategories =
        unChangedfilter.innersubcategories.filter((item) => {
          return (
            //eslint-disable-next-line
            item.cat_id == type &&
            (category.length === 0 ||
              category.includes(item.subcat_id.toString()))
          );
        });

      // console.log("innersubcategoriesData", filteredInnerSubcategories);

      //eslint-disable-next-line
      // const updatedInnerSubCategorySubCat = innerSubCategorySubcat.filter((subcat) => {
      //   return filteredInnerSubcategories.some((item) => {
      //     return item.subcat_id === subcat.subcat_id;
      //   });
      // });
      // const params = new URLSearchParams(location.search);
      // console.log(
      //   "innerCategoryArray dependency",
      //   params.getAll("innerSubCategory")
      // );
      // if (innerSubCategorySubcat.length > 0) {
      //   if (params.getAll("innerSubCategory").length > 0) {
      //   const updatedInnerSubCategory = innerSubCategorySubcat
      //     .filter((subcat) =>
      //       filteredInnerSubcategories.some(
      //         (item) => item.subcat_id === subcat.subcat_id
      //       )
      //     )
      //     .map((subcat) => String(subcat.id));
      //   setInnerSubCategory(updatedInnerSubCategory);
      //   // setInnerSubCategorySubcat(updatedInnerSubCategorySubCat);
      // }

      setFilter((prevFilter) => ({
        ...prevFilter,
        innersubcategories: filteredInnerSubcategories,
      }));
    }
    // setInnerSubCategory(updatedInnerSubCategory);
    //eslint-disable-next-line
  }, [category]); ///its sub category not category in terms of ui

  useEffect(() => {
    //  const typeValue = parseInt(initType);

    handleInnerSubCategoryUpdateFilter();
    // if (!isNaN(typeValue)) {
    // handleInnerSubCategoryUpdateFilter();
    // }

    //eslint-disable-next-line
  }, [filter.subcategory]); ///its sub category not category in terms of ui

  useEffect(() => {
    handleSubcategoryUpdateFilter();
    handleInnerSubCategoryUpdateFilter();
    //eslint-disable-next-line
  }, [innerSubCategory]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // console.log("typechange 1->", params.get("type"), " ", type);
    //eslint-disable-next-line
    if (params.get("type") != type) {
      if (params.get("type")) {
        setTypeChange(params.get("type"));
      }
    }
    const types = params.get("type");
    const sortBy = params.get("sortBy");
    // console.log("filters:", types);
    setType(types);
    //setTypeChange(type)
    setSortBy(sortBy);

    ///here/////
    const categoryArray = params.getAll("category");
    const innerCategoryArray = params.getAll("innerSubCategory");
    const discount = params.getAll("discount");
    const price = params.getAll("price");
    const color = params.getAll("color");
    const size = params.getAll("size");
    // console.log("innerCategoryArray 1", innerCategoryArray);
    // console.log("innerCategoryArray 2", innerSubCategorySubcat);
    setDiscount(discount.length > 0 ? discount : []);
    setPrice(price.length > 0 ? price : []);
    setColor(color.length > 0 ? color : []);
    setSize(size.length > 0 ? size : []);
    setCategory(categoryArray.length > 0 ? categoryArray : []);
    // if(innerCategoryArray.length === 0 )
    // {
    //   setInnerSubCategorySubcat([]);
    // }
    setInnerSubCategory(
      innerCategoryArray.length > 0 ? innerCategoryArray : []
    );
    //eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categoryArray = params.getAll("category");
    const innerCategoryArray = params.getAll("innerSubCategory");
    setCategory(categoryArray.length > 0 ? categoryArray : []);
    setInnerSubCategory(
      innerCategoryArray.length > 0 ? innerCategoryArray : []
    );

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // const params = {};
    // q && (params.q = q);
    // setSearchParams(params);
    setQuery(initQuery);
    if (initQuery) {
      // console.log("innerCategoryArray init");
      setType([]);
      setTypeChange([]);

      setDiscount([]);
      setCategory([]);
      setInnerSubCategory([]);
      setPrice([]);
      setColor([]);
      setSize([]);
    }

    setReset(!reset);
    //eslint-disable-next-line
  }, [initQuery]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/fetchFilters`
        );
        const {
          brand: brandsData,
          category: categoryData,
          subcategory: subcategoriesData,
          innersubcategories: innersubcategoriesData,
          price: priceData,
          discount: discountData,
          size: sizeData,
          color: colorData,
        } = response.data.data;
        const params = new URLSearchParams(location.search);
        const types = params.get("type");

        const sub = types
          ? subcategoriesData.filter(
              //eslint-disable-next-line
              (subcategoryItem) => subcategoryItem.cat_id == types
            )
          : subcategoriesData;

        const inner = types
          ? //eslint-disable-next-line
            innersubcategoriesData.filter((item) => item.cat_id == types)
          : innersubcategoriesData;

        const data = {
          brand: brandsData,
          subcategory: sub,
          innersubcategories: inner,
          price: priceData,
          discount: discountData,
          size: sizeData,
          category: categoryData,
          color: colorData,
        };
        setFilter(data);
        setUnChangedfilterFilter({
          brand: brandsData,
          subcategory: subcategoriesData,
          innersubcategories: innersubcategoriesData,
          price: priceData,
          discount: discountData,
          size: sizeData,
          category: categoryData,
          color: colorData,
        });
        setIsLoading(false);
      } catch (error) {
        // console.error("Error fetching brands:", error);
        setIsLoading(false);
      }
    };
    fetchBrands();
    //eslint-disable-next-line
  }, [reset]);

  useEffect(() => {
    const params = {};
    type && (params.type = type);
    category && (params.category = category);
    innerSubCategory && (params.innerSubCategory = innerSubCategory);
    brand && (params.brand = brand);
    price && (params.price = price);
    color && (params.color = color);
    discount && (params.discount = discount);
    size && (params.size = size);
    sortBy && (params.sortBy = sortBy);
    bestSeller && (params.bestSeller = bestSeller);

    if (q !== "") {
      q && (params.q = q);
    }
    setSearchParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // type,
    setType,
    category,
    innerSubCategory,
    brand,
    setBrand,
    price,
    setPrice,
    color,
    setColor,
    discount,
    setDiscount,
    size,
    setSize,
    q,
    sortBy,
    setSortBy,
    setQuery,
  ]);

  const FilterClicked = (data) => {
    // console.log(data);
    setFilterCategoryClicked(data);
  };

  return (
    <>
      <Show breakpoint="(max-width: 500px)">
        <Grid
          h={"300%"}
          templateRows="repeat(2)"
          templateColumns="repeat(5, 1fr)"
          gap={0}
        >
          <GridItem colSpan={2} bg="#f3f3f3">
            <Flex>
              <Box
                px={2}
                pt={3}
                fontSize={14}
                flex={"90%"}
                onClick={() => {
                  FilterClicked("Gender");
                }}
              >
                Gender
              </Box>
              <Spacer />
              <Box flex={"10%"} pr={4} pt={2}>
                {" "}
                <p style={{ color: "#000000" }}>
                  <span>
                    {" "}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size="sm"
                      style={{ color: "rgba(128, 128, 128, 0.8)" }}
                    />
                  </span>
                </p>
              </Box>
            </Flex>
            <Flex>
              <Box
                px={2}
                pt={3}
                fontSize={14}
                flex={"90%"}
                onClick={() => {
                  FilterClicked("Category");
                }}
              >
                Category
              </Box>
              <Spacer />
              <Box flex={"10%"} pr={4} pt={2}>
                {" "}
                <p style={{ color: "#000000" }}>
                  <span>
                    {" "}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size="sm"
                      style={{ color: "rgba(128, 128, 128, 0.8)" }}
                    />
                  </span>
                </p>
              </Box>
            </Flex>
            <Flex>
              <Box
                px={2}
                pt={3}
                fontSize={14}
                flex={"90%"}
                onClick={() => {
                  FilterClicked("Product Type");
                }}
              >
                Product Type
              </Box>
              <Spacer />
              <Box flex={"10%"} pr={4} pt={2}>
                {" "}
                <p style={{ color: "#000000" }}>
                  <span>
                    {" "}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size="sm"
                      style={{ color: "rgba(128, 128, 128, 0.8)" }}
                    />
                  </span>
                </p>
              </Box>
            </Flex>
            <Flex>
              <Box
                px={2}
                pt={3}
                fontSize={14}
                flex={"90%"}
                onClick={() => {
                  FilterClicked("Discount");
                }}
              >
                Discount
              </Box>
              <Spacer />
              <Box flex={"10%"} pr={4} pt={2}>
                {" "}
                <p style={{ color: "#000000" }}>
                  <span>
                    {" "}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size="sm"
                      style={{ color: "rgba(128, 128, 128, 0.8)" }}
                    />
                  </span>
                </p>
              </Box>
            </Flex>
            <Flex>
              <Box
                px={2}
                pt={3}
                fontSize={14}
                flex={"90%"}
                onClick={() => {
                  FilterClicked("Size");
                }}
              >
                Size
              </Box>
              <Spacer />
              <Box flex={"10%"} pr={4} pt={2}>
                {" "}
                <p style={{ color: "#000000" }}>
                  <span>
                    {" "}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size="sm"
                      style={{ color: "rgba(128, 128, 128, 0.8)" }}
                    />
                  </span>
                </p>
              </Box>
            </Flex>
            <Flex>
              <Box
                px={2}
                pt={3}
                fontSize={14}
                flex={"90%"}
                onClick={() => {
                  FilterClicked("Price");
                }}
              >
                Price
              </Box>
              <Spacer />
              <Box flex={"10%"} pr={4} pt={2}>
                {" "}
                <p style={{ color: "#000000" }}>
                  <span>
                    {" "}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size="sm"
                      style={{ color: "rgba(128, 128, 128, 0.8)" }}
                    />
                  </span>
                </p>
              </Box>
            </Flex>
            <Flex>
              <Box
                px={2}
                pt={3}
                fontSize={14}
                flex={"90%"}
                onClick={() => {
                  FilterClicked("Color");
                }}
              >
                Color
              </Box>
              <Spacer />
              <Box flex={"10%"} pr={4} pt={2}>
                {" "}
                <p style={{ color: "#000000" }}>
                  <span>
                    {" "}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      size="sm"
                      style={{ color: "rgba(128, 128, 128, 0.8)" }}
                    />
                  </span>
                </p>
              </Box>
            </Flex>
          </GridItem>
          <GridItem colSpan={3} p={2}>
            {filterCategoryClicked === "Gender" ? (
              <RadioGroup
                value={type}
                onChange={handleType}
                // colorScheme="red"
                style={{
                  "--chakra-colors-blue-500": "#000000",
                  "--chakra-colors-blue-600": "#000000",
                }}
                size={"md"}
              >
                <VStack alignItems={"flex-start"} spacing={1}>
                  {filter.category.map((item) => (
                    <Radio value={item.id.toString()}>{item.title}</Radio>
                  ))}
                </VStack>
              </RadioGroup>
            ) : (
              <></>
            )}

            {filterCategoryClicked === "Category" ? (
              <CheckboxGroup
                // colorScheme="red"
                size="md"
                onChange={handleCategory}
                value={category}
              >
                <VStack alignItems="flex-start" mt="1" spacing={1}>
                  {filter.subcategory.map((item) => (
                    <Checkbox
                      style={{
                        "--chakra-colors-blue-500": "#000000",
                        "--chakra-colors-blue-600": "#000000",
                      }}
                      key={item.id}
                      value={item.id.toString()}
                      isChecked={category.includes(item.id.toString())}
                    >
                      {item.title}
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            ) : (
              <></>
            )}
            {filterCategoryClicked === "Product Type" ? (
              <CheckboxGroup
                size="md"
                onChange={(newInnerSubCategory) =>
                  handleInnerSubcategory(newInnerSubCategory)
                }
                value={innerSubCategory}
              >
                <VStack alignItems="flex-start" mt="1" spacing={1}>
                  {filter.innersubcategories.map((item) => (
                    <Checkbox
                      style={{
                        "--chakra-colors-blue-500": "#000000",
                        "--chakra-colors-blue-600": "#000000",
                      }}
                      key={item.id}
                      value={item.id.toString()}
                      isChecked={innerSubCategory.includes(item.id.toString())}
                    >
                      {item.title}
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            ) : (
              <></>
            )}
            {filterCategoryClicked === "Discount" ? (
              <CheckboxGroup
                size="md"
                onChange={handleDiscount}
                value={discount}
              >
                <VStack alignItems="flex-start" mt="1" spacing={1}>
                  {filter.discount.map((item) => (
                    <Checkbox
                      style={{
                        "--chakra-colors-blue-500": "#000000",
                        "--chakra-colors-blue-600": "#000000",
                      }}
                      key={item.id}
                      value={item.id.toString()}
                      isChecked={discount.includes(item.id.toString())}
                    >
                      {item.title}
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            ) : (
              <></>
            )}
            {filterCategoryClicked === "Size" ? (
              <CheckboxGroup size="md" onChange={handleSize} value={size}>
                <VStack alignItems="flex-start" mt="1" spacing={1}>
                  {filter.size.map((item) => (
                    <Checkbox
                      style={{
                        "--chakra-colors-blue-500": "#000000",
                        "--chakra-colors-blue-600": "#000000",
                      }}
                      key={item.id}
                      value={item.id.toString()}
                      isChecked={size.includes(item.id.toString())}
                    >
                      {item.title}
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            ) : (
              <></>
            )}

            {filterCategoryClicked === "Price" ? (
              <CheckboxGroup size="md" value={price} onChange={handlePrice}>
                <VStack alignItems="flex-start" mt="1" spacing={1}>
                  {filter.price.map((item) => (
                    <Checkbox
                      style={{
                        "--chakra-colors-blue-500": "#000000",
                        "--chakra-colors-blue-600": "#000000",
                      }}
                      key={item.id}
                      value={item.id.toString()}
                      isChecked={price.includes(item.id.toString())}
                    >
                      {item.title}
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            ) : (
              <></>
            )}
            {filterCategoryClicked === "Color" ? (
              <CheckboxGroup size="md" value={color} onChange={handleColor}>
                <VStack alignItems="flex-start" mt="1" spacing={1}>
                  {filter.color.map((item) => (
                    <Checkbox
                      style={{
                        "--chakra-colors-blue-500": "#000000",
                        "--chakra-colors-blue-600": "#000000",
                      }}
                      key={item.id}
                      value={item.id.toString()}
                      isChecked={color.includes(item.id.toString())}
                    >
                      <Flex
                        flexDirection={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        marginY={2}
                      >
                        <Box
                          height={4}
                          width={6}
                          backgroundColor={item.code}
                          marginX={4}
                          border={
                            item.id.toString() === "white"
                              ? "1px solid #808080"
                              : ""
                          }
                          boxShadow="1px 1px 3px rgba(0, 0, 0, 0.2)"
                        ></Box>
                        <Text> {item.title}</Text>
                      </Flex>
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            ) : (
              <></>
            )}
          </GridItem>
        </Grid>
      </Show>
      <Show breakpoint="(min-width: 500px)">
        <VStack
          alignItems={"flex-start"}
          spacing={1}
          position={"sticky"}
          top={"7%"}
          bottom={"1000px"}
          bg={"#f3f3f3"}
          mx={1}
        >
          <HStack mt={"8px"} pl={4} fontWeight={700}>
            FILTERS
          </HStack>
          {/* <Divider /> */}
          <Box pl={4} w={"100%"}>
            <Accordion w={"full"} defaultIndex={[0]} allowMultiple>
              <AccordionItem w={"full"}>
                <h2>
                  <AccordionButton w={"full"} borderBottomWidth={0}>
                    <Text
                      fontSize="14px"
                      fontWeight={600}
                      color="#282c3f"
                      textAlign="left"
                    >
                      GENDER
                    </Text>
                    <ChevronDownIcon marginLeft="auto" />
                  </AccordionButton>
                </h2>
                <AccordionPanel>
                  <RadioGroup
                    value={type}
                    onChange={handleType}
                    colorScheme="red"
                    size={"sm"}
                  >
                    <VStack alignItems={"flex-start"} spacing={1}>
                      {filter.category.map((item) => (
                        <Radio value={item.id.toString()}>{item.title}</Radio>
                      ))}
                    </VStack>
                  </RadioGroup>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>

          <Box pl={4} w={"100%"}>
            {filter.subcategory.length > 0 && (
              <Accordion defaultIndex={[1]} allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Text
                        fontSize="14px"
                        fontWeight={600}
                        color="#282c3f"
                        textAlign="left"
                      >
                        CATEGORY
                      </Text>
                      <ChevronDownIcon marginLeft="auto" />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    {isLoading ? (
                      <Skeletons />
                    ) : (
                      <CheckboxGroup
                        colorScheme="red"
                        size="sm"
                        onChange={handleCategory}
                        value={category}
                      >
                        <VStack alignItems="flex-start" mt="1" spacing={1}>
                          {filter.subcategory.map((item) => (
                            <Checkbox
                              key={item.id}
                              value={item.id.toString()}
                              isChecked={category.includes(item.id.toString())}
                            >
                              {item.title}
                            </Checkbox>
                          ))}
                        </VStack>
                      </CheckboxGroup>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </Box>

          <Box pl={4} w={"100%"}>
            {filter.subcategory.length > 0 && (
              <Accordion defaultIndex={[2]} allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Text
                        fontSize="14px"
                        fontWeight={600}
                        color="#282c3f"
                        textAlign="left"
                      >
                        PRODUCT TYPE
                      </Text>
                      <ChevronDownIcon marginLeft="auto" />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    {isLoading ? (
                      <Skeletons />
                    ) : (
                      <CheckboxGroup
                        colorScheme="red"
                        size="sm"
                        onChange={(newInnerSubCategory) =>
                          handleInnerSubcategory(newInnerSubCategory)
                        }
                        value={innerSubCategory}
                      >
                        <VStack alignItems="flex-start" mt="1" spacing={1}>
                          {filter.innersubcategories.map((item) => (
                            <Checkbox
                              key={item.id}
                              value={item.id.toString()}
                              isChecked={innerSubCategory.includes(
                                item.id.toString()
                              )}
                            >
                              {item.title}
                            </Checkbox>
                          ))}
                        </VStack>
                      </CheckboxGroup>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </Box>

          {/* <Divider /> */}
          <Box pl={4} w={"100%"}>
            {filter.discount.length > 0 && (
              <Accordion defaultIndex={[3]} allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Text
                        fontSize="14px"
                        fontWeight={600}
                        color="#282c3f"
                        textAlign="left"
                      >
                        DISCOUNT
                      </Text>
                      <ChevronDownIcon marginLeft="auto" />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    {isLoading ? (
                      <Skeletons />
                    ) : (
                      <CheckboxGroup
                        colorScheme="red"
                        size="sm"
                        onChange={handleDiscount}
                        value={discount}
                      >
                        <VStack alignItems="flex-start" mt="1" spacing={1}>
                          {filter.discount.map((item) => (
                            <Checkbox
                              key={item.id}
                              value={item.id.toString()}
                              isChecked={discount.includes(item.id.toString())}
                            >
                              {item.title}
                            </Checkbox>
                          ))}
                        </VStack>
                      </CheckboxGroup>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </Box>

          <Box pl={4} w={"100%"}>
            {filter.size.length > 0 && (
              <Accordion defaultIndex={[4]} allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Text
                        fontSize="14px"
                        fontWeight={600}
                        color="#282c3f"
                        textAlign="left"
                      >
                        SIZE
                      </Text>
                      <ChevronDownIcon marginLeft="auto" />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    {isLoading ? (
                      <Skeletons />
                    ) : (
                      <CheckboxGroup
                        colorScheme="red"
                        size="sm"
                        onChange={handleSize}
                        value={size}
                      >
                        <VStack alignItems="flex-start" mt="1" spacing={1}>
                          {filter.size.map((item) => (
                            <Checkbox
                              key={item.id}
                              value={item.id.toString()}
                              isChecked={size.includes(item.id.toString())}
                            >
                              {item.title}
                            </Checkbox>
                          ))}
                        </VStack>
                      </CheckboxGroup>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </Box>
          <Box pl={4} w={"100%"}>
            {filter.price.length > 0 && (
              <Accordion defaultIndex={[5]} allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Text
                        fontSize="14px"
                        fontWeight={600}
                        color="#282c3f"
                        textAlign="left"
                      >
                        PRICE
                      </Text>
                      <ChevronDownIcon marginLeft="auto" />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    {isLoading ? (
                      <Skeletons />
                    ) : (
                      <CheckboxGroup
                        colorScheme="red"
                        size="sm"
                        value={price}
                        onChange={handlePrice}
                      >
                        <VStack alignItems="flex-start" mt="1" spacing={1}>
                          {filter.price.map((item) => (
                            <Checkbox
                              key={item.id}
                              value={item.id.toString()}
                              isChecked={price.includes(item.id.toString())}
                            >
                              {item.title}
                            </Checkbox>
                          ))}
                        </VStack>
                      </CheckboxGroup>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </Box>
          <Box pl={4} w={"100%"}>
            {filter.color.length > 0 && (
              <Accordion defaultIndex={[5]} allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Text
                        fontSize="14px"
                        fontWeight={600}
                        color="#282c3f"
                        textAlign="left"
                      >
                        COLOR
                      </Text>
                      <ChevronDownIcon marginLeft="auto" />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    {isLoading ? (
                      <Skeletons />
                    ) : (
                      <CheckboxGroup
                        colorScheme="red"
                        size="sm"
                        value={color}
                        onChange={handleColor}
                      >
                        <VStack alignItems="flex-start" mt="1" spacing={1}>
                          {filter.color.map((item) => (
                            <Checkbox
                              style={{
                                "--chakra-colors-blue-500": "#000000",
                                "--chakra-colors-blue-600": "#000000",
                              }}
                              key={item.id}
                              value={item.id.toString()}
                              isChecked={color.includes(item.id.toString())}
                            >
                              <Flex
                                flexDirection={"row"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                                marginY={2}
                              >
                                <Box
                                  height={4}
                                  width={6}
                                  // backgroundColor={item.code}
                                  backgroundColor={item.code}
                                  marginX={4}
                                  border={
                                    item.id.toString() === "white"
                                      ? "1px solid #808080"
                                      : ""
                                  }
                                  boxShadow="1px 1px 3px rgba(0, 0, 0, 0.2)"
                                ></Box>
                                <Text> {item.title}</Text>
                              </Flex>
                            </Checkbox>
                          ))}
                        </VStack>
                      </CheckboxGroup>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </Box>
        </VStack>
      </Show>
    </>
  );
};

export default Filter;
