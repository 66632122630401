import { Box, Image, Skeleton } from '@chakra-ui/react';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CarouselCom = ({ data, isLoading }) => {
  return (
    <>
      {window.innerWidth < 500 ? (
        <Box mb={3} style={{ cursor: 'pointer' }}>
          {isLoading ? (
            <Skeleton height="300px" width="100%" />
          ) : (
            <Carousel
              responsive={responsive}
              additionalTransfrom={0}
              autoPlay
              autoPlaySpeed={1500}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={true}
              sliderClass=""
              slidesToSlide={1}
              swipeable
              removeArrowOnDeviceType={['desktop', 'mobile', 'tablet']}
            >
              {data.filter((item) => item.type === 2).map((item, id) => (
                <Box key={id} mb={10} width="100%">
                  <Image
                    src={item.image_url}
                    maxWidth="100%"
                    objectFit="cover"
                    onClick={() => (window.location.href = item.link)}
                  />
                </Box>
              ))}
            </Carousel>
          )}
        </Box>
      ) : (
        <Box mb={3} style={{ cursor: 'pointer' }}>
          {isLoading ? (
            <Skeleton height="300px" width="100%" />
          ) : (
            <Carousel
              responsive={responsive}
              additionalTransfrom={0}
              autoPlay
              autoPlaySpeed={1500}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots={true}
              sliderClass=""
              slidesToSlide={1}
              swipeable
              removeArrowOnDeviceType={['desktop', 'mobile', 'tablet']}
            >
              {data.filter((item) => item.type === 1).map((item, id) => (
                <Box key={id} mb={10} width="100%">
                  <Image
                    src={item.image_url}
                    maxWidth="100%"
                    objectFit="cover"
                    onClick={() => (window.location.href = item.link)}
                  />
                </Box>
              ))}
            </Carousel>
          )}
        </Box>
      )}
    </>
  );
};

export default CarouselCom;
