import * as types from "./ActionTypes";

const initialState = {
  Products: [],
  ProductsDetail: [],
  NavData: [],
  ProductHeading: "",
  isLoading: false,
  isError: false,
  isProductsEmpty: true,
  Total: 0,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.GET_STATE_CLEAR:
      return {
        ...state,
        Products: [],
        ProductsDetail: [],
        ProductHeading: "",
        isLoading: false,
        isError: false,
        isProductsEmpty: true,
        Total: 0,
      };
    // isLoading state...................

    case types.GET_PRODUCTS_REQUEST:
      //console.log("rendering:", state);
      return {
        ...state,
        // Products: [],
        ProductsDetail: [],
        isLoading: true,
      };

    // ...............................

    case types.GET_PRODUCTS_HEADING:
      // console.log("heading:", payload);
      return {
        ...state,
        ProductHeading: payload,
        isLoading: false,
      };

    // Success state..................

    case types.GET_PRODUCTS_SUCCESS:
      console.log("products in redux:", payload);
      const oldProducts = [...state.Products];
      for (const product of payload) {
        const isProductInState = oldProducts.some(
          (existingProduct) => existingProduct.id === product.id
        );

        if (!isProductInState) {
          oldProducts.push(product);
        }
      }
      console.log("products in redux oldProducts:", oldProducts);
      return {
        ...state,
        Products: oldProducts,
        isLoading: false,
        isProductsEmpty: oldProducts.length > 0 ? false : true,
      };

    // return {
    //   ...state,
    //   Products: [...state.Products, ...payload],
    //   isLoading: false,
    //   isProductsEmpty: payload.length > 0 ? false : true,
    // };

    // for next page link
    case types.GET_TOTAL:
      return {
        ...state,
        isLoading: false,
        Total: payload,
      };

    // Products Detail

    case types.GET_PRODUCTSDETAIL_SUCCESS:
      // console.log("products detail in reducer:", payload);
      return {
        ...state,
        ProductsDetail: payload,
        isLoading: false,
      };

    // ...............................

    // Failed state..................

    case types.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case types.UPDATE_WISHLIST_SUCCESS:
      //console.log("hey payload", payload);
      const { product_id, is_wishlist } = payload;
      const updatedProducts = state.Products.map((product) => {
        if (product.id === product_id) {
          // alert(product_id);
          // Update the is_wishlist property of the matching product
          return {
            ...product,
            is_wishlist: is_wishlist,
          };
        }
        return product;
      });

      return {
        ...state,
        Products: updatedProducts,
        isLoading: false,
      };

    case types.GET_NAVDATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: true,
        NavData: payload,
      };

    case "RESET_PRODUCTS_DETAILS":
      return {
        ...state,
        ProductsDetail: [],
      };

    // ...............................
    // default case

    default:
      return state;
  }

  // ................................
};

export { reducer };
