import {  Select } from '@chakra-ui/react'
import React from 'react'
import { useState } from 'react';
import {  useNavigate,useLocation } from 'react-router-dom';

const SortBy = ({type}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('');

    //console.log("store page:");

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      const searchParams = new URLSearchParams(location.search);

      if(event.target.value !== "" && event.target.value !== null)
      { //alert("hey:"+event.target.value)
        searchParams.set('sortBy', event.target.value);

      }
      else{
        searchParams.delete('sortBy');
      }

      navigate(`/store?${searchParams}`);
     // setSearchParams(searchParams);

    };
    // const handleCategoryClick = (categoryId) => {
    //     setSelectedCategory(categoryId);
    //     // alert("categoryId - "+categoryId);
    //     const searchParams = new URLSearchParams(location.search);
    //     searchParams.set('type', categoryId);
    //    // setSearchParams(searchParams);
    //     navigate(`/store?${searchParams}`);
    //   };



  return (
<>

{
  type === 'Mobile'?
  <Select
    fontSize={"14px"}
    fontWeight={'600'}
    color={"#FFFFFF"}
    border={'0px'}
    placeholder="SORT BY"
    style={{ '--chakra-colors-white': '#000000' }}
    value={selectedOption}
    onChange={handleOptionChange}
  >
    <option value="price-high-to-low">Price: High To Low</option>
    <option value="price-low-to-high">Price: Low To High</option>
    <option value="rating-high-to-low">Customer Rating</option>
    <option value="best-seller">Best Sellers</option>
    <option value="new">Latest</option>
  </Select>
  :
  <Select
    w='40vh'
    fontSize={"14px"}
    fontWeight={400}
    color={"#878b94"}
    variant="outline"
    placeholder="Sort By"
    value={selectedOption}
    onChange={handleOptionChange}
  >
    <option value="price-high-to-low">Price: High To Low</option>
    <option value="price-low-to-high">Price: Low To High</option>
    <option value="rating-high-to-low">Customer Rating</option>
    <option value="best-seller">Best Sellers</option>
    <option value="new">Latest</option>
  </Select>
}
{/* <Select
    w='40vh'
    fontSize={"14px"}
    fontWeight={400}
    color={"#878b94"}
    variant="outline"
    placeholder="Sort By"
    value={selectedOption}
    onChange={handleOptionChange}
  >
    <option value="price-high-to-low">Price: High To Low</option>
    <option value="price-low-to-high">Price: Low To High</option>
    <option value="rating-high-to-low">Customer Rating</option>
    <option value="best-seller">Best Sellers</option>
    <option value="new">Latest</option>
  </Select> */}
</>
  )
}

export default SortBy