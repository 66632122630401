import "./App.css";
import "./variables.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MainRoutes } from "./Routes/Routes";
import { useDispatch } from "react-redux";
import "./App.css";
import ScrollToTop from "react-scroll-up";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RiArrowUpCircleFill } from "react-icons/ri";
import { Icon, Show } from "@chakra-ui/react";
import {
  getTopBannerImgAsync,
  getWishlistCountAsync,
  getCartCountAsync,
} from "./Redux/NavBarhelperdataslice";
import { getNavdata } from "./Redux/AppReducer/Action";
import { useSelector } from "react-redux";
function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryClient = new QueryClient();
  const { userData } = useSelector((store) => store.AuthReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const params = {
      user_id: userData.id,
    };

    dispatch(getNavdata());
    dispatch(getTopBannerImgAsync());


    if(userData.id)
    {
      dispatch(getWishlistCountAsync(params));
      dispatch(getCartCountAsync(params));
    }
  }, [userData, dispatch]);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <MainRoutes />
        <Show breakpoint="(min-width: 500px)">
          <ScrollToTop
            showUnder={200}
            style={{
              position: "fixed",
              bottom: 50,
              right: 30,
              cursor: "pointer",
              transitionDuration: "0.2s",
              transitionTimingFunction: "linear",
              transitionDelay: "0s",
            }}
          >
            <Icon
              fontSize={"36px"}
              borderRadius={"full"}
              bgColor="pink"
              as={RiArrowUpCircleFill}
            />
          </ScrollToTop>
        </Show>
      </QueryClientProvider>
    </div>
  );
}

export default App;
