import OtherNavbar from "../Components/OtherNavbar";
import OtherFooter from "../Components/OtherFooter";
import {
  Box,
  Button,
  VStack,
  Show,
  Text,
} from "@chakra-ui/react";
import { WarningIcon } from '@chakra-ui/icons';
import {  useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateOrderStatus } from "../Redux/OrderReducer/Action";

const PaymentCancel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

//   const searchParams = new URLSearchParams(location.search);

//   // Access the individual query parameters
//   const order_id = searchParams.get("order_id");
//   const bank_ref = searchParams.get("bank_ref");
  useEffect(() => {
    const params = {
      status: "success",
    };
    dispatch(updateOrderStatus(params));
    //eslint-disable-next-line
  }, []);

  return (
    <VStack h="100vh" justify="space-between">
      <OtherNavbar page="paymentSuccess" />
      <Box w="100%">
        <VStack w={"60%"} m="auto" spacing={4}>
          {/* <Image
            w="full"
            src="https://www.knowband.com/blog/wp-content/uploads/2020/03/THANKYOU-PAGE-2.png"
          /> */}
          <Show breakpoint="(max-width: 500px)">
            <Button
              my={4}
              w={"100%"}
              onClick={() => {
                navigate("/store");
              }}
              color={"#fff"}
              variant={"solid"}
              bg={"black"}
              borderRadius={3}
              fontSize={"15px"}
            >
              CONTINUE SHOPPING
            </Button>
          </Show>
          <Show breakpoint="(min-width: 500px)">
            <Text style={{fontSize:20,fontWeight:'bold'}}>Your Payment Failed</Text>
            <WarningIcon  boxSize={20} color="red"/>
            <Text style={{fontSize:14,fontWeight:'bold'}}>Something went wrong. Please try again.</Text>
            {/* <Text style={{fontSize:12}}>Your Order Id is: {order_id}</Text> */}
            <Button
              variant={"outline"}
              colorScheme="red"
              fontWeight={"bold"}
              //  _hover={{bgColor:"pink"}}
              onClick={() => {
                navigate("/store");
              }}
            >
              TRY AGAIN
            </Button>
          </Show>
        </VStack>
      </Box>
      <OtherFooter />
    </VStack>
  );
};

export default PaymentCancel;
