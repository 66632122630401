import { Box, Image, Text, HStack,Flex  } from "@chakra-ui/react";
import styles from "../css/Card.module.css";
import { useNavigate } from "react-router";

const Card = ({ item, handleCategoryCard }) => {
  const handleClick = () => {
    handleCategoryCard(item);
  };

  return (

    <Box as="li" listStyleType="none" mb={5} style={{ cursor: 'pointer'}}>
      <Box height="130px" bg="#d4d4d4"  paddingLeft={'16px'}  boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)" onClick={handleClick}>
      <Flex justifyContent="space-between" alignItems="center" height="100%">
        {/* Left Side - Centered Text */}
        <Box textAlign="left">
        <Text>{item.category_name}</Text>
        </Box>

        {/* Right Side - Centered Image */}
        <Box textAlign="right" height="100%" width="18vh" >
          <Image src={item.image_url} aspectRatio={1} alt="Category" height="100%" />
        </Box>
      </Flex>
      </Box>
      {/* <Box className={styles.imageContainer} onClick={handleClick}>
        <Image
          className={styles.catImage}
          src={item.image_url}
          alt="Categories"
        />
        <Text className={styles.textOverlay}>{item.category_name}</Text>
      </Box> */}
    </Box>
  );
};

const SubCatCard = ({ item, handleCategoryCard, cardType,onClose }) => {
  const navigate = useNavigate();
  // console.log("innerdrawer",item);
  const handleClick = () => {
    if (cardType === "innerSubCatNav") {
      onClose();
       navigate(`/store?type=${item.cat_id}&category=${item.subcat_id}&innerSubCategory=${item.id}`);
    } else if(cardType === "subCatNav") {

      if(item.innersubcategories.length < 1)
      {
        navigate(`/store?type=${item.cat_id}&category=${item.id}`);
      }
      else{
        handleCategoryCard(item);
      }


    }
  };


  var name;
  name = cardType === "subCatNav" ? item.subcategory_name : item.innersubcategory_name;

  return (

    <Box as="li" listStyleType="none" mb={5} style={{ cursor: 'pointer'}}>
      <Box onClick={handleClick} className={styles.subcatImageContainer}>
        <HStack justifyContent={"flex-start"}>

        <Box w="80px" h="80px">
            <Image
              src={item.image_url}
              objectFit="cover"
              w="100%"
              h="100%"
            />
          </Box>
          <Text flex={'0.8'} ml={5}>{name}</Text>
        </HStack>
      </Box>
    </Box>
  );
};

const CardList = ({ handleCategoryCard, navdata, cardType,onClose }) => {

  if (cardType === "catNav") {
    return (
      <Box>
        {navdata.map((item) => (
          <Card key={item.id} item={item} handleCategoryCard={handleCategoryCard} />
        ))}
      </Box>
    );
  }
  else if (cardType === "subCatNav" || cardType === "innerSubCatNav") {
    return (
      <Box>
        {navdata.map((item) => (
          <SubCatCard key={item.id} cardType={cardType} item={item} handleCategoryCard={handleCategoryCard}
          onClose={onClose} />
        ))}
      </Box>
    );
  }

};

export default CardList;
