import {
  Box,
  Grid,
  HStack,
  Spacer,
  Text,
  Flex,
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  CloseButton,
  Show,
  Icon,
  GridItem,
  Button,
} from "@chakra-ui/react";
import { MdOutlineFilterAlt } from "react-icons/md";
import React, { useState, useEffect } from "react";
// import React ,{useEffect} from 'react';
import { useSelector } from "react-redux";
import Filter from "../Components/Filter";
import Footer from "../Components/Footer";
// import Navbar from '../Components/Navbar';
import Products from "../Components/Products";
import SortBy from "../Components/SortBy";
import Navbar2 from "../Components/Navbar2";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Store = () => {
  const navigate = useNavigate();
  const { Total: length } = useSelector((store) => store.AppReducer);
  const { ProductHeading } = useSelector((store) => store.AppReducer);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // const { isOpen, onClose } = useDisclosure();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  const onDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  const onDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      navigate("/"); // Navigate to the home screen or any other desired screen
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);
  return (
    <>
      <Navbar2  showBackIcon={'true'}/>

      <Drawer
        placement={"left"}
        onClose={onDrawerClose}
        isOpen={isDrawerOpen}
        size={"sm"}
      >
        <DrawerOverlay />
        <DrawerContent overflowY="auto">
          <DrawerHeader>
            <Box w="100%" p={2} display="flex" alignItems="center">
              <CloseButton
                aria-label="Close drawer"
                onClick={onDrawerClose}
                size="xl"
                mr={2}
              />
            </Box>
          </DrawerHeader>
          <Box p={4} mt={5}>
            {/* <CardList handleCategoryCard={handleCategoryCard} navdata={navdata}
            cardType={cardType}
          /> */}
            <Filter />
          </Box>
        </DrawerContent>
      </Drawer>

      <Box>
        <HStack spacing={1} w={"98%"} m={"10px auto"}>
          <Link color={"#46495a"} fontSize={"10px"} to="/">
            Home /
          </Link>
          <Link color={"#46495a"} fontSize={"10px"} to="/store">
            Store
          </Link>
        </HStack>
      </Box>

      <Box>
        <HStack spacing={1} w={"98%"} m={"10px auto"}>
          <Text fontWeight={600} fontSize={"18px"} color="#878b94">
            {ProductHeading} -
          </Text>

          <Text fontSize={"16px"} fontWeight={400} color={"#878b94"}>
            {length} items
          </Text>

          <Spacer />
          <Show breakpoint="(min-width: 500px)">
            <SortBy />
          </Show>
        </HStack>
      </Box>

      <Box mb={"50px"}>
        {/* <Grid gridTemplateColumns={"20% 80%"}> */}

        <Grid
          templateColumns={{
            base: "1",
            sm: "0% 100%",
            md: "20% 80%",
            lg: "20% 80%",
          }}
        >
          <Show breakpoint="(min-width: 500px)">
            <Box border={"1px solid #edf2f7"}>
              <Filter />
            </Box>
          </Show>
          <Box>
            {/* { isLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
                <Spinner size="xl" color="red.500" />
              </Box>
            ) : (
              <Products />
            )} */}
            <Products />
          </Box>
        </Grid>
      </Box>

      <Show breakpoint="(max-width: 500px)">
        <Flex>
          {isDrawerOpen ? (
            <Grid
              w={"full"}
              gap={2}
              templateColumns="repeat(2,1fr)"
              position="fixed"
              bottom="0rem"
              pb={"0.5rem"}
              background={"white"}
              zIndex="9999"
            >
              <GridItem mx={2} onClick={onDrawerClose}>
                <Button
                  w={"full"}
                  variant={"outline"}
                  colorScheme="black"
                  size={"md"}
                >
                  {/* <Icon fontSize="20px" as={MdOutlineFilterAlt} /> */}
                  Cancel
                </Button>
              </GridItem>
              <GridItem onClick={onDrawerClose} mr={2}>
                <Button
                  w={"full"}
                  bg="black"
                  color={"#FFFFFF"}
                  colorScheme="black"
                  size={"md"}
                >
                  {/* <Icon fontSize="20px" as={MdOutlineFilterAlt} /> */}
                  Apply Filter
                </Button>
              </GridItem>
            </Grid>
          ) : (
            <Grid
              w={"full"}
              templateColumns="repeat(2,1fr)"
              position="fixed"
              bottom="1.0rem"
              h={"50px"}
              zIndex="9999"
            >
              <GridItem bg={"#000000"}>
                <Button size={"md"} colorScheme="black">
                  <SortBy type="Mobile" />
                </Button>
              </GridItem>
              <GridItem bg="black" onClick={onDrawerOpen}>
                <Button
                  w={"full"}
                  bg="black"
                  color={"#FFFFFF"}
                  colorScheme="black"
                  fontSize={"14px"}
                  fontWeight={"600"}
                >
                  <Icon fontSize={"14px"} as={MdOutlineFilterAlt} />
                  FILTERS
                </Button>
                {/* <HStack justifyContent={'center'}>
                    <Icon fontSize="20px" as={MdOutlineFilterAlt} />
                    <Text fontSize="16px" color="535766" fontWeight={700}>
                      Filters
                    </Text>
                  </HStack> */}
              </GridItem>
              <Box height={20} bg={"gray.200"}></Box>
              <Box height={20} bg={"gray.200"}></Box>
            </Grid>
          )}
        </Flex>
        {/* <Wrap spacing='30px' position="fixed" bottom="0rem" right="50%" zIndex="9999">
          <WrapItem>
            <Center w='100%' h='80px' bg='tomato'>
              Filters
            </Center>
          </WrapItem>
        </Wrap> */}
      </Show>
      <Show breakpoint="(min-width: 500px)">
        <Footer />
      </Show>
    </>
  );
};

export default Store;
