import {
  VStack,
  HStack,
  Text,
  Button,
  Image,
  SimpleGrid,
  Box,
  Center,
} from "@chakra-ui/react";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../Redux/CartReducer/Action";
import showCustomToast from "./showCustomToast";
import { useNavigate } from "react-router-dom";

const SizeSelectModal = ({
  onClose,
  setAddress,
  handleDelete,
  selectedAddress = {},
  type = "add",
  item,
}) => {
  const { isAuth, userData } = useSelector((store) => store.AuthReducer);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [variation, setVariation] = useState("");
  //eslint-disable-next-line
  const [addressType, setAddressType] = useState("");
  const [sizeRef, setSize] = useState("");

  useEffect(() => {
    if (type === "edit") {
      // console.log("address page", selectedAddress);

      setAddressType(selectedAddress.type);
    }
  }, [type, selectedAddress]);

  const setsize = (size, el) => {
    if (size === sizeRef) {
      setSize("");
      setVariation("");
    } else {
      setSize(size);
      setVariation(el.variation);
      //   alert(el.variation);
    }
  };

  const handleSendCart = () => {
    if (variation === "" && item.variation.length > 0) {
      showCustomToast("select size", "error");
      return;
    }
    const params = {
      product_id: item.id,
      user_id: userData.id,
      vendor_id: parseInt(item.vendor_id),
      product_name: item.product_name,
      qty: 1,
      price: item.discounted_price,
      variation: variation,
      attribute: "",
      shipping_cost: "",
      tax: "",
      image: item.productimages[0].image_name,
      image_url: item.productimages[0].image_url,

      //size:
    };
    const successCallback = (success) => {
      if (successCallback) {
        handleDelete(item.id);
        onClose();
      }
    };

    dispatch(addToCart(params, successCallback));
  };

  return (
    <>
      <VStack p={10}>
        <HStack>
          <HStack flex={"0.3"}>
            <Image src={item.productimages[0].image_url} />
          </HStack>
          <VStack flex={"0.7"} alignItems={"flex-start"}>
            <Text>{item.product_name}</Text>
            <Text>Rs.{item.discounted_price} </Text>
            <Text
              textDecor="line-through"
              fontWeight={400}
              fontSize="12px"
              color="#afb0b7"
            >
              Rs.{item.product_price}
            </Text>
          </VStack>
        </HStack>
        <VStack w="full">
          <HStack w="full">
            {item.variation.length > 0 ? (
              <>
                <Text fontWeight="bold" color="#282c3f">
                  SELECT SIZE
                </Text>
              </>
            ) : (
              ""
            )}
          </HStack>
          <HStack my={5} w="full">
            <SimpleGrid columns={7} gap={10} spacing={"10px"}>
              {item.variation?.map((el, i) => {
                return (
                  <>
                    {window.innerWidth < 500 ? (
                      <Box
                        w={42}
                        h={42}
                        pointerEvents={el.qty === "0" ? "none" : " "}
                        boxShadow={"base"}
                        rounded={"md"}
                        key={i + toString(i)}
                        onClick={() => setsize(el.id, el)}
                        // borderRadius={"full"}
                        bg={
                          el.qty === "0"
                            ? "#F3F3F3"
                            : `${sizeRef === el.id ? "black" : "white"}`
                        }
                        color={
                          el.qty === "0"
                            ? "#A2A2A2"
                            : `${sizeRef === el.id ? "white" : "black"}`
                        }
                        border={
                          el.qty === "0"
                            ? `1px solid #F3F3F3`
                            : `1px solid ${
                                sizeRef === el.id ? "black" : "#b8b8b8cb"
                              }`
                        }
                        cursor={"pointer"}
                        position={"relative"}
                        transition=".5s  all"
                        _hover={{
                          borderColor: `${
                            el.qty === "0" ? "#F3F3F3" : "black"
                          }`,
                        }}
                      >
                        <Center fontSize={"14px"} fontWeight={500} p={"10px"}>
                          {el.variation}
                        </Center>
                        {el.qty === "0" ? (
                          <Box
                            bg={"#7b7b7b1c"}
                            position={"absolute"}
                            top={"1px"}
                            width={"100%"}
                            height={"100%"}
                          >
                            {/* <TagCloseButton w={'100%'} /> */}
                            <Text color={"#9a9a9ac9"} w={"100%"}>
                              ______
                            </Text>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <Box
                        w={42}
                        h={42}
                        key={i + toString(i)}
                        onClick={() => setsize(el.id, el)}
                        // borderRadius={"full"}
                        bg={"white"}
                        color={"black"}
                        border={`1px solid ${
                          sizeRef === el.id ? "#ff3f6c" : "#b8b8b8cb"
                        }`}
                        cursor={"pointer"}
                        transition=".5s  all"
                        _hover={{
                          borderColor: "#ff3f6c",
                        }}
                      >
                        <Center fontSize={"14px"} fontWeight={500} p={"10px"}>
                          {el.variation}
                        </Center>
                      </Box>
                    )}
                  </>
                  // <Box
                  //     key={i + toString(i)}
                  //     borderRadius={'full'}
                  //     onClick={() => setsize(el.id, el)}
                  //     border={`1px solid ${sizeRef === el.id ? '#ff3f6c' : '#b8b8b8cb'}`}
                  //     cursor={'pointer'}
                  //     transition=".5s all"
                  //     _hover={{
                  //         borderColor: '#ff3f6c',
                  //     }}
                  // >
                  //     <Center fontSize={'14px'} fontWeight={500} p={'10px'}>
                  //         {el.variation}
                  //     </Center>
                  // </Box>
                );
              })}
            </SimpleGrid>
          </HStack>

          <Button
            // eslint-disable-next-line
            onClick={() =>
              isAuth
                ? handleSendCart()
                : navigate("/signup", {
                    state: `/fetchProductDetails/${item.id}`,
                    replace: true,
                  })
            }
            w="full"
            bg={window.innerWidth > 500 ? "red" : "black"}
            color={"white"}
            borderRadius={3}
            fontSize={"15px"}
          >
            DONE
          </Button>
        </VStack>
      </VStack>
    </>
  );
};

export default SizeSelectModal;
