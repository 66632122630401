import React from 'react';
import { Box, Container, Heading, Text,Show } from '@chakra-ui/react';
import Navbar2 from "../Components/Navbar2";
import Footer from "../Components/Footer";

const TermsCondition = () => {
  return (
    <div>
        <Navbar2></Navbar2>
        <Box py={4} mr={10} ml={10} mb={10}>
        <Container maxW="container.LG" >
        <Heading as="h1" mb={4} textAlign={'center'} fontWeight={400}>ABOUT OUR GENERAL TERMS AND CONDITIONS </Heading>
        <Text mb={4} fontSize={'13px'}>
        This document is an electronic record and is governed by the provisions under the Information Technology Act, 2000 and rules made thereunder as may be applicable, and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures. 

M/S K.K.Fabrics is a Partnership Firm having its registered office at 1740/27-C, Balloke Road , Haibowal Kalan, Ludhiana-141001,Punjab , (referred to as  "we" or "us" in this General Terms and Conditions). 

This page provides information about who we are and the legal terms and conditions that may apply to your use (“you”, “your” or “User”) of the mobile application and/or  website: 98degreenorth  .com (collectively referred to as the “98degreenoth Platform”) and any Product you order from us. “Products” shall mean the menswear, womenswear, baby/kidswear, unisex apparels, lingerie, accessories, footwear, toiletries, perfumes and beauty products under the brand name “98degreenorth  ”. 

Please take a moment to familiarize yourself with our General Terms and Conditions, Privacy Policy and Cookie Policy (collectively referred to as “Terms and Conditions”). Before you start to use the 98degreenorth Platform. If you do not agree with any provisions of the Terms and Conditions, we advise you to not use or access the  98degreenorth Platform. 
      </Text>

      <Text  mb={4} fontSize={'13px'}>
      The Terms and Conditions apply to your use of the  98degreenorth   98degreenorth Platform and to any Products you purchase from the  98degreenorth Platform. The Terms and Conditions apply regardless of how you access the  98degreenorth   98degreenorth Platform, including any technologies or devices by which M/S K.K.Fabrics makes the  98degreenorth   98degreenorth Platform available to you, at home, on the move or in store. By accessing, browsing, using, registering with, or placing an order on the  98degreenorth  98degreenorth Platform, you confirm that you have read, understood and you expressly agree to be bound by the Terms and Conditions in their entirety. 

Please note that we may from time to time change the Terms and Conditions that govern your use of the  98degreenorth Platform. Every time you wish to use our 98degreenorth Platform, please check these Terms and Conditions to ensure you understand the terms and conditions that apply at that time. Further, please note that we reserve the right to either change the format and the content of the  98degreenorth Platform or suspend the operation of the  98degreenorth Platform for support or maintenance work, in order to update the content or for any other reason, at any time. 

Only persons who can enter into legally binding contracts as per Indian Contract Act, 1872, i.e., persons who are 18 (Eighteen) years of age or older, are of sound mind, and are not disqualified from entering into contracts by any law, can use 98degreenorth Platform and place an order with us. If you are a minor i.e., under the age of 18 (Eighteen) years, you may purchase the Product on  98degreenorth Platform only with the involvement of a parent or guardian. 

By accepting these Terms and Conditions, you affirm that you are 18 (Eighteen) years of age or above and are fully competent to enter into these Terms and Conditions, and to abide by and comply with these Terms and Conditions. In the event we are made aware that a user is under the age of 18 years and is using/browsing the  98degreenorth Platform without the supervision of his/her parent or legal guardian, we reserve the right to deactivate such user’s account without further notice.
      </Text>

      <Heading size={'md'} mb={1} fontWeight={600}>Use of the 98degreenorth Platform </Heading>
      <Text mb={4} fontSize={'13px'}>
         Registration of your account: 
      </Text>
      <Text mb={4} fontSize={'13px'}>
      In order to purchase Products on the 98degreenorth Platform and/or avail the services, you will have to register on the  98degreenorth Platform. Registration is a one-time process. You will be eligible to further access and avail the services only upon receipt of a confirmation e-mail from us after filling in the necessary forms and after providing the necessary details as may be required by us at the time of your registration. 

You are solely authorized to operate the account created by you. Consequently, it is your responsibility to maintain the confidentiality of the log in credentials of your account on the  98degreenorth Platform and for restricting access to your computer/mobile/other similar devices to prevent unauthorized access to your account. You shall remain solely liable for all the actions undertaken through your account. 

You will: (a) immediately inform us of any unauthorized use of the account or any other security breach; and (b) ensure that you log out of your account at the end of each session. We, our employees, agents, directors and officers will not be liable for any loss or direct or indirect damage arising from your failure to comply with these Terms and Conditions. You may be held liable for any losses incurred to us or any other user due to unauthorized use of their account by you. We reserve the right to refuse access to the 98degreenorth Platform, terminate accounts, remove or edit content at any time without notice to you. 
      </Text>

      <Text mb={4} fontSize={'13px'}>
      In order to purchase Products on the 98degreenorth Platform and/or avail the services, you will have to register on the  98degreenorth Platform. Registration is a one-time process. You will be eligible to further access and avail the services only upon receipt of a confirmation e-mail from us after filling in the necessary forms and after providing the necessary details as may be required by us at the time of your registration. 

You are solely authorized to operate the account created by you. Consequently, it is your responsibility to maintain the confidentiality of the log in credentials of your account on the  98degreenorth Platform and for restricting access to your computer/mobile/other similar devices to prevent unauthorized access to your account. You shall remain solely liable for all the actions undertaken through your account. 

You will: (a) immediately inform us of any unauthorized use of the account or any other security breach; and (b) ensure that you log out of your account at the end of each session. We, our employees, agents, directors and officers will not be liable for any loss or direct or indirect damage arising from your failure to comply with these Terms and Conditions. You may be held liable for any losses incurred to us or any other user due to unauthorized use of their account by you. We reserve the right to refuse access to the 98degreenorth Platform, terminate accounts, remove or edit content at any time without notice to you. 
      </Text>
      <Text mb={4} fontSize={'13px'}>
      We constantly monitor the user’s account in order to avoid fraudulent accounts and transactions. Users with more than one account or availing referral vouchers fraudulently shall be liable for legal actions under law and we reserve the right to recover the cost of goods, collection charges and lawyer fees from persons using the 98degreenorth Platform fraudulently. We reserve the right to initiate legal proceedings against such persons for fraudulent use of the 98degreenorth Platform and any other unlawful acts or omissions in breach of these terms and conditions. In the event of detection of any fraudulent or declined transaction, prior to initiation of legal actions, we reserve the right to immediately delete such account and dishonour all past and pending orders without any liability. For the purpose of this clause, we shall owe no liability for any refunds. 

You will use the 98degreenorth Platform for lawful purposes only and will not use it in a way that infringes the rights of anyone else or that restricts or inhibits anyone else's enjoyment of the 98degreenorth Platform. You will not undertake any activity that is harmful to the 98degreenorth Platform or its content or otherwise not envisaged through the 98degreenorth Platform. You have a limited license to access and use the 98degreenorth Platform, solely for the purpose of availing the services and purchasing the Products offered on it, subject to these Terms and Conditions. 

You may not, without our prior written consent, copy, reproduce, crawl, frame, republish, download, print, post, distribute, re-post, broadcast, record, transmit, edit, communicate to the public, link to, deep-link into, or distribute in any way the web pages or materials on the 98degreenorth Platform or the computer codes or elements comprising the 98degreenorth Platform. You may not use the content of the 98degreenorth Platform for any commercial purposes whatsoever. 

We authorise you to view and access the  98degreenorth Platform solely for identifying Products, carrying out purchases of Products and processing returns and refunds, in accordance with Terms and Conditions. We therefore, grant you a limited, revocable, non-exclusive and non-transferable permission to access and use the 98degreenorth Platform for the aforesaid purpose. 
        </Text>
        <Text mb={4} fontSize={'13px'}>
        The license granted to you does not include a license for: (a) resale of products or commercial use of the  98degreenorth Platform or any content therein, (b) any collection and use of product listings, description, or prices, (c) any use of 98degreenorth Platform, the services and/or of any content therein other than as contemplated in these Terms and Conditions, (d) any downloading or copying of login credentials of user, (e) any use of data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) any parts of 98degreenorth Platform, and (f) creating and/ or publishing your own database that features parts of the 98degreenorth Platform. 

In your use of 98degreenorth Platform, you may enter into correspondence with, purchase goods and/or services from, or participate in promotions of advertisers or members or sponsors of  98degreenorth Platform or access any other third-party website linked to the  98degreenorth Platform. Unless otherwise stated, any such correspondence, advertisement, purchase or promotion, including the delivery of and the payment for goods and/or services, and any other term, condition, warranty or representation associated with such correspondence, purchase or promotion, is solely between you and the applicable third party. You agree that we have no liability, obligation or responsibility for any such correspondence, purchase or promotion, access or usage of any third-party website and the contract under such instances remains between you and any such third party. 
        </Text>
        <Text mb={4} fontSize={'13px'}>
        
        </Text>
        <Heading size={'md'} mb={1} fontWeight={600}>Use of the  98degreenorth Platform from Outside India </Heading>
        <Text mb={4} fontSize={'13px'}>
        Except where otherwise specified on the  98degreenorth Platform, the materials on the  98degreenorth Platform are displayed solely for the purposes of promoting our Products available in India. 98degreenorth   shall not accept orders for delivery to locations outside India. Those who choose to access the 98degreenorth   98degreenorth Platform from locations outside India are entirely responsible for compliance with all the applicable local laws of that country and to the extent any local laws are applicable. These Terms and Conditions do not constitute, nor may these Terms and Conditions be used for or in connection with any promotional activities or solicitation by anyone in any jurisdiction in which such promotional activities or solicitation are not authorized or to any person to whom it is unlawful to promote or solicit. 

We have several websites offering Products, services, content and various other functionalities to specific regions worldwide. The Products and services offered in one region may differ from those in other regions due to availability, local or regional laws, shipment and other considerations. We do not make any warranty or representation that a user in one region may obtain the Products or services from our site in another region and we may cancel a user's order or redirect a user to the site for that user’s region if a user attempts to order Products or services offered on a site in another region. 

Information that we publish on the world wide web may contain references or cross references to our Products, programs and services that are not announced or available in your country. Such references do not imply that we intend to announce such Products, programs or services in your country. Consult our local business contact for information regarding the Products, programs and services that may be available to you. 
        </Text>

        <Heading size={'md'} mb={1} fontWeight={600}>Ownership of Submissions / Comments  </Heading>
        <Text mb={4} fontSize={'13px'}>
        Other than personal / sensitive data (which is subject to our Privacy Policy), You grant to us a royalty-free, perpetual, irrevocable, non-exclusive right and license to adopt, publish, modify, reproduce, disseminate, transmit, distribute, copy, use, create derivative works from, display worldwide, or act on any material, all comments, blogs, feedback, suggestions, questions, ideas, artwork, images, product or marketing ideas and any other submissions disclosed, submitted or offered to M/S K.K.Fabrics   on or through the 98degreenorth Platform or otherwise disclosed, submitted or offered by you (collectively, “Submissions/Comments”) shall become and remain the property of M/S K.K.Fabrics   once submitted . You must comply with the content standards set out in these terms. You warrant that you own or have the right to use any submissions and that your submissions will comply with these standards and does not breach any applicable agreement and will not cause injury to any person or entity (including that the content or material is not defamatory). If at any time, the submissions provided by you is found to be false or inaccurate, we will have the right to reject registration, cancel all orders, and restrict you from using the services and other affiliated services in the future without any prior intimation whatsoever. You agree to indemnify us and our affiliates for all claims brought by a third party against us or our affiliates arising out of or in connection with a breach of any of such warranties made by you. 
        </Text>

        <Heading size={'md'} mb={1} fontWeight={600}>Social networking sites  </Heading>
        <Text mb={4} fontSize={'13px'}>
        Any submissions you make on any third party website or page such as Facebook page, Twitter, Pinterest, YouTube or other social networking site (“Third Party Website/Platform”) are governed by the terms of use and policies of the respective Third Party Website/Platform. The user should read the terms of use, privacy policy and/or other policies of the respective Third Party Website/Platform and any access or use of the Third Party Website/Platform is entirely at the user’s own risk. All comments, images, videos and any other type of material posted on any third party’s social networking site do not necessarily reflect the opinions or ideas of M/S K.K.Fabrics   or its employees and 98degreenorth   is not responsible for any such content. 
        </Text>

        <Heading size={'md'} mb={1} fontWeight={600}>Ownership of Rights </Heading>
        <Text mb={4} fontSize={'13px'}>
        All rights, including copyright and other intellectual property rights, in and to the 98degreenorth Platform are owned by or licensed to M/S K.K.Fabrics  . 

Your use of the 98degreenorth Platform and its contents grants no rights to you in relation to our intellectual property rights, or that of third parties, in the 98degreenorth Platform or its contents. By submitting information (other than your personal data), text, photos, graphics or other content to the 98degreenorth Platform you confirm that you have the right to use the same and grant us a right to use such materials at our own discretion (with or without accreditation) in any media including, without limitation, to edit, copy, reproduce, disclose, post and remove such materials from the 98degreenorth Platform. 

A disclosure, comments and your agreement to the 98degreenorth Platform Terms and Conditions shall constitute an assignment to 98degreenorth   of any intellectual property rights. Whenever you make use of a feature that allows you to upload material to our 98degreenorth Platform, including questions, or to make contact with other users of our 98degreenorth Platform or to make submissions on any 98degreenorth   related Third Party Website/Platform, you must comply with the content standards set out below and the 98degreenorth Platform Terms. Any third-party advertising on 98degreenorth   pages of third-party social networking sites / Third Party Website/Platform are not the responsibility of, or endorsed by 98degreenorth  . Any use of any 98degreenorth   social networking pages or their contents, including copying or storing them in whole or in part, is prohibited without the permission of 98degreenorth  . You confirm that the submissions made by you on 98degreenorth Platform or any Third Party Website/Platform is your own and that the content does not infringe the material, trademarks or intellectual property of others. 

You further agree to execute all such documents and do all such acts and things as we may reasonably require in order to assign any such rights to us. 

All the trade names associated with us and all Products and logos denoted with TM or ® are trademarks or registered trademarks of us or our affiliates. Our trademarks may not be used in connection with any product or service that is not our product or service. 
        </Text>
        <Heading size={'md'} mb={1} fontWeight={600}>Confidentiality </Heading>
        <Text mb={4} fontSize={'13px'}>
        Any material you upload to our 98degreenorth Platform will be considered non-confidential and non-proprietary, and we have the right to use, copy, distribute and disclose to third parties any material in the submissions for any purpose. We also have the right to disclose your identity to any third-party who is claiming that any material posted or uploaded by you to our 98degreenorth Platform or any 98degreenorth   related third party social networking site constitutes a violation of their intellectual property rights, or of their right to privacy. We will not be responsible, or liable to any third party, for the content or accuracy of any materials posted by you or any other user of our 98degreenorth Platform. We have the right to remove any submissions you make on our 98degreenorth Platform if, in our opinion, such submission does not comply with the content standards set out below. 
        </Text>
        <Heading size={'md'} mb={1} fontWeight={600}>Rules for Submissions  </Heading>
        <Text mb={4} fontSize={'13px'}>
        You must be 18 years of age or older and registered to post a submission. Suppliers or manufacturers are not eligible to post a submission. 

Submissions should not include any: 

    Abusive, offensive or otherwise inappropriate language; 

    Profanity, blasphemy, spiteful, racist, sexually explicit, sexually gratuitous or discriminatory comments or content that might be considered to be harassment, abuse or threats against the personal safety or property of others; 

    Comments about other reviewers or bloggers; 

    Remarks that repeat criminal accusations, false, defamatory or misleading statements; 

    Material which impersonates others or personal information about anyone, including yourself, such as phone numbers, postal addresses or credit/debit card numbers, etc.; 

    Spam or advertising; 

    Third party brand names or trademarks; 

    HTML code, computer script or website URLs; 

    Availability, price, promotions or alternative ordering or delivery information; 

    Information about 98degreenorth   suppliers or manufacturers; and/or 

    An unsupported claim about the product producing an effect other than that for which it is indicated. 

M/S K.K.Fabrics , in its absolute discretion, reserves the right to not publish the submission, edit or remove it, take any appropriate action it deemed necessary or remove reviews which do not comply with the rules above. 

User Warranties and Restrictions 

You are prohibited from hosting, displaying, uploading, modifying, publishing, transmitting, updating or sharing on or through 98degreenorth Platform, any information that: 

    Deletes or modifies any content on the 98degreenorth Platform, such as any information regarding the services, their performance, sales or pricing; 

    Uses any engine, software, tool, agent or other mechanism (such as spiders, robots, avatars, worms, time bombs etc.) to navigate or search the 98degreenorth Platform; 

    Makes false or malicious statements against the Products, services, the 98degreenorth Platform or us; 

    Posts, copies, submits, uploads, distributes, or otherwise transmits or make available any software or other computer files that contain a virus or other harmful component, or otherwise disrupt or damage the 98degreenorth Platform and/or services or any connected network, or otherwise interfere with any person or entity’s use or enjoyment of the 98degreenorth Platform and/or the services; 

    Introduces any trojans, viruses, any other malicious software, any bots or scrape the 98degreenorth Platform for any information; 

    Probes, scans, or tests the vulnerability of any system, security or authentication measures implemented by us or otherwise tamper or attempt to tamper with our technological design and architecture; 

    Hacks into or introduce malicious software of any kind onto the 98degreenorth Platform; 

    Gains unauthorized access to, or interfere with, or damage, or disrupt the server on which the details connected to the services are stored, or any other server, computer, or database connected to the services; 

    Engages in any form of antisocial, disruptive, or destructive acts, including “flaming,” “spamming,” “flooding,” “trolling,”, “phishing” and “griefing” as those terms are commonly understood and used on the internet; 

    belongs to another person and to which you do not have any right; 

    is harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, invasive of another’s privacy, hateful, relating to or encouraging money laundering or gambling, or otherwise harmful in any manner whatsoever; 

    harms minors in any way; 

    infringes any patent, trademark, copyright or other proprietary rights; 

    violates any law for the time being in force; 

    deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature; and/or 

    impersonates or defames another person. 
        </Text>

        <Heading size={'md'} mb={1} fontWeight={600}>Disclaimer of Warranties and Accuracy of content   </Heading>
        <Text mb={4} fontSize={'13px'}>
        We will try to ensure that all information and recommendations, whether in relation to the Products, services, offerings or otherwise (hereinafter “Information”) provided as part of this 98degreenorth Platform is correct at the time of inclusion on the 98degreenorth Platform. We do not guarantee the accuracy of the Information. We make no representations or warranties as to the completeness or accuracy of Information. To the extent permitted by applicable law, M/S K.K.Fabrics  disclaims all representations and warranties, express or implied, that content or Information displayed in or on the 98degreenorth Platform is accurate, complete, up-to-date and/or does not infringe the rights of any third-party. 

The views expressed in user generated content are the opinions of those users and do not represent the views, opinions, beliefs or values of M/S K.K.Fabrics. 

You agree that information is being supplied to you on the condition that you will make your own determination as to the suitability of such information for your purpose prior to use or in connection with the making of any decision. No information on the 98degreenorth Platform shall constitute an invitation to invest in us or any affiliates. Any use of the 98degreenorth Platform or the information is at your own risk. Neither we, our affiliates, nor their officers, employees or agents shall be liable for any loss, damage or expense arising out of any access to, use of, the 98degreenorth Platform, the information, or any third-party website linked to the 98degreenorth Platform. We are not responsible for the content of any third-party sites and do not make any representations regarding the content or accuracy of material on such sites. If you decide to access links of any third-party platforms, you are doing so entirely at your own risk and expense. 

As means to assist the users in identifying the Products of their choice, we provide visual representations on the 98degreenorth Platform including graphics, illustrations, photographs, images, videos, charts, screenshots, infographics and other visual aids. While reasonable efforts are made to provide accurate visual representation, we disclaim any guarantee or warranty of exactness of such visual representation or description of the Product, with the actual Product ultimately delivered to users. The appearance of the Product when delivered may vary for various reasons. 

Nothing contained herein is to be construed as a recommendation to use any product, process, equipment or formulation, in conflict with any patent, or otherwise and we make no representation or warranty, express or implied that, the use thereof will not infringe any patent, or otherwise. 

We do not covenant or provide any representations and warranties: 

    in respect of quality, suitability, accuracy, reliability, performance, safety, merchantability, fitness for a particular purpose/consumption or the content (including Product or pricing information and/or specifications) on 98degreenorth Platform; 

    that the Services will be made available at all times; and 

    that the operation of 98degreenorth Platform, including the functions contained in any content, information and materials on 98degreenorth Platform or any third-party sites or services linked to 98degreenorth Platform will be uninterrupted, or that the defects will be rectified, or that 98degreenorth Platform or the servers that make such content, information and materials available are free of viruses or other harmful components. 

THIS 98degreenorth Platform AND THE SERVICES ARE PROVIDED TO YOU ON AN “AS IS” AND “WHERE-IS” BASIS, WITHOUT ANY REPRESENTATIONS OR WARRANTIES. WE, FOR OURSELF AND ANY THIRD-PARTY PROVIDING MATERIALS, SERVICES, OR CONTENT TO 98degreenorth Platform, MAKE NO REPRESENTATIONS OR WARRANTIES, EITHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF THIRD-PARTY RIGHTS, WITH RESPECT TO THE 98degreenorth Platform, THE INFORMATION OR ANY PRODUCTS OR SERVICES TO WHICH THE INFORMATION REFERS. WE WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, ARISING FROM OR CONNECTED WITH THE SITE, INCLUDING BUT NOT LIMITED TO, YOUR USE OF THIS SITE OR YOUR INABILITY TO USE THE SITE, EVEN IF WE HAVE PREVIOUSLY BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
        </Text>
        <Heading size={'md'} mb={1} fontWeight={600}>Disclaimer  </Heading>
        <Text mb={4} fontSize={'13px'}>
        o the extent permitted by applicable law, We, our officers, agents, employees and directors, disclaim any liability against any loss, damage, expenses, liabilities, claim, injury caused due to the failure of performance, omission, defect of products, or deletion, interruption, error, delay, virus, communication, unauthorised access, theft, destruction, alteration or use of records on the 98degreenorth Platform. 
        </Text>

        <Heading size={'md'} mb={1} fontWeight={600}>Damage to your computer or other devices   </Heading>
        <Text mb={4} fontSize={'13px'}>
        98degreenorth   uses reasonable efforts to ensure that the 98degreenorth Platform is free from viruses and other malicious or harmful content. However, we cannot guarantee that your use of the 98degreenorth Platform (including any content on it or any website accessible from it) will not cause damage to your computer or other device. It is your responsibility to ensure that you have the right equipment (including antivirus software) to use the 98degreenorth Platform safely and to screen out anything that may damage or harm your computer/ mobile phone or other device or other system or equipment through which the user uses and accesses the 98degreenorth Platform. Except where required by applicable law, 98degreenorth   shall not be liable to any person for any loss or damage they suffer as a result of viruses or other malicious or harmful content that they access from or via the 98degreenorth Platform. 
        </Text>
        <Heading size={'md'} mb={1} fontWeight={600}>Communication and Unsubscribing    </Heading>
        <Text mb={4} fontSize={'13px'}>
        By providing your consent, you also accept to receive news, updates, offers/ campaign related SMS, to the email address and mobile phone number provided by you. By accessing and using the 98degreenorth Platform and/ or verifying your contact number with us, you explicitly consent to receive such communications (through call, SMS, email or other digital and electronic means) from us and/or our authorized representatives regarding any new services or offerings, even if your contact number is registered under the DND/NCPR list under the Telecom Commercial Communications Customer Preference Regulations, 2018. 

You can unsubscribe/ opt-out from receiving marketing/ promotional communications, newsletters and other notifications from us at any time by following the instructions set out in such communications (if any) or by contacting our customer care. 

In case you wish to delete your account on the 98degreenorth Platform, you may do so through the mobile application (both iOS & Android). By requesting the deletion of your account on the 98degreenorth Platform you will be automatically unsubscribed/ opted-out from receiving marketing/ promotional communications, newsletters and other notifications from us and from the 98degreenorth . You will lose any accrued points and benefits for the loyalty account linked to the account/phone number being deleted. Further, you understand that by deleting your account we will delete some of your personal information and account related data available with us permanently and cannot be retrieved in case of disputes in future. Consequently, upon deletion of your account, we may no longer be able to provide you all the corresponding service like online returns, order tracking etc. It is hereby clarified that, despite your request to delete your account we may retain some information which are necessary to fulfill any pending orders, services and /or for complying with statutory requirement. Your request for deletion of the account may be kept in abeyance till the final resolution of any pending disputes, grievances, investigations or issues relating to the account. 
        </Text>
        <Heading size={'md'} mb={1} fontWeight={600}>Acceptance of Your Order    </Heading>
        <Text mb={4} fontSize={'13px'}>
        Please note that completion of the online checkout process does not constitute our acceptance of your offer to purchase products from us. We will notify you by email and/or mobile confirming receipt of your order and containing the details of your order as soon as possible to acknowledge that we have received and are processing your order. Our acceptance of your order will take place only when we ship or dispatch the product(s) that you order from us. We reserve the right, at our sole discretion, prior to dispatch of the product(s), to decline or refuse or cancel an order for any reason whatsoever, including legal and regulatory reasons. 

If your order is dispatched in more than one package, you may receive separate shipment confirmations for each package, and each shipment confirmation and corresponding dispatch will conclude a separate contract of sale between you and us for the Product(s) specified in that shipment confirmation. 

The duration of our contract with you will start from when you receive the order dispatch email and last upon delivery of the Products. 

We list availability information for the products on the relevant webpage of the 98degreenorth Platform. Beyond what we say on that webpage or otherwise on the 98degreenorth Platform, we cannot be more specific about availability. Please note that dispatch estimates are not guaranteed and should not be relied upon as such. As we process your order, you will be informed by e-mail and/or mobile SMS if any Products you order turn out to be unavailable or out of stock. 

Any communication from us shall be sent only to your registered mobile number and/or email address or such other contact number or email address that you may designate, for any particular transaction. You shall be solely responsible to update your registered mobile number and/or email address on the 98degreenorth Platform in the event there is a change. Further, we may also send you notifications and reminders with respect to scheduled deliveries of the purchased products. While we shall make every endeavour to share prompt reminders and notifications relating to the delivery of purchased Product(s) with you, we shall not be held liable for any failure to send such notifications or reminders to you. 

If we cannot supply you with the Product you ordered, whether fully or partially, we will not process your order, to the extent we cannot supply the same, inform you of this in writing (including email) and, if you have already paid for the Product or service, refund you for the part of the order so cancelled, in full as per the Returns & Refunds terms herein. 

If the fulfilment of an order (or any aspect of it) would be illegal or unlawful, M/S K.K.Fabrics  has the right to stop or cease to fulfil the order at any time, including after dispatch of products and/or notification to you that the order has been received and is being processed. You acknowledge that M/S K.K.Fabrics   shall incur no liability in such circumstances. 

All orders placed on 98degreenorth Platform are subject to the availability of such product, our acceptance of your offer and your continued adherence to these Terms and Conditions. 

You hereby authorise us to declare and provide declarations to any governmental authority on request on your behalf, including that the products ordered by you are for personal, non-commercial use.
        </Text>

        <Heading size={'md'} mb={1} fontWeight={600}>Terms for Cancellation, Return and Refund of Orders     </Heading>
        <Text mb={4} fontSize={'13px'}>
        Cancellation of Orders 

You can cancel orders for Products in fully (not partially) till the time the cancel button is enabled against the order on the 98degreenorth Platform. 

Return of Products 

    We offer a no questions asked return policy. Please note that merchandise can only be returned for refunds if it is unused with all price tags intact. 

    Return can be done within 35 days of delivery. The return can be done online from home, or at the nearest offline store. The refund will be processed according to original mode of payment as per details given below. 

    You may initiate the request for return of the Product if: (i) Product is damaged; (ii) both the Product and shipping package have been damaged; (iii) Product is defective or is not in working condition; (iv) parts of the Product or accessory is missing; or (v) the Product ordered is different from what was ordered basis the product description provided on the 98degreenorth Platform (“Non-Compliant Product”). For any such issue you need to intimate customer service team within 48 hours of delivery of your order. 

    The Product quality does not match the Product description on the 98degreenorth Platform; You shall initiate such requests for a return within 35 days of delivery. Please note some Products (eg. items on final reduction, masks, beauty products, knickers, knicker packs, swimwear, shapewear, toiletries, jewellery, personalised items and/or any products with a broken seal) are sold on non-returnable and non-exchangeable basis (“Non-returnable Products”). Notwithstanding anything to the contrary, Non-returnable Products cannot be returned under any circumstance. 

    You shall keep the products in its unused, original condition, along with the original invoice/ sale receipt, brand outer box, MRP and Product tags attached, user manual, warranty cards, and original accessories in manufacturer packaging for a successful return pick-up. We would accept the request for return of such Product subject to the terms mentioned herein. Our Logistics Partner will usually pick up the Product from you within 3 days of initiating return. 

    If you have received any Product which is subject to return as per the terms mentioned herein, we suggest you to immediately register a request in this regard on the 98degreenorth Platform. We will in our sole discretion verify if: (a) the defects and damages in the products or the non-compliances claimed in such Products are acceptable by us, or (b) such defects, damages or non-compliances are due to our default, and will accordingly post verification, accept or reject the request for return. For such verification, we may request you to send us images of the damaged, defective or Non-Compliant Product received and/or allow our personnel to schedule a visit to assess the damage, defect or non-compliance in the Product. Further to the assessment of the damage or the concern reported in the return request, we may in our sole discretion resolve the issue subject to the assessment report, in a manner as we may deem fit. 

    Your return will be processed only when the conditions as may be stipulated by us are fulfilled at the time of return of such Products, such as the Product being returned in original condition (including water seal stickers in case of fragrances), along with the price tag intact including original packaging of the product, the brand outer packaging of the Non-Compliant Product and all accessories therein, no damage having occurred post-delivery of the Product while in your possession etc. 

    You agree that we will not accept the return of any Product: (i) if the product belongs to Non-returnable Products category, (ii) if you fail to request return/register a complaint in relation to a Non-Compliant Product within the Return Period. Any damage to the product caused by your improper wearing or safekeeping of the Product, or any modification or change to the Product by you or a third party or any depreciation in the value for other reasons will not deem such Product a Non-Compliant Product and will not be considered returnable. Any decision by us in this regard shall be final and binding. 

    You can return the product(s), purchased from us provided the Product(s) satisfy below mandatory conditions: 

(i) The product has not been worn, cleaned or tampered with by you. 

(ii) The price tag /bar code, brand tags, original packaging material, accompanying accessories is intact and not altered, damaged or discarded by you. 

(iii) The Product is not altered. 

(iv) Return is being initiated against the order under which the Product was originally bought, from same registered account and the serial number/ bar code of the Product matches our records. 

(v) There is no foul odour, perfume, stains, dents, scratches, tears or damage on the Product. 

(vi) The Product(s) if bought as a set should be exchanged as the complete set. 

(vii) Products returned should be in unused, undamaged, unwashed and in a saleable condition. 

(viii)We are satisfied that the Product has not been rendered defective or unusable. 

    We reserve the right to reject the return request for a Product if it does not satisfy the aforementioned conditions. Further, we will not be under any obligation to process return or refund for Products which are visibly tampered with post-delivery to the customer or where the product returned is not the Product against which return is being sought. 

    If the product received in the return package does not match the Product for which the return is initiated or where any third party product, wrong, offensive, harmful, deceptive, inaccurate, etc. products are returned, M/S K.K.Fabrics   reserves the right to deal with such products as it may deem fit in its sole and absolute discretion including resale and/ or destruction of such products. Request for return shall not be entertained by M/S K.K.Fabrics   even if such products are handed over to our Logistics Partner by mistake, erroneously or inadvertently. No claim for compensation or proceeds received by M/S K.K.Fabrics   from sale, etc. of such products will be entertained by M/S K.K.Fabrics  . 

    Return of purchased Products are facilitated through our reverse-logistics partners. On receipt of request for return of Product on the 98degreenorth Platform and the same being duly acknowledged by us, our reverse-logistics partners may get in touch with you in order to collect the purchased Products from you. 

    You may also return a Product purchased by you on the 98degreenorth Platform at our physical stores/ boutiques provided you give the following information: (i) details of the Product; (ii) original invoice of the Product; (iii) order number; (iv) reasons for return; and (v) your contact information. 

Refund in case of Cancellation or Return 

    At the time of raising a request for return or cancelling the products on the 98degreenorth Platform, you may seek refund for the Non-Compliant Product. Such refund will be made to you only in the event the payment has been received by us for the Product. 

    Please be informed that when you opt to cancel or return the Products, upon our verification and assessment of the Products and the documents relating thereto, the refund amount for such products which are eligible for cancellation or return as per the terms mentioned herein, will be processed within a period of 7 business days from the date of us verifying the defect or the non-compliance in the Product. Business days for the purpose of this Policy shall mean days except weekends and any national or other state holidays in the state of Delhi NCR. 

    Your refund will be processed only when the conditions as stipulated by us are fulfilled, such as the Product being returned in original condition, along with the price tag intact including original packaging of the Product, the brand outer packaging of the Non-Compliant Product and all accessories therein. For the sake of abundant clarity, it is clarified that we shall not make any refund in respect of a Product that is deemed ineligible for a refund based on our verification and assessment. We reserve the right to reject a refund request for a Product if it does not satisfy the quality conditions specified under the terms mentioned herein on our assessment pursuant to its return. We may in such cases notify you and send the returned Product back without initiating a refund. 

    We do not make any cash refunds. The refund amount will be credited to the original payment mode opted by you or the wallet (defined below), as may be chosen by you. In case of cash on delivery transactions, amount will be refunded to the User designated bank account held under the User’s name provided to us by you prior to processing the refund. In case of payment by loyalty points, refund will be made as loyalty point credit in your account. In case you have used any voucher/ coupons/ codes while placing the order, the same shall no longer be reusable and no refund or other claim against the same shall be processed by us. 

    You acknowledge that after initiation of refund, it may take additional time for your refund to reflect in your account which is subject to your financial institution or payment solution provider’s terms and conditions. However, if the refund does not happen by the date advised, you may contact us, and we will gladly help you. 

    Refund to be made to the user shall not include any amount paid towards shipping charges or any other such charges applicable from time to time. However, in the event a Product has been delivered with a defect or damage (for reasons attributable to, and accepted by us after due verification at its sole discretion) we may, in our sole discretion, refund the shipping charges to you. 
        </Text>

        <Heading size={'md'} mb={1} fontWeight={600}>Terms of Shipping and Delivery  </Heading>
        <Text mb={4} fontSize={'13px'}>
        We partner with third party logistic service providers in order to effectuate product shipping and delivery to you ("Logistic Partners"). We shall provide the details of the Logistic Partner which will be responsible for processing the shipping and delivery of any Product(s) purchased by you on the 98degreenorth Platform at the time such Product is processed and successfully handed over to the Logistic Partner by us. The user will be provided with an estimated timeline for the delivery of the Product purchased from the 98degreenorth Platform through email/ sms on the e-mail ID and/or mobile number provided by you/ registered with us. This estimated timeline shall be notified to the user on the order confirmation page displayed at the time the order is confirmed by us. You agree and understand that though we effectuate product delivery to the users through our Logistic Partners, we reserve the right to ship and deliver the Products on our own without engaging any Logistic Partners or third party service providers. 

You agree and understand that though we endeavour to ship and deliver our Products all across India, we may, in our sole discretion determine a select list of areas which are unserviceable for delivery of Products. We or our Logistic Partners do not provide shipping and delivery services in such unserviceable areas and may not process your orders on the 98degreenorth Platform in such cases. In the event an area has been deemed unserviceable by us, we shall notify such user at the time of placing an order for purchase of Products on the 98degreenorth Platform. You may also verify whether an area is unserviceable for deliveries by us by entering the relevant area pin-code on the 98degreenorth Platform. 

You agree and acknowledge that to effectuate timely delivery of the purchased Products to you we may inquire or collect specific information like your name, shipping address, billing address, landmarks, contact details, etc. You shall ensure that all information that is submitted by you to us on the 98degreenorth Platform is true, complete, accurate and sufficient to identify the actual place of delivery. You understand that you shall bear absolute liability in case of any failure by us in delivering the purchased Products due to your failure to provide correct, complete, sufficient and accurate information at the time of placing the order. It is further clarified that we shall not be liable in any manner and at any point in time due to your failure to provide correct and complete information. 

We will attempt to deliver the purchased Product to your designated address within the estimated timeline of delivery notified to you. In the event you are not available or present to accept the delivery of the Product, our Logistic Partners will make a maximum of three attempts to deliver the purchased Product(s) to you. If the last delivery attempt is unsuccessful and you continue to remain unavailable, we reserve the right to cancel the order of the purchased Products at our sole discretion and process the return of such Product to us. We further reserve the right to deduct the shipping and delivery charges borne by us while processing any refunds subsequent to such cancellation. 

While we make reasonable endeavours in ensuring that purchased Products are delivered to you in a timely manner and within the timeline notified to you, you accept and acknowledge that the delivery may be delayed on account of: 

(a) Logistical issues beyond our control; 

(b) Unsuitable weather conditions; 

(c) Political disruptions, strikes, employee-lockouts, etc.; 

(d) Acts of God such as floods, earthquakes, etc.; 

(e) Other unforeseeable circumstances. 

In such events of delay, we shall make reasonable attempt to inform you by writing to your email ID and/or mobile number registered with us. We disclaim all liabilities that may arise on account of our failure to inform or notify you of delays in the delivery of purchased Products on the 98degreenorth Platform. Further, we shall be under no obligation to compensate you for any claim that may otherwise arise on account of delay in the shipment or delivery or use of the purchased Products. 

We endeavour to engage Logistic Partners, employees, agents with the highest regard for ethics and integrity; and behave in a fashion that exudes thorough professionalism, competence and good mannerism. You agree and acknowledge that the actions, inactions of delivery individuals are not in our control, and it is not possible for us to monitor and observe each delivery executive. Since we are merely facilitating delivery of a Product purchased by you, we shall not be liable for any acts or omissions on part of our delivery agents, employees, or personnel and/ or the Logistic Partner or their employees, agents, or personnel including deficiency in service, wrong delivery of Product, time taken to deliver the Product, Product package tampering, etc. For the sake of abundant clarity, it is stated that any ill-mannerism, impoliteness, discourtesy or offensiveness shown by our delivery executives or the employees, agents, personnel of the Logistic Partners is beyond our control and any issue arising between you and our delivery executive or an employee, agent, personnel of the Logistic Partner will have to be resolved by the you, independently. You agree and acknowledge that you will not hold us responsible or require us to settle, mediate or resolve any disputes between you and the delivery personnel delivering the Products to you. 

Once you place an order on the 98degreenorth Platform, we process such order and hand over the purchased Product to our Logistic Partner. The user will receive a unique tracking identity number once the purchased Product is handed over to the Logistics Partner, which will enable the user in tracking the status of delivery of the purchased Products. The user may use the tracking identity number on the 98degreenorth Platform or the website and/or the mobile application of the Logistic Partner to check the status and location of the purchased Product and its estimated time of delivery. Our customer service team coordinates with the Logistic Partners to ensure that the Products are delivered to you at the earliest and make all reasonable efforts in ensuring that the Logistic Partners update the tracking status of the purchased Products on a real time basis. It is clarified that we engage third party service providers to effectuate deliveries of the products and hence, we do not guarantee the accuracy or correctness of the tracking status and the status may be subject to inconsistencies arising out of time-lags in updating the information and/ or other technical difficulties which are not in our control. 

We reserve the right to charge or collect shipping fees (for delivery and/or return) on Products from time to time. Shipping charges may vary based on the value of the Product, type of Product, area of delivery, payment mechanism, etc. You agree that we are authorized to collect, on behalf of the Logistic Partner, the shipping and delivery fees for the service provided by the Logistic Partner. In the event we charge a shipping fees for the delivery of a purchased Product, such shipping fees will not be refunded by us pursuant to any return request raised by you. You acknowledge and accept that the title and risk of all products ordered by you shall pass on to you upon the delivery/ handover of the purchased Products to you. 
        </Text>

        <Heading size={'md'} mb={1} fontWeight={600}>Payment    </Heading>
        <Text mb={4} fontSize={'13px'}>
        In order to ensure user convenience, M/s K.K.Fabrics offers multiple payment options (“Payment Options”) to user. We, without prior notice to user, reserve the right to add or delete payment options from /to the ones listed below: 

    payment through net banking facilities; 

    payment through select credit cards; 

    payment through select debit cards; 

    payments through cash on delivery; 

    payments through prepaid payment instruments and electronic wallets. 

The ‘cash on delivery’ payment option allows Users to make a cash-only payment to our delivery executive or logistic partner at the time of delivery of the purchased Products to the User. Presently, we offer a maximum order value of INR 4,999 (Indian Rupees Four Thousand Nine Hundred and Ninety-Nine only) under the cash on delivery payment option. In case we offer cash on delivery for the orders exceeding INR 49,999, we may request for additional documents like PAN, etc. from you at the time of placing of order/ delivery of the order. We reserves the right not to provide cash on delivery payment option for certain Products (these could be Products specified by us or Products with value exceeding a specified amount) or locations. User(s) are required to peruse and accept the terms set out under the Return, Refund and Cancellation for refunds in respect of transactions carried out using the cash on delivery payment option. 

During the checkout process, you will be asked to enter your payment details. By completing your payment details, you confirm that the credit or debit card or any other prepaid payment instrument details being used is yours and is correct, complete and accurate. All fields indicated as compulsory must be completed. User(s) shall not use a credit/debit card or prepaid instrument which is not lawfully owned by him/her or which the user has not been lawfully authorised the user to use. The user shall solely be responsible for the security and confidentiality of his/her financial information. M/s K.K.Fabrics disclaims all liabilities that may arise as a consequence of any unauthorised use of the user’s financial information and/or identity, including, details relating the payment options referred above. Please note that we may collect and store your information, using an encrypted secure payment mechanism and will only use your information in accordance with our Privacy and Cookie Policy. Please be advised that where you select CCAvenue as a payment method, we will pass certain information to CCAvenue(name, address, delivery address, email address, and phone number) so that they can process your application and provide the service. Once this information has been passed to CCAvenue, 98degreenorth   shall remain a data controller and CCAvenue shall also be a data controller in relation to that data. 

All card payments are subject to authorisation by your card issuer. We take full payment immediately for all Products save for items paid for by CCAvenue. 

It is expressly clarified that accepting a User’s payment through the Payment Options is solely at 98degreenorth  ’ discretion. 98degreenorth   reserves the right to reject payment from a User through the Payment Options for any reason whatsoever. In order to further validate a User’s transaction, we may request the User to submit a copy of the User’s photo identity proof (such as the User’s PAN card), failing which, we reserves the right to reject a User’s payment made through the Payment Options. 

We reserve the right to impose limits on the number of transactions a User may undertake over the 98degreenorth Platform. Should the User exceed such transaction limits imposed by us, we reserve the right to refuse to process such transactions. We may refuse to process transactions emanating from User(s) who have suspicious or questionable transaction history on the 98degreenorth Platform. Should 98degreenorth   be dissatisfied with the credibility of a User or the genuineness of a transaction carried out on the 98degreenorth Platform, we shall have the right to reject such transactions. We may also choose to delay the dispatch of Products purchased by such User or cancel the entire purchase at our sole discretion. The User agrees that we shall not be liable for any damage, interests or claims resulting from our decision to not process a transaction or delay in the processing of a transaction on account of a User’s suspicious activity on the 98degreenorth Platform. All Products ordered will remain the property of M/S K.K.Fabrics  until we have delivered/ handed over the Products to the address specified by you. If your payment is not received and you have already received the Products you ordered from us, you must pay for it (in full) or return those products to us in accordance with our return instructions and in the same condition that you received them at your own expense. If you do not do this within 30 days of the date on which we cancel your order, we may collect or arrange for collection of the Products at your expense. We reserve the right to charge you for any and all damage to (or other adverse interference with) any Product(s) that are the subject of an unpaid order. 

M/S K.K.Fabrics shall not be held responsible and shall not assume any liability in respect of any loss or damage suffered by a user owing to: 

    lack of authorisation for any transaction, including such transactions carried out on gift cards and vouchers issued to user(s) as a part of M/S K.K.Fabric’s promotions. 

    exceeding of the credit/debit limit mutually agreed between the user and the issuing bank. 

    any payment issue arising out of technical glitches occurring during a transaction. 

    declination of transaction for reasons beyond M/S K.K.Fabrics’s control. 

Despite our best efforts, there may be instances of mispricing of Products on the 98degreenorth Platform during or after the process of purchase of Products has been carried out by the User. While thorough processes of verification are carried out prior to the publication of Product prices on the 98degreenorth Platform, errors and discrepancies arising out of technical glitches and time lags is unavoidable. For avoidance of doubt, M/s K.K.Fabrics  curative actions that will arise in the event of a price difference arising on the actual maximum retail price of a Products against the price published on the 98degreenorth Platform is set out below: 

    If the maximum retail price of the Products delivered to the User is lower than the price published on the 98degreenorth Platform, the User shall within 15 business days from the time when he/she received the Products, intimate us at the customer care in each case resulting in an acknowledgment. Upon 98degreenorth   being satisfied of the User’s claim, the difference in the purchase price against the maximum retail price shall be refunded within 7 business days. 

    The refund amount will be credited to the original payment mode opted by you or the wallet (defined below), as may be chosen by you. In case of cash on delivery transactions, amount will be refunded to the User designated bank account held under the User’s name provided to us by you prior to processing the refund. In case payment by loyalty points, refund will be made as loyalty point credit in your account. You acknowledge that after initiation of refund, it may take additional time for your refund to reflect in your account which is subject to your financial institution or payment solution provider’s terms and conditions. In case you have used any voucher/ coupons/ codes while placing the order, the same shall no longer be reusable and no refund shall be processed against this. 

    If the maximum retail price of the Products listed on the 98degreenorth Platform is higher than what has been published and if 98degreenorth   is not providing an offer or discount on such Products, 98degreenorth   shall have the right to reserve the despatch of the purchased Products until the error on the 98degreenorth Platform have been rectified and the User pays the difference amount. If the User refuses to pay the difference, the User is entitled to seek a cancellation and the amount paid by the User shall be refunded as per the cancellation terms herein. 

    In order to process such refunds, the User will be required to send an email to us at care.india@marks-and-spencer.com Thereafter, the User will receive a refund if the initial mode of payment and in a User designated bank account held under the User’s name for the order that were ‘cash on delivery’. 
        </Text>
 
      </Container>
    </Box>
    <Show breakpoint='(min-width: 500px)' >
      <Footer></Footer>
    </Show>
    </div>
  );
};

export default TermsCondition;
