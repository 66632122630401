export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";


export const ADD_CART_SUCCESS = "ADD_CART_SUCCESS";
export const UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";

export const UPDATE_CART_IF_SIZE_EXISTS = "UPDATE_CART_IF_SIZE_EXISTS";
export const UPDATE_CART_ITEM_SIZE = "UPDATE_CART_ITEM_SIZE";










